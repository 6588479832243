
import axios from "axios";
import React, {useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from 'js-cookie';
import Loader from "../components/loader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
export default function CreateDepenseDir() {
    const [loader, setLoader]= useState(false);   
    const [error, setError] = useState(null);
const [titre,setTitre]= useState(Cookies.get('titre'))
const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
    'credentials': 'include',
  };
const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
const [ce,setCe]= useState(Cookies.get('autoa'))
    const [date, setDate]= useState('')
    const [libelle, setLibelle]= useState('')
    const [montant, setMontant]= useState('')
    const navigate = useNavigate();
    const [info, setInfo]= useState('')
    const [logaId, setLogaID]= useState(0)
    const [loga, setLoga]= useState('')
    const [dateControl, setDateControl]= useState('')
    const [annexe, setAnnexe]= useState('')
    
    const [idAnnexe,setIdAnnexe]= useState('')
    
    const[lea,setLea]= useState([])
    const [secretaire, setSecretaire] = useState([])
   
    
 
    const  le = lea.map((data)=>(data.id_ecole))
   
    var o=le
    const {id}= useParams();
    if (o!=0){
        o=le
    }else{
        
        o=id
    }


         useEffect(()=>{
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/idAnnexe', {o}, { headers})
            .then(res =>{ 
                
                setIdAnnexe(res.data[0].ville)             
            }
               
    
            ).catch(err => console.log(err));
        }, [])
            var b





    const [ido, setIdo]= useState('')
    useEffect(()=>{
        axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/navbar?id='+gestauto+'&titre='+titre+'', { headers})
        .then(res => setSecretaire(res.data))
        .catch(err => console.log(err));
    }, [])



    const [ville, setVille] = useState([])
    useEffect(()=>{
        axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/candidatDir?id='+ce+'' , { headers})
        .then(res =>{
             setVille(res.data)
             setLoga(res.data[0].ville)
             setLogaID(res.data[0].id_annexe)
             if(id==0){
                setIdo(res.data[0].id_ecole)
             }else{
                setIdo(id)
             }
             if(annexe==''){
                setAnnexe(res.data[0].ville)
             }
            
            })
        .catch(err => console.log(err));
    }, [])

    var id_annexe= 0;
      if(ville.map((data)=>(data.id_annexe))){
     [id_annexe]= ville.map((data)=>(data.id_annexe));
    }


    function firstSubmit(event){
        event.preventDefault();
if(id_annexe!=0){
        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/candidatDir', {annexe,id_annexe}, { headers})
    .then(res => {
        setLea(res.data)
        setIdo(res.data[0].id_ecole)
   
      


       

    }).catch(err => console.log(err));
    }}
           
         
            
           if(idAnnexe!=''){
            b= idAnnexe
          }  else{
            b= loga
          }

        
 
 
    function handleSubmit(event){
        setInfo('')
        setLoader(true)
        event.preventDefault();

        if( montant=="" || libelle=="" || date==""){
            setLoader(false)
            setInfo("UN CHAMP N'EST PAS REMPLI VEILLEZ VERIFIER!!!")
           
         }else if(date>autre){
            setLoader(false)
            setDateControl('Verifier la date')
        }else if(montant<=0){
            setLoader(false)
            setInfo('VERIFIER LA VALEUR DU MONTANT')
        }
         else{
    
        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/createDepenseDir', {date,libelle,montant,ido,id_annexe}, { headers})
        .then(res => {
            setLoader(false)
            navigate('/depenseDir/'+o);
        }).catch(err => console.log(err));
    }
    }

    const handleGoBack = () => {
        navigate('/depenseDir/'+o);
      };
    var date2= new Date();
      
        var autre= date2.toISOString().split('T')[0]  
        useEffect(()=>{
            setDate(autre)
        }, [])
        let load

        if(loader==true){
            load=   <div className="modal">
                <div className="overlay">
    
                </div>
                   <div className="modal-contentis">
        <div className="anous">
        <Loader/>
        </div>
        </div>
            </div>
           
        }else{
            load=''
        }
    
    return(
        
    <div className="auths">
        
         
        <body className="body">
         
         <div className="container">
         <FontAwesomeIcon icon={faArrowLeft} onClick={handleGoBack} style={{ color: 'white', fontSize: '28px', cursor: 'pointer' }} />
                 <form action="#" onSubmit={handleSubmit}>
                 
                 
                 <select name="poste" className='sele' onChange={e => setAnnexe(e.target.value)}>
            <option >{b}</option>
                     
                {  
                    ville.filter((data) => data.ville!=b).map((data)=>(   
                        
                        <option value={data.ville}>{data.ville}</option>
                            
                    ))
                    
                }
           </select>   
           <button className="btn" onClick={firstSubmit}> APPLIQUER </button>


                 <div className="main-user-info">
             
            

        
           
                  <div className="user-input-box">
                  <label htmlFor="date" className="">DATE</label>
                  <h4 className="text-danger">{dateControl}</h4>
                    <input type="date" value={autre} className="" name="date"  id="date" onChange={e => setDate(e.target.value)}  ></input>
                    
                  </div>
                
                   <div className="user-input-box">
                   <label htmlFor="libelle" className="">Libelle</label>
                    <input type="text" className="" name="libelle" id="libelle" maxLength={100} onChange={e => setLibelle(e.target.value)}></input>
                    
                   </div>
                    
                    <div className="libe">
                    <label htmlFor="montant" className="">Montant</label>
                    <input type="number" className="" name="montant" id="montant" onChange={e => setMontant(e.target.value)}></input>
                    
                    </div>
                    
                
                    <h4 className="text-danger">{info}</h4>
                    </div>
                    <div className="form-submit-btn">
                    <input type="submit" className='' value="ENREGISTRER" ></input>
                    </div>
                   
                    
                 </form>
            </div> 
             <br/><br/><br/>
             </body>
             {load}
    </div>
    )
}