import React,{ useEffect, useState} from 'react';
import axios from 'axios';
import Navbar from 'F:/projet/first/src/components/navbar';
import Footer from '../components/footer';
import { Link } from "react-router-dom";
import Cookies from 'js-cookie';
import moni from 'F:/projet/first/src/components/image/moni.png';
import secre from 'F:/projet/first/src/components/image/images.png'
import Loader from "../components/loader";
export default function OldCandidat(){
    const [candidat, setCandidat] = useState([])
    const [id, setId] = useState('')
    const [id2, setId2]= useState('')
    const [titre,setTitre]= useState(  Cookies.get('titre'))
        const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
        const [viewLoader, setViewLoader]= useState(false)
        const [largeurEcran, setLargeurEcran] = useState(window.innerWidth);
        const [loader, setLoader]= useState(true)
    const [error, setError] = useState(null);
        const headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
            'credentials': 'include',
          };





    useEffect(()=>{
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/avoir', {gestauto,titre}, { headers})
            .then(res =>{
                 setId(res.data[0].id_ecole)
                 setId2(res.data[0].id_ecole)
            })
            .catch(err => console.log(err));
    
        }, [])
    
    

  var date= new Date();
 const years= date.getFullYear()
 const days= date.getDate()
var  month=  date.getMonth()+1
var autre= date.toISOString().split('T')[0] 
var datee= new Date(years,month-3,days)
var autre2= datee.toISOString().split('T')[0]
const [date1, setDate1]= useState(autre2)
  const [date2, setDate2]= useState(autre)
  const [out, setOut]= useState('is')


  const mettreAJourLargeurEcran = () => {
    setLargeurEcran(window.innerWidth);

  };



  useEffect(() => {       
    window.addEventListener('resize', mettreAJourLargeurEcran);  
    return () => {
      window.removeEventListener('resize', mettreAJourLargeurEcran);
      
    }; 
  }, []);







  if(id!='' && out== 'is'){
        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/oldResultat',{date1,date2,id}, { headers})
        .then(res => {
            
            
            setCandidat(res.data)
           setLoader(false)
        })
        .catch(err =>{
             console.log(err)
             setError(err)
             setLoader(false)
        });

        setOut('')
        setId('')
    
    }
    function handlePropose(event){
        
        setViewLoader(true)
        event.preventDefault();
     
        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/modfOldDate', {date1,date2,id2}, { headers})
        .then(res => {
         setCandidat(res.data)
         
           setViewLoader(false) 
           setId('')
        }).catch(err =>{
             console.log(err)
             setError(err)
             
             setViewLoader(false)
        });

       
    }
   

   

     const [query, setQuery]= useState(""); 
     let load
     if(viewLoader==true){
        load=   <div className="avt">
        <div className="rin">
          <div className="chm">
            <Loader/>
          </div>
        </div>
      </div>
    }else{
        load=''
    }





     if(loader){
        return(
            <div className="per">
            
            <div className="loader">
            <div className="overlayLo"> 
                <div className="loaderP">
                <Loader/>
                </div>
                </div>
                </div>
                </div>
        )
    }else if(error){
        return (<div>Une erreur est survenue : {error.message}</div>)
    }else{

     if(largeurEcran>= 1024){

    return(
        <>
             
             <div className='wini'>
        <Navbar/>
        <h1 className='win'>NOS ANCIENS CANDIDATS</h1><br/><br/>
        {load}
        <div className='aff'>
        <div className="search">
             <input type="text" className="recherche" name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e=>setQuery(e.target.value)}></input>
        </div>
        <div className='person2'>
           <input type='date' value={date1} className='oix2' onChange={e => setDate1(e.target.value)}></input> <input type='date' value={date2}  className='oix2' onChange={e => setDate2(e.target.value)} ></input> 
           <button type='submit' onClick={handlePropose} className='somi2'>APPLIQUER</button>
           </div>
           </div>

<br/><br/><br/>
    <div className=''>
        <div className=''>
             
        <div className="card-list">
             {
                        candidat.filter((data) => data.nom.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="card">
                             <img src={data.sexe=='Masculin' ? moni : secre}  alt="Candidat 1" />
                         <div className='info'>
                            <p className='name'> {data.nom} {data.prenom}</p>
                         
                            <p className='contacts'>Note Code: {data.resultat_code} </p>
                            <p className='contacts'>Note Conduite: {data.resultat_conduite} </p>
                            </div>
                        </div>
                    
                        ))
                      } 
             </div>
        </div>
        
    </div>
    </div>
<Footer/>
        </>
    )}
    else if(largeurEcran>=510){
        return(
            <>
                 
                 <div className='wini'>
            <Navbar/>
            <h1 className='win'>NOS ANCIENS CANDIDATS</h1><br/><br/>
            {load}
            <div className='barContener'>

{/* Barre de recherche */}
<div className='barChercheT'>
    <input type="text"className='rechBar'  name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e => setQuery(e.target.value)}></input>
</div>


{/* Barre de recherche personnalisée */}
<div className='persBarT'>
    
    <input type='date' className='perSF' value={date1} onChange={e => setDate1(e.target.value)}></input>
    <input type='date' className='perSF' value={date2} onChange={e => setDate2(e.target.value)}></input>
    <button type='submit' className='butonSF' onClick={handlePropose}>APPLIQUER</button>
</div>

</div>

    <br/><br/><br/>
    <div className="card-list">
             {
                        candidat.filter((data) => data.nom.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="card">
                             <img src={data.sexe=='Masculin' ? moni : secre}  alt="Candidat 1" />
                         <div className='info'>
                            <p className='name'> {data.nom} {data.prenom}</p>
                         
                            <p className='contacts'>Note Code: {data.resultat_code} </p>
                            <p className='contacts'>Note Conduite: {data.resultat_conduite} </p>
                            </div>
                        </div>
                    
                        ))
                      } 
             </div>
   
        </div>
    <Footer/>
            </>
        )
    }
    else{
        return(
            <>
                 
                 <div className='wini'>
            <Navbar/>
            <h1 className='win'>NOS ANCIENS CANDIDATS</h1><br/><br/>
            {load}
            <div className='barContener'>

{/* Barre de recherche */}
<div className='barChercheT'>
    <input type="text"className='rechBar'  name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e => setQuery(e.target.value)}></input>
</div>


{/* Barre de recherche personnalisée */}
<div className='persBarT'>
    
    <input type='date' className='perSF' value={date1} onChange={e => setDate1(e.target.value)}></input>
    <input type='date' className='perSF' value={date2} onChange={e => setDate2(e.target.value)}></input>
    <button type='submit' className='butonSF' onClick={handlePropose}>APPLIQUER</button>
</div>

</div>

    <br/><br/><br/>
    <div className="card-list">
             {
                        candidat.filter((data) => data.nom.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="card">
                             <img src={data.sexe=='Masculin' ? moni : secre}  alt="Candidat 1" />
                         <div className='info'>
                            <p className='name'> {data.nom} {data.prenom}</p>
                         
                            <p className='contacts'>Note Code: {data.resultat_code} </p>
                            <p className='contacts'>Note Conduite: {data.resultat_conduite} </p>
                            </div>
                        </div>
                    
                        ))
                      } 
             </div>
   
        </div>
    <Footer/>
            </>
        )
    }
}
}