import React, {useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { Link,NavLink,  useLocation} from "react-router-dom";
import moni from 'F:/projet/first/src/components/image/moni.png';
import log from 'F:/projet/first/src/components/image/log.png';
import Loader from "../components/loader";
import Cookies from 'js-cookie';
import { sidedataDir } from 'F:/projet/first/src/components/sidedataDir'
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { IconContext } from "react-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import logo from 'F:/projet/first/src/components/image/logo4.jpg'
function LesObservationsDir(){
  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
    'credentials': 'include',
  };
  const [error, setError] = useState(null);
    const [sidebar, setSidebar]= useState(false)
    const showSidebar = () => setSidebar(!sidebar)
    const [lu,setLu] = useState(Cookies.get('token'))
    const [tok, setTok]= useState('')
    const [titre,setTitre]= useState(Cookies.get('titre'))
    const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
    const [old, setOld]= useState('')
    const [recup, setRecup]= useState('')
    const [new1, setNew1]= useState('')
    const [new2, setNew2]= useState('')
    const [info1, setInfo1]= useState('')
    const [info2, setInfo2]= useState('')
    
    const [largeurEcran, setLargeurEcran] = useState(window.innerWidth);
    const mettreAJourLargeurEcran = () => {
        setLargeurEcran(window.innerWidth);
    
      };

      if(tok!= ''){
        if(tok!= lu){
            Cookies.remove('token')
            Cookies.remove('autoa')
            Cookies.remove('titre')
            Cookies.remove('gestauto')
            window.location.reload();
            navigate('/')

        }
    }

    var navigate= useNavigate()
    useEffect(()=>{
         if(!Cookies.get('token')){
            navigate('/')
         }
    }, [])


   
    
    const logout = () =>{
        Cookies.remove('token')
        Cookies.remove('autoa')
        Cookies.remove('titre')
        Cookies.remove('gestauto')
        Cookies.remove('default')
        Cookies.remove('default2')
        Cookies.remove('defaultCode')
        Cookies.remove('defaultCode2')
        window.location.reload();
       navigate('/')
    }
    useEffect(()=>{
        if(!Cookies.get('token')){
           navigate('/')
        }
   }, [])





useEffect(() => {
        
    window.addEventListener('resize', mettreAJourLargeurEcran);

   
    return () => {
      window.removeEventListener('resize', mettreAJourLargeurEcran);
      
    };
   
  }, []);
// Recuperer token
useEffect(()=>{
    

axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/setToken', { gestauto, titre}, { headers})
    .then(res => 
        {setTok(res.data[0].token)
        

        })
    .catch(err => console.log(err));
}, [])
//nav en haut
    const [name, setName]= useState('')
    
    const[profil, setProfil]= useState(false)
    const [loader, setLoader]= useState(false);
    const showProfil = () => setProfil(!profil)

    const [observation, setObservation]= useState('')
    const [poste, setPoste]= useState('')
    const {id}= useParams();
    const [affichage, setAffichage]= useState([]);
    
    const [info, setInfo]= useState('');
    var date= new Date();
    const now= date.toLocaleDateString();
   const years= date.getFullYear()
  const  month= date.getMonth()+1
   const day= date.getDate()
   
    const [secretaire, setSecretaire] = useState([])
  useEffect(()=>{
      axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/navbar?id='+gestauto+'&titre='+titre+'', { headers})
      .then(res => {
        setSecretaire(res.data)
        setName(res.data[0].nom)
        setPoste(res.data[0].poste)
        setInfo(res.data[0].id)
    })
      .catch(err => console.log(err));
  }, []) 
    function handleSubmit(event){
        event.preventDefault();
        setLoader(true)
        if(observation!=''){
        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/createObservation/'+id, {observation,years,month,day,name,poste}, { headers})
        .then(res => {
          setLoader(false)
            window.location.reload();
        }).catch(err => console.log(err));
    }
    }
    if(info!=''){
        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/affObservation',{id}, { headers})
                .then(res =>{ 
                    setAffichage(res.data)
              
                })
                .catch(err => console.log(err));
                setInfo('')

            }
            
       const [nameCand,setNameCand] = useState('')
       const [prenomCand, setPrenomCand]= useState('')
       const [controleId,setControleId] = useState('')
  useEffect(()=>{
      axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/update/'+id, { headers})
      .then(res => { 
          
          setNameCand(res.data[0].nom)
          setPrenomCand(res.data[0].prenom)
          setControleId(res.data[0].id_ecole)
      })
      .catch(err => console.log(err));
  }, [])   
  
  var o
  o=controleId

  const handleGoBack = () => {
    navigate('/pageCandidatDir/'+id);
  };



  let load

  if(loader==true){
      load=   <div className="modal">
          <div className="overlay">

          </div>
             <div className="modal-contentis">
  <div className="anous">
  <Loader/>
  </div>
  </div>
      </div>
     
  }else{
      load=''
  }
   




//modification password



const[valid, setValid]= useState('')
              
const[valid2, setValid2]= useState('')
const[supMod, setSupMod]= useState(false) 

const toggleSup = ()=> {
  
 
  setSupMod(!supMod)
  setValid('')
 
}


if(valid!= ''){
 toggleSup()
 


} 



              

const[supMod2, setSupMod2]= useState(false) 

const toggleSup2 = ()=> {
  
 
  setSupMod2(!supMod2)
  setValid2('')
  
}


if(valid2!= ''){
 toggleSup2()
 


} 




function handleModf(event){
    const id= gestauto
    event.preventDefault();
    const currentPassword = secretaire.map((data) => data.password)[0];
    
    if(currentPassword===old){
    
        if(new1===new2){
            setInfo2('')
            axios.put('https://gestauto-56bfcbe4b876.herokuapp.com/upPassword/'+id, {new1,titre}, { headers})
            .then(res => {
                  
        axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/navbar?id='+gestauto+'&titre='+titre+'', { headers })
        .then(res => setSecretaire(res.data))
        .catch(err => console.log(err));
                toggleSup()

            setValid2('on')
            }).catch(err => console.log(err));
        }else{
           
            setInfo2('Incorrecte')
            setTimeout(() => setInfo2(''), 3000)
        }
    }else{
        setInfo1('Incorrecte')
        setTimeout(() => setInfo1(''), 3000)
    }
}


const [menuVisible, setMenuVisible] = useState(false);

const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  
};






useEffect(() => {
    const handleClickOutside = (event) => {
        if (menuVisible && !document.querySelector('.carD').contains(event.target)) {
            setMenuVisible(false);
        }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, [menuVisible]);


          // ici bar
          var bar 
          const location = useLocation();
          if(largeurEcran>= 1000){
            bar=   <div className="barHaut">
 <div className="lep">
                    
                    
                    <img src={moni} className="user" onClick={showProfil} />
                    
                    {supMod && (<div className='modalSup'>
    <div className='overlay'>
        
    </div>
    <div className='modal-contentSupInfo'>
       <div className="containerModal"> 
        <h3 className="form-title2">MODIFIER MOT DE PASSE</h3>
     
      <div className='supD'>
      <input type="text"  name="prenom" className="inputCmot1"  id="prenom"  value={secretaire.map((data)=>(data.nom))}  ></input>
     <input type="text"  name="prenom" className="inputCmot1"  id="prenom"  value={secretaire.map((data)=>(data.prenom))}  ></input>
      
     
      </div>
      <label htmlFor="libelle" className="">Ancien mot de pass</label> <br/>
      <input type="pasword"  name="prenom" className="inputCmot"  id="prenom"   onChange={e => setOld(e.target.value)}   ></input>
      <br/>
      <h4 className="text-danger">{info1}</h4>
      <br/>
      <label htmlFor="libelle" className="">Nouveau mot de pass</label> <br/>
      <input type="pasword"  name="prenom" className="inputCmot"  id="prenom" onChange={e => setNew1(e.target.value)}   ></input>
      <br/>
      <label htmlFor="libelle" className="">Confirmer mot de pass</label> <br/>
      <input type="pasword"  name="prenom" className="inputCmot"  id="prenom"   onChange={e => setNew2(e.target.value)} ></input>
      <br/>
      <h4 className="text-danger">{info2}</h4>
      <br/>
      <div className="supD">
      <Link onClick={handleModf}><button className='nm1' > Modifier </button></Link>
      <Link onClick={toggleSup}><button className='nm2' > Annuler </button></Link>
      </div>
      </div>
    </div>
   
</div>)}
{supMod2 && (<div className='modal'>
    <div className='overlay'>
        
   
    <div className='modal-content'>
       
      <h2>Mot de passe modifier avec succès</h2>
      <div className='supD'>
     
      <Link onClick={toggleSup2}> <button className='supC' >QUITER</button> </Link>
    
      </div>
     
      </div>
    </div>
   
</div>)}
                    
                    <div className="hero"> 
                     <h3 className="nom">{secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}</h3>
                     
                    </div>
       
                    <div class="options">
     <h2 className="form-title2"> {secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}</h2>           
    <button class="option-btn" onClick={e => setValid('on')}>Modifier</button>
    <button class="option-btn2" onClick={logout}>Déconnexion</button>
  </div>
       
                    </div>

            <NavLink to={'/accueil2/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Accueil</button> </NavLink>
            <NavLink to={'/candidatDir/'+o} className='dirResult'><button className='pr'> Candidats</button> </NavLink>
            <NavLink to={'/depenseDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Depenses</button> </NavLink>
            
            
            <NavLink to={'/payementDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Recettes</button> </NavLink>
            <NavLink to={'/bilan/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Bilan Global</button> </NavLink>
            <NavLink to={'/optPresenceDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Presence</button> </NavLink>
            <NavLink to={'/resultatDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Resultats</button> </NavLink>
            <Link onClick={logout} className="link">
  <img src={log} className="am" />
  <div className="deco">DECONNEXION</div>
  
</Link> 
            
              
            
            </div>
            
            
          }else{
            bar=  <IconContext.Provider value={{color:'#fff'}} >
                         
            <div className="icon">
         <div>
         &nbsp;&nbsp;&nbsp;
         <img src={logo} className="userLog"  /> 
         </div>
               
         <div className="naI">
               {secretaire.map((data)=>(data.auto_ecole))} 
             </div>   
         <div className="navbar">
         
            <Link to="#" className={sidebar?  "trois": "menu-bars"}>
                <FaIcons.FaBars onClick={showSidebar} />
            </Link>
         
            &nbsp;&nbsp;&nbsp;
              
         
         </div>
    
 <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
    <ul className="nav-menu-items" >
        <li className="navbar-toggle">
            <Link to="#" className="menu-bars">
               <AiIcons.AiOutlineClose onClick={showSidebar}/>
            </Link>
        </li>
        <div className="carD">
                    <div className="profil">
        <AiIcons.AiOutlineUser className="icone-profil"   onClick={toggleMenu} />
        <div className="infos-utilisateur">
           <p className="inPo"   onClick={toggleMenu}> {secretaire.map((data)=>(data.poste))} </p>
          <p className="inN"  onClick={toggleMenu}> {secretaire.map((data)=>(data.nom))}  {secretaire.map((data)=>(data.prenom))} </p>
        </div>
      </div>


      {menuVisible && (
      <div className="dropdown-menu">
         <button className="option-btnMobi" onClick={e => setValid('on')}>Modifier</button>
         <button className="option-btnMobi2" onClick={logout}>Déconnexion</button>
      </div>
   )}

      </div>
        {sidedataDir.map((item, index) =>{
            const isActive = location.pathname.startsWith(item.path);
          return (
           
            <li key={index} className={isActive ? `${item.cName} active` : item.cName} onClick={showSidebar}>
                 <Link to={item.path+'/'+o}>
                    <span>{item.title}</span>
                 </Link>
                
            </li>
            
          
          )
 
        })}
       <li className="nav-text">
        <Link onClick={logout}  >
             
               <span>Deconnexion</span>
               
          </Link>
          </li> 
    </ul>
 </nav>
         </div>
         {supMod && (<div className='modalSup'>
    <div className='overlay'>
        
    </div>
    <div className='modal-contentSupInfo'>
       <div className="containerModal"> 
        <h3 className="form-title2">MODIFIER MOT DE PASSE</h3>
     
      <div className='supD'>
      <input type="text"  name="prenom" className="inputCmot1"  id="prenom"  value={secretaire.map((data)=>(data.nom))}  ></input>
     <input type="text"  name="prenom" className="inputCmot1"  id="prenom"  value={secretaire.map((data)=>(data.prenom))}  ></input>
      
     
      </div>
      <label htmlFor="libelle" className="">Ancien mot de pass</label> <br/>
      <input type="pasword"  name="prenom" className="inputCmot"  id="prenom"   onChange={e => setOld(e.target.value)}   ></input>
      <br/>
      <h4 className="text-danger">{info1}</h4>
      <br/>
      <label htmlFor="libelle" className="">Nouveau mot de pass</label> <br/>
      <input type="pasword"  name="prenom" className="inputCmot"  id="prenom" onChange={e => setNew1(e.target.value)}   ></input>
      <br/>
      <label htmlFor="libelle" className="">Confirmer mot de pass</label> <br/>
      <input type="pasword"  name="prenom" className="inputCmot"  id="prenom"   onChange={e => setNew2(e.target.value)} ></input>
      <br/>
      <h4 className="text-danger">{info2}</h4>
      <br/>
      <div className="supD">
      <Link onClick={handleModf}><button className='nm1' > Modifier </button></Link>
      <Link onClick={toggleSup}><button className='nm2' > Annuler </button></Link>
      </div>
      </div>
    </div>
   
</div>)}
{supMod2 && (<div className='modal'>
    <div className='overlay'>
        
   
    <div className='modal-content'>
       
      <h2>Mot de passe modifier avec succès</h2>
      <div className='supD'>
     
      <Link onClick={toggleSup2}> <button className='supC' >QUITER</button> </Link>
    
      </div>
     
      </div>
    </div>
   
</div>)} 



         </IconContext.Provider>
                 
         }

    return(
        <div className="auths">
          


{bar}

          
        <body className="body">
        
        <div className="container"> 
        <FontAwesomeIcon icon={faArrowLeft} onClick={handleGoBack} style={{ color: 'white', fontSize: '28px', cursor: 'pointer' }} />
        <h1 className="form-title">OBSERVATION</h1>
        <h2 className="candName"> {nameCand} {prenomCand} </h2>
     <div className="lesrcoll">
   {
        affichage.map((data, i)=> (
                   <div>
                            <div className={data.nom_auteur== name ? 'affOa':'affOb'}> <div className="boite">
                           <div className="tete">   <h6 className="hh">{data.auteur} {data.nom_auteur} <br/></h6> <h6 className="hh">{moment(data.date).utc(1).format('DD-MM-YYYY')}</h6> </div>
                                
                                  {data.observation}</div>
                            
                            </div><br/><br/></div>
                        ))
                    }
                    </div>
        <br/><br/>
        
       <div className="position">
        <textarea cols={100} rows={3} className="obe" onChange={e => setObservation(e.target.value)} ></textarea>
        <button className="enr" onClick={handleSubmit}>ENREGISTRER</button>
        </div>
        </div>
        </body>
        {load}
       </div>
    )
}
export default LesObservationsDir