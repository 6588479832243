
import Navbar from 'F:/projet/first/src/components/navbar';
import React,{ useEffect, useState} from 'react';
import axios from 'axios';
import Loader from "../components/loader";
import croix from './croix.png'
import Mod from './mod.png'
import { Link,useNavigate} from "react-router-dom";
import moment from "moment";
import Footer from '../components/footer';
import Cookies from 'js-cookie';
export default function Payement(){
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };
    const [view, setView]= useState('')
    const navigate = useNavigate();
    var date= new Date();

    const [select1,setSelect1]= useState(Cookies.get('select1'))
    const [select2,setSelect2]= useState(Cookies.get('select2'))


   const years= date.getFullYear()
  var  month=  date.getMonth()+1
  var autre= date.toISOString().split('T')[0]
  const day= date.getDate()
  const [viewLoader,setViewLoader]= useState(false)
  const[value,setValue]= useState('')
  const [date1, setDate1]= useState('')
  const [date2, setDate2]= useState('')
  const days= date.getDate()
  var datee= new Date(years,month-2,days)
  var autre2= datee.toISOString().split('T')[0] 
  const [loader, setLoader]= useState(true)
  const [error, setError] = useState(null);
  const [largeurEcran, setLargeurEcran] = useState(window.innerWidth);
  const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
  const [mie, setMie]= useState(Cookies.get('mie'))
  let lo 
  useEffect(()=>{
    setDate1(autre2)
    setDate2(autre)
  
}, [])

    const [payement, setPayement] = useState([])
    const [candidat, setCandidat]= useState([])

    const [listePay2, setListePay2]= useState([])

    useEffect(()=>{
    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/listePay2', {date1,date2,mie},{headers})
    .then(res =>{ 
      if (Array.isArray(res.data)) {
          setListePay2(res.data);  // Inverse l'ordre des éléments si res.data est un tableau
        } else {
          console.error("Les données reçues ne sont pas un tableau : ", res.data);
          setListePay2([]);  // Définit un tableau vide si ce n'est pas un tableau
            

        }
      console.log('entout cas moi je suis là ', res.data)
      setLoader(false)
 
    }
    ).catch(err => {
      console.log(err)
    setError(err)
    setLoader(false)
  });

}, [date1,date2])
   
    useEffect(()=>{
        if(select1=== undefined || select2===undefined){
          
        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/payement',{month,years,gestauto,mie}, { headers})
        .then(res =>{
             setPayement(res.data)
             setLoader(false)
        })
        .catch(err =>{
             console.log(err)
             setError(err)
             setLoader(false)
        });
       
      
   
    }else if(select1 || select2){

        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/secreVvoirPaye', {select1,select2,gestauto,mie}, { headers})
        .then(res => {
            setDate1(select1)
            setDate2(select2)

           setPayement(res.data)
      
            setLoader(false)
            
        }).catch(err =>{ 
            console.log(err)
            setError(err)
          
            setLoader(false)
        });


        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/secretairePayep', {select1,select2,gestauto,mie},{headers})
        .then(res =>{ 
          if (Array.isArray(res.data)) {
              setListePay2(res.data);  // Inverse l'ordre des éléments si res.data est un tableau
            } else {
              console.error("Les données reçues ne sont pas un tableau : ", res.data);
              setListePay2([]);  // Définit un tableau vide si ce n'est pas un tableau
            }
          console.log('entout cas moi je suis là ', res.data)
          setLoader(false)
     
        }
        ).catch(err => {
          console.log(err)
        setError(err)
        setLoader(false)
      });

    }
    }, [])



    useEffect(()=>{

    axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/listeCand?id='+mie+'', { headers})
    .then(res => setCandidat(res.data))
    .catch(err => console.log(err));
}, [])

var mois 
if(month==1){
    mois="Janvier"
}else if(month==2){
    mois="Fevrier"
}else if(month==3){
    mois="Mars"
}else if(month==4){
    mois="Avril"
}else if(month==5){
    mois="Mai"
}else if(month==6){
    mois="Juin"
}else if(month==7){
    mois="Juillet"
}else if(month==8){
    mois="Août"
}else if(month==9){
    mois="Septembre"
}else if(month==10){
    mois="Octobre"
}else if(month==11){
    mois="Novembre"
}else if(month==12){
    mois="Decembre"
}

    function handleTout(event){
        setViewLoader(true)
        event.preventDefault();
        if(value=='Tous les paiements'){
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/toutPayement', {gestauto,mie}, { headers})
            .then(res => {
                setPayement(res.data)
                setViewLoader(false)
                setLoader(false)
            })
            .catch(err =>{
                 console.log(err)
                 setError(err)
                 setLoader(false)
                 setViewLoader(false)
            });

            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/toutPayement2', {gestauto,mie}, { headers})
            .then(res => {

                if (Array.isArray(res.data)) {
                    setListePay2(res.data);  // Inverse l'ordre des éléments si res.data est un tableau
                  } else {
                    console.error("Les données reçues ne sont pas un tableau : ", res.data);
                    setListePay2([]);  // Définit un tableau vide si ce n'est pas un tableau
                  }
             
                setViewLoader(false)
                setLoader(false)
            })
            .catch(err =>{
                 console.log(err)
                 setError(err)
                 setLoader(false)
                 setViewLoader(false)
            });

            }
        else if(value=='Ce mois'){
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/payement',{month,years,gestauto,mie}, { headers})
            .then(res =>{
                 setPayement(res.data)
                 setViewLoader(false)
                 setLoader(false)    
            })
            .catch(err =>{
                 console.log(err)
                 setError(err)   
                 setLoader(false) 
                 setViewLoader(false)
            });

            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/payement2', {month,years,gestauto,mie}, { headers})
            .then(res => {

                if (Array.isArray(res.data)) {
                    setListePay2(res.data);  // Inverse l'ordre des éléments si res.data est un tableau
                  } else {
                    console.error("Les données reçues ne sont pas un tableau : ", res.data);
                    setListePay2([]);  // Définit un tableau vide si ce n'est pas un tableau
                  }
             
                setViewLoader(false)
                setLoader(false)
            })
            .catch(err =>{
                 console.log(err)
                 setError(err)
                 setLoader(false)
                 setViewLoader(false)
            });


        }else if(value=='Cette année'){
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/anneePayement',{month,years,gestauto,mie}, { headers})
            .then(res => {
                setPayement(res.data)
                setViewLoader(false)
                setLoader(false)
            })
            .catch(err =>{
                 console.log(err)
                 setError(err)
                 setViewLoader(false)
                 setLoader(false)
            });


            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/anneePayement2', {month,years,gestauto,mie}, { headers})
            .then(res => {

                if (Array.isArray(res.data)) {
                    setListePay2(res.data);  // Inverse l'ordre des éléments si res.data est un tableau
                  } else {
                    console.error("Les données reçues ne sont pas un tableau : ", res.data);
                    setListePay2([]);  // Définit un tableau vide si ce n'est pas un tableau
                  }
             
                setViewLoader(false)
                setLoader(false)
            })
            .catch(err =>{
                 console.log(err)
                 setError(err)
                 setLoader(false)
                 setViewLoader(false)
            });


        }
       
    }
    const [query, setQuery]= useState(""); 

    
    function handlePropose(event){
        setViewLoader(true)
        
        Cookies.set('select1',date1)
        Cookies.set('select2',date2)
        event.preventDefault();
        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/personPayement', {date1,date2,gestauto,mie}, { headers})
        .then(res => {
           setPayement(res.data)
           setViewLoader(false)
            setLoader(false)
            
        }).catch(err =>{ 
            console.log(err)
            setError(err)
            setViewLoader(false)
            setLoader(false)
        });

        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/listePay2', {date1,date2,mie},{headers})
        .then(res =>{ 
          if (Array.isArray(res.data)) {
              setListePay2(res.data);  // Inverse l'ordre des éléments si res.data est un tableau
            } else {
              console.error("Les données reçues ne sont pas un tableau : ", res.data);
              setListePay2([]);  // Définit un tableau vide si ce n'est pas un tableau
            }
          console.log('entout cas moi je suis là ', res.data)
          setLoader(false)
     
        }
        ).catch(err => {
          console.log(err)
        setError(err)
        setLoader(false)
      });

    }

    const mettreAJourLargeurEcran = () => {
        setLargeurEcran(window.innerWidth);
    
      };
     
        const[inf, setInf] = useState(false)
        const[dataLibe, setDataLibe]= useState('')
        const[dataDate, setDataDate]= useState('')
        const[dataNon, setDataNom]= useState('')
        const[dataPrenom, setDataPrenom]= useState('')
        const[dataMontant, setDataMontant]= useState('')
        const [pop, setPop]= useState('')
    
        const togglePop =()=> {
            setInf(!inf)
        }
       
        if(pop!= ''){
            lo= true
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/vivoPa', {pop}, { headers})
            .then(res => {
                
              setDataLibe(res.data[0].libelle)
              setDataDate(res.data[0].date)
              setDataNom(res.data[0].nom)
              setDataPrenom(res.data[0].prenom)
              setDataMontant(res.data[0].montant)
              if(dataLibe !='' && dataDate !='' && dataMontant !=''){
                console.log('les pop ', dataDate,dataMontant,dataLibe)          
                togglePop()
                setPop('')
                lo= false
            }  
                
            }).catch(err => {
                console.log(err)
                setError(err)
                lo= false
            })  ;
              
        }
        
        useEffect(() => {      
            window.addEventListener('resize', mettreAJourLargeurEcran);      
            return () => {
              window.removeEventListener('resize', mettreAJourLargeurEcran);        
            };      
          }, []);
    

    const[modal, setModal]= useState(false) 
    const[modalCand, setModalCand]= useState(false)
const toggleModal = ()=> {
    setModal(!modal)
}
const toggle2 =()=> {
    setModalCand(!modalCand)
}
   
    if(view!= ''){
        setViewLoader(true)
        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/vevPaye', {years,month,day,view}, { headers})
        .then(res => {
           if(res.data.message== 'No match'){
            setLoader(false)
            setViewLoader(false)
            toggleModal()
                setView('')
           }else{
            setLoader(false)
            setViewLoader(false)
            navigate('/updatePayement/'+res.data[0].id)
           }
  
            
            
        }).catch(err =>{ 
            console.log(err)
            setError(err)
            setLoader(false)
            setViewLoader(false)
        });
        setView('')
      }     

      let totalPay=0

let totalPay2=0
      payement.map((data, i)=> (
        totalPay=totalPay+data.montant
    ))
    

    listePay2.map((data, i)=> (
        totalPay2=totalPay2+data.montant_payer
    ))

    const tot = totalPay+totalPay2

    if (totalPay){
        console.log('nos totaux',totalPay,totalPay2,tot)
    }


    let load
if(viewLoader==true || lo== true){
    load=   <div className="avt">
    <div className="rin">
      <div className="chm">
        <Loader/>
      </div>
    </div>
  </div>
}else{
    load=''
}


    if(loader){
        return(
            <div className="per">
            
            <div className="loader">
            <div className="overlayLo"> 
                <div className="loaderP">
                <Loader/>
                </div>
                </div>
                </div>
                </div>
        )
    }else if(error){
        return (<div>Une erreur est survenue : {error.message}</div>)
    }else{

    if(largeurEcran>= 1024){
    return(
        <>
    <div className='wini'>
        <Navbar/>
        <h1 className='win'>HISTORIQUE DES RECETTES DU MOIS DE {mois}</h1><br/>

{load}
        <div className='inove'>
        <div className="search">
             <input type="text" className="recherche" name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e=>setQuery(e.target.value)}></input>
        </div>

          

        <div className='selvil'  >
             
             <select name="poste" className='sel' onChange={e => setValue(e.target.value)} >
            <option value={'Ce mois'}>Ce mois</option>
            <option value={'Cette année'}>Cette année</option>
            <option value={'Tous les paiements'}>Tous les paiements</option>
            

                     
                
           </select>
           <button className='butonSF2' onClick={handleTout}>Appliquer</button>
             <br/><br/>
           <h4 className='che'> Recherche personnalisée </h4>
           <div className='person'>
           <input type='date' value={date1} className='oix' onChange={e => setDate1(e.target.value)}></input> <input type='date' value={date2} className='oix' onChange={e => setDate2(e.target.value)} ></input> 
           <button type='submit' onClick={handlePropose} className='butonSF3' >APPLIQUER</button>
           </div>
       </div></div>

        <div className=''>
        <div className=''>
             <Link onClick={toggle2} className='yello'> <button className='ajouter'>AJOUTER UN PAIEMENT</button> </Link><br/><br/>
            <div className='uncard-list'> 
            {
                        
                        payement.filter((data) => data.date.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query) || data.nom.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                              
                                <span className='texte-depense'>
                                <div> {data.nom} {data.prenom}</div>
                            <span className="montant">{data.montant} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                             <Link onClick={e => setView(data.id)}  >  <span className="icone-modifier1"> </span></Link> 
                               {modal && (<div className='modal'>
    <div className='overlay'>
        
    </div>
    <div className='modal-content'>
        <h2>Attention cette recette ne peut plus être modifiée!!!</h2>
      <Link onClick={toggleModal}>  <img src={croix} className='close-modal'/></Link>
    </div>
</div>)}
                             
                          </div>
                        ))
                      }

{
                        
                        listePay2.filter((data) => data.date.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query) || data.nom.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                              
                                <span className='texte-depense'>
                                <div> {data.nom} {data.prenom}</div>
                            <span className="montant">{data.montant_payer} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">Premier versement</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                           
                          </div>
                        ))
                      }


             </div>
             <div className='vT'>
             TOTAL : {totalPay+totalPay2} FCFA
             </div>
        </div>
        
    </div>


   

    <br/>



    </div>

    <Footer/>
    {modalCand && (<>
<div className="overlay"></div>
<div className="fenetre-modal">
     
<Link to='/create' className='yello'> <button className='btnP'>NOUVEAU CANDIDAT</button> </Link><br/><br/>
                                 <input type="text" className="rechercheMod" name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e=>setQuery(e.target.value)}></input>
                            
  <div className="modal-entete">
 
                                
                                </div>
                                <div className="modal-corps">
    <table className="table-inscrits">
      <thead>
        <tr>
          <th>Nom</th>
          <th>Prénom</th>
        </tr>
      </thead>
      <tbody>
        {   candidat.filter((data) => data.date.toLowerCase().includes(query) || data.nom.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query)).reverse().map((data, i)=> (
          <tr key={i} className={i % 2 === 0 ? "ligne-grise" : "ligne-blanche"}>
            <td ><Link to={'/pageCandidatPaye/'+ data.id} className='bnfs'>{data.nom}</Link></td>

            <td><Link to={'/pageCandidatPaye/'+ data.id} className='bnfs'>{data.prenom}</Link></td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  <div className="boutons-modal">
    <Link onClick={toggle2}>
      <button className="bouton-annuler">QUITTER</button>
    </Link>
  </div>
  </div>
 

</>


                       
                    )}


    </>
    )
                    }
                    else if(largeurEcran >=510){
                        return(
                            <>
                        <div className='wini'>
                            <Navbar/>
                            <h1 className='win'>HISTORIQUE DES RECETTES DU MOIS DE {mois}</h1><br/>
                    
                    {load}
                            <div className='barContener'>

{/* Barre de recherche */}
<div className='barChercheT'>
    <input type="text"className='rechBar'  name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e => setQuery(e.target.value)}></input>
</div>

{/* Barre de sélection avec bouton Appliquer */}
<div className='barChoixT'>
    <select name="poste" className='choosee' onChange={e => setValue(e.target.value)}>
        <option>Ce mois</option>
        <option>Cette année</option>
        <option>Tous les paiements</option>
    </select>
    {/* Bouton Appliquer pour le select */}
    <button className='butonSF' onClick={handleTout}>Appliquer</button>
</div>

{/* Barre de recherche personnalisée */}
<div className='persBarT'>
    
    <input type='date' className='perSF' value={date1} onChange={e => setDate1(e.target.value)}></input>
    <input type='date' className='perSF' value={date2} onChange={e => setDate2(e.target.value)}></input>
    <button type='submit' className='butonSF' onClick={handlePropose}>APPLIQUER</button>
</div>

</div>
                            <div className=''>
                            <div className=''>
                                 <Link onClick={toggle2} className='yello'> <button className='ajouter'>AJOUTER UN PAIEMENT</button> </Link><br/><br/>
                   
<div className='card-list'>

{
                        
                        payement.filter((data) => data.date.toLowerCase().includes(query) || data.nom.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                              
                                <span className='texte-depense'>
                                <div> {data.nom} {data.prenom}</div>
                            <span className="montant">{data.montant} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                             <Link onClick={e => setView(data.id)}  >  <span className="icone-modifier"> </span></Link> 
                               {modal && (<div className='modal'>
    <div className='overlay'>
        
    </div>
    <div className='modal-content'>
        <h2>Attention cette recette ne peut plus être modifiée!!!</h2>
      <Link onClick={toggleModal}>  <img src={croix} className='close-modal'/></Link>
    </div>
</div>)}
                             
                          </div>
                        ))
                      }
{
listePay2.filter((data) => data.date.toLowerCase().includes(query) || data.nom.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                              
                                <span className='texte-depense'>
                                <div> {data.nom} {data.prenom}</div>
                            <span className="montant">{data.montant_payer} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">Premier versement</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                           
                          </div>
                        ))
                      }
</div>
                
                
                                 <div className='vT'>
                                 TOTAL : {totalPay+totalPay2} FCFA
                                 </div>
                            </div>
                            
                        </div>
                    
                    
                       
                    
                        <br/>
                    
                    
                    
                        </div>
                    
                        <Footer/>
                        {modalCand && (<>
<div className="overlay"></div>
<div className="fenetre-modal">
     
<Link to='/create' className='yello'> <button className='btnP'>NOUVEAU CANDIDAT</button> </Link><br/><br/>
                                 <input type="text" className="rechercheMod" name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e=>setQuery(e.target.value)}></input>
                            
  <div className="modal-entete">
 
                                
                                </div>
                                <div className="modal-corps">
    <table className="table-inscrits">
      <thead>
        <tr>
          <th>Nom</th>
          <th>Prénom</th>
        </tr>
      </thead>
      <tbody>
        {   candidat.filter((data) =>data.nom.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query)).reverse().map((data, i)=> (
          <tr key={i} className={i % 2 === 0 ? "ligne-grise" : "ligne-blanche"}>
            <td ><Link to={'/pageCandidatPaye/'+ data.id} className='bnfs'>{data.nom}</Link></td>

            <td><Link to={'/pageCandidatPaye/'+ data.id} className='bnfs'>{data.prenom}</Link></td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  <div className="boutons-modal">
    <Link onClick={toggle2}>
      <button className="bouton-annuler">QUITTER</button>
    </Link>
  </div>
  </div>
 

</>


                       
                    )}
                    
                    
                        </>
                        )
                    }
                    else{


                        return(
                            <>
                        <div className='wini'>
                            <Navbar/>
                            <h1 className='win'>HISTORIQUE DES RECETTES DU MOIS DE {mois}</h1><br/>
                    
                    {load}
                            <div className='barContener'>

{/* Barre de recherche */}
<div className='barChercheT'>
    <input type="text"className='rechBar'  name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e => setQuery(e.target.value)}></input>
</div>

{/* Barre de sélection avec bouton Appliquer */}
<div className='barChoixT'>
    <select name="poste" className='choosee' onChange={e => setValue(e.target.value)}>
        <option>Ce mois</option>
        <option>Cette année</option>
        <option>Tous les paiements</option>
    </select>
    {/* Bouton Appliquer pour le select */}
    <button className='butonSF' onClick={handleTout}>Appliquer</button>
</div>

{/* Barre de recherche personnalisée */}
<div className='persBarT'>
    
    <input type='date' className='perSF' value={date1} onChange={e => setDate1(e.target.value)}></input>
    <input type='date' className='perSF' value={date2} onChange={e => setDate2(e.target.value)}></input>
    <button type='submit' className='butonSF' onClick={handlePropose}>APPLIQUER</button>
</div>

</div>
                            <div className=''>
                            <div className=''>
                                 <Link onClick={toggle2} className='yello'> <button className='ajouter'>AJOUTER</button> </Link><br/><br/>
                   


{
                        
                        payement.filter((data) => data.date.toLowerCase().includes(query) || data.nom.toLowerCase().includes(query) ||  data.prenom.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                              
                                <span className='texte-depense'>
                                <div> {data.nom} {data.prenom}</div>
                            <span className="montant">{data.montant} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                             <Link onClick={e => setView(data.id)}  >  <span className="icone-modifier"> </span></Link> 
                               {modal && (<div className='modal'>
    <div className='overlay'>
        
    </div>
    <div className='modal-content'>
        <h2>Attention cette recette ne peut plus être modifiée!!!</h2>
      <Link onClick={toggleModal}>  <img src={croix} className='close-modal'/></Link>
    </div>
</div>)}
                             
                          </div>
                        ))
                      }
               {      

                
                     listePay2.filter((data) => data.date.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query) || data.nom.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                              
                                <span className='texte-depense'>
                                <div> {data.nom} {data.prenom}</div>
                            <span className="montant">{data.montant_payer} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">Premier versement</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                           
                          </div>
                        ))
                      }
                                 <div className='vT'>
                                 TOTAL : {totalPay+totalPay2} FCFA
                                 </div>
                            </div>
                            
                        </div>
                    
                    
                       
                    
                        <br/>
                    
                    
                    
                        </div>
                    
                        <Footer/>
                         {modalCand && (<>
<div className="overlay"></div>
<div className="fenetre-modal">
     
<Link to='/create' className='yello'> <button className='btnP'>NOUVEAU CANDIDAT</button> </Link><br/><br/>
<input type="text" className="rechercheMod" name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e=>setQuery(e.target.value)}></input>
                            
  <div className="modal-entete">
 
                                
                                </div>
                                <div className="modal-corps">
    <table className="table-inscrits">
      <thead>
        <tr>
          <th>Nom</th>
          <th>Prénom</th>
        </tr>
      </thead>
      <tbody>
        {   candidat.filter((data) => data.nom.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query)).reverse().map((data, i)=> (
          <tr key={i} className={i % 2 === 0 ? "ligne-grise" : "ligne-blanche"}>
            <td ><Link to={'/pageCandidatPaye/'+ data.id} className='bnfs'>{data.nom}</Link></td>

            <td><Link to={'/pageCandidatPaye/'+ data.id} className='bnfs'>{data.prenom}</Link></td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  <div className="boutons-modal">
    <Link onClick={toggle2}>
      <button className="bouton-annuler">QUITTER</button>
    </Link>
  </div>
  </div>
 

</>


                       
                    )}
                    
                    
                        </>
                        )
                       
                                        }

                    }
}

                                
                               