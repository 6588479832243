import React, {useEffect, useState } from "react"
import axios from "axios";
import {  useNavigate, useParams } from "react-router-dom";


import moment from "moment";




import Cookies from 'js-cookie';
export default function Option2(){
  
  
  const [lu,setLu] = useState(Cookies.get('token'))
   const [tok, setTok]= useState(Cookies.get('token'))
   const [titre,setTitre]= useState(Cookies.get('titre'))
   
   const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
  
   const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
    'credentials': 'include',
  };

  
  
  const [stat,setStat]= useState("")
  const [le, setLe]= useState('')
  const [le2,setLe2]= useState('')
  const [name, setName]= useState('')
  const [prenom, setPrenom]= useState('')
  const [total, setTotal]= useState('')
  const [contact, setContact]= useState('')
  const [sexe, setSexe]= useState('')
  const [adresse, setAdresse]= useState('')
  const [age,setAge]= useState('')
  const [montant, setMontant]= useState('')
  const [statut, setStatut]= useState('')
  const [date, setDate]= useState('')
  const [nombre, setNombre]= useState([])
  const [nombre2, setNombre2]= useState([])
  

  const {id}= useParams();
  
  const navigate = useNavigate();
  const [payement, setPayement]= useState([])
  const [largeurEcran, setLargeurEcran] = useState(window.innerWidth);
    
  const mettreAJourLargeurEcran = () => {
      setLargeurEcran(window.innerWidth);
  
    };
    useEffect(() => {
      
      window.addEventListener('resize', mettreAJourLargeurEcran);
  
     
      return () => {
        window.removeEventListener('resize', mettreAJourLargeurEcran);
      };
    }, []);
    useEffect(()=>{
      if(!Cookies.get('token')){
         navigate('/')
      }
 }, [])
 useEffect(()=>{
  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/setToken' ,{gestauto,titre}, { headers })
  .then(res => 
      {setTok(res.data[0].token)
          

      })
  .catch(err => console.log(err));
}, [])

 

if(tok!= lu){
  Cookies.remove('token')
  window.location.reload();
  navigate('/')

}

 
  
  
  



  const [candidat, setCandidat] = useState([])
  useEffect(()=>{
      axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/update/'+id, { headers })
      .then(res => { 
          setLe(res.data[0].id)
          setName(res.data[0].nom)
          setPrenom(res.data[0].prenom)
          setContact(res.data[0].contact)
          setSexe(res.data[0].sexe)
          setAdresse(res.data[0].adresse)
          setAge(res.data[0].age)
          setMontant(res.data[0].montant_payer)
          setStatut(res.data[0].statut_payement)
          setDate(res.data[0].date)
          setCandidat(res.data)
          setTotal(res.data[0].montant_total)
          if(res.data[0].resultat_conduite>=12
            ){
            setStat('ADMIS')
        }
        else if(res.data[0].resultat_code<14 && res.data[0].resultat_code!=null){
          setStat('NON ADMIS')
        }
        else if(res.data[0].resultat_conduite==null){
          setStat('')
        }else{
          setStat('NON ADMIS')
        }
      
      }
          
          
          
     
      
         


          
          )
      .catch(err => console.log(err));

  
  }, [])
  if(name!='' || prenom!=""){
    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/affichePayement',{le}, { headers })
    .then(res => setPayement(res.data)
  ).catch(err => console.log(err))
  setName('')
  setPrenom('')
  }
  

let totalPaye=0
const first=montant



payement.map((data, i)=> ( 
              
  totalPaye=totalPaye+data.montant    

))
  const paye= first+totalPaye
  
 const calcul= total 


 if(le!=''){
  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/nombre2',{le}, { headers })
  .then(res => setNombre2(res.data)
 ).catch(err => console.log(err))
 
 axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/nombre',{le}, { headers })
  .then(res => setNombre(res.data)
 ).catch(err => console.log(err))
 setLe2(le)
 setLe('')
 }
 
         

var o

  


if(largeurEcran>=880){
  return(


    <>
    
    <div className="option">
    
       
  
<div className="versement">

<br/><br/>


<h2 className="title">VERSEMENTS</h2>
  <div className="">
    <div className="">
      <div className="">

     
      <div className="biann"> 
      <table className='ta'>
             <thead >
                    <tr>
                    
                    <th >Versement</th>
                    <th >Libelle</th>
                    <th >Date</th>
                    <th >Montant</th>
                    </tr>
                 </thead>
                
               <tbody>
                      {
                        candidat.map((data, i)=> (
                            <tr>
                                <td className='th'   data-label='Versement'> 1 </td>
                                <td className='th'   data-label='Libelle'>INSCRIPTION</td>
                                <td className='th'   data-label='Date'>{moment(data.date).utc(1).format('DD-MM-YYYY')}</td>
                                <td className='th'   data-label='Montant'>{data.montant_payer} FCFA</td>
                                
                                
                                
                                
                            </tr>
                        ))
                      } 

                       {
                       
                       payement.map((data, i)=> (
                        <tr >
                            <td className='th'   data-label='Versement'> {i+2} </td>
                            <td className='th'   data-label='Libelle'> {data.libelle}</td>
                            <td className='th'   data-label='Date'> {moment(data.date).utc(1).format('DD-MM-YYYY')}</td>
                            <td className='th'   data-label='Montant'>  {data.montant} FCFA</td>
                             
                            
                           
                            
                            
                        </tr>

                    )) 

                    
                      } 

<tr>
         <td className="" ><h3 className='tol'>TOTAL</h3></td>
            <td className=""> </td>
            <td className=""> </td>
            <td className=''><h4 className="ha">{first+totalPaye} FCFA</h4></td>
         </tr>
                     
                 </tbody> 
             </table>
          
             
             

        <div className="omega">
          <div className="ome">
        <h3 className='to'>MONTANT A PAYER </h3>
        <h4>{total} FCFA</h4>
        </div>   
        <div className="ome">
        <h3 className='to'>RESTE A PAYER </h3> 
        <h4>{calcul-paye} FCFA</h4>
            
          </div> 
        </div>    
        </div>
        </div>
    </div>
        </div>


<br/>
<br/>

<div className="itec">
<div>

<h2 className="title">PRESENCE</h2>

<div className="biou">
 
     <div>
    
     <table className='tah'>
         <thead>
            <tr>
            <th> Presence cours de Code : {nombre2.length}</th>                    
              </tr>
            </thead>
         
              {  
                nombre2.map((data, i)=> ( 
                    <tr>        
                        <td className='tbilan'><div className="timus">{moment(data.date).utc(1).format('DD-MM-YYYY')} {data.time}</div></td>                                              
                    </tr>     
                ))
              }  
     </table>
     </div>
     <br/>
     <div>
   
   <table className='tah'>
<thead>
<tr>
<th>Presence cours de Conduite : {nombre.length}</th>                    
</tr>
</thead>

{  
  nombre.map((data, i)=> ( 
      <tr>        
          <td className='tbilan'><div className="timus">{moment(data.date).utc(1).format('DD-MM-YYYY')} {data.time}</div></td>                                              
      </tr>     
  ))
}  
</table>
</div>
    
 </div>
 </div>
<div>   


<div className="space">
  
<h2 className="title">RESULTATS</h2>
<div className="recadre">
     <div >
   <div className="bian">
   
                 <table className='tam'>
         <thead>
         <tr>
            <th> Resultat cours de Code</th>                    
              
            
            
            <th>Resultat cours de Conduite </th>  
            <th>Statut</th>  
                            
              </tr>
            </thead>
            <tr> 
              {  
                candidat.map((data, i)=> ( 
                           
                        <td className='th'   data-label='Code'>{data.resultat_code}</td>                                              
                         
                ))
              } 
               {  
                candidat.map((data, i)=> ( 
                           
                        <td className='th'   data-label='Conduite'>{data.resultat_conduite}</td>                                              
                         
                ))
              } 
              <td className="th"   data-label='Statut'>{stat}</td>
              
              </tr> 
     </table>
     <br/><br/>
     </div>
     <div>
     </div>
     
     </div>
     </div>

     </div>








</div>


</div>









  

        

      
<br/> 


<br/>
<br/>

<br/>

<br/><br/><br/><br/>


      </div>

      </div>





      
        
    </>
)
            }else{
              return(


                <>
                
                <div className="option">
                
                   
              
            <div className="versement">
            
            <br/>
            
            <h2 className="title">VERSEMENTS</h2>
            <br/>
              <div className="">
                <div className="">
                  <div className="">
            
                
             
                  <div className="bloom">
                  {
                        
                        candidat.reverse().map((data, i)=> (
                            <div className="bilan-depense">
                              
                                <span className='texte-depense'>
                                <div> {data.nom} {data.prenom}</div>
                            <span className="montant">{data.montant_payer} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle"> versement 1</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                          
                             
                          </div>
                        ))
                      }
                         
                  {
                        
                        payement.reverse().map((data, i)=> (
                            <div className="bilan-depense">
                              
                                <span className='texte-depense'>
                                <div> {data.nom} {data.prenom}</div>
                            <span className="montant">{data.montant} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle"> versement {i+2}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                          
                             
                          </div>
                        ))
                      }

<div className="omega">
                      <div className="ome">
                    <h3 className='tool'>MONTANT A PAYER </h3>
                    <h4>{total} FCFA</h4>
                    </div>   
                    <div className="ome">
                    <h3 className='tool'>RESTE</h3> 
                    <h4>{calcul-paye} FCFA</h4>
                        
                      </div> 
                    </div>   
                    </div>
                    </div>
                </div>
                    </div>
            
            
            <br/>
            <br/>
            <div className="bourse">
            <div className="itec">
            <div>
            
            <h2 className="title">PRESENCE</h2>
            
            <div className="biou">
             
                 <div>
                
                 <table className='tah'>
                     
                        <tr>
                        <th className="ninh"> Presence cours de Code : {nombre2.length}</th>                    
                          </tr>
                        
                     
                          {  
                            nombre2.map((data, i)=> ( 
                                <tr>        
                                    <td className='tbilan' ><div className="timus">{moment(data.date).utc(1).format('DD-MM-YYYY')} {data.time}</div></td>                                              
                                </tr>     
                            ))
                          }  
                 </table>
                 </div>
                 <br/>
                 <div>
               
               <table className='tah'>
            
            <tr>
            <th className="ninh">Presence cours de Conduite : {nombre.length}</th>                    
            </tr>
            
            
            {  
              nombre.map((data, i)=> ( 
                  <tr>        
                      <td className='tbilan'><div className="timus">{moment(data.date).utc(1).format('DD-MM-YYYY')} {data.time}</div></td>                                              
                  </tr>     
              ))
            }  
            </table>
            </div>
                
             </div>
             </div>
             
            
             
             </div>
             <div>   
            <div className="space">
              
            <h2 className="title">RESULTATS</h2>
            <div className="recadre">
                 <div >
               <div className="bian">
               
                             <table className='tam'>
                     <thead>
                     <tr>
                        <th> Resultat cours de Code</th>                    
                          
                        
                        
                        <th>Resultat cours de Conduite </th>  
                        <th>Statut</th>  
                                        
                          </tr>
                        </thead>
                        <tr> 
                          {  
                            candidat.map((data, i)=> ( 
                                       
                                    <td className='th'   >{data.resultat_code}</td>                                              
                                     
                            ))
                          } 
                           {  
                            candidat.map((data, i)=> ( 
                                       
                                    <td className='th'  >{data.resultat_conduite}</td>                                              
                                     
                            ))
                          } 
                          <td className="th"   >{stat}</td>
                          
                          </tr> 
                 </table>
                 <br/><br/>
                 </div>
                 <div>
                 </div>
                 
                 </div>
                 </div>
            
                 </div>
            
            </div>
            </div>
            
            
                    
            
                  
            <br/> 
            
            
            <br/>
            <br/>
            
            <br/>
            
            <br/><br/><br/><br/>
            
            
                  </div>
            
                  </div>
            
            
            
            
            
                  
                    
                </>
            )
            }
}
    
    




