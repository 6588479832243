import React,{ useEffect, useState} from 'react';
import axios from 'axios';
import Loader from "../components/loader";
import moment from "moment";
import moni from 'F:/projet/first/src/components/image/moni.png';
import log from 'F:/projet/first/src/components/image/log.png';
import Footer from '../components/footer';
import { Link , useParams,NavLink, useNavigate,  useLocation} from "react-router-dom";
import Cookies from 'js-cookie';
import { sidedataDir } from 'F:/projet/first/src/components/sidedataDir'
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { IconContext } from "react-icons";
import logo from 'F:/projet/first/src/components/image/logo4.jpg'
export default function Bilan(){
  const [loader, setLoader]= useState(true)
  const navigate = useNavigate();
  const [titre,setTitre]= useState(Cookies.get('titre'))
  const [error, setError] = useState(null);
  const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
    'credentials': 'include',
  };
  const [sidebar, setSidebar]= useState(false)
    const showSidebar = () => setSidebar(!sidebar)
    const [lu,setLu] = useState(Cookies.get('token'))
    const [tok, setTok]= useState('')
    const [ce,setCe]= useState(Cookies.get('autoa'))
    const [largeurEcran, setLargeurEcran] = useState(window.innerWidth);
  
    const [viewLoader, setViewLoader] = useState(false)
    const [select1,setSelect1]= useState(Cookies.get('select1'))
    const [select2,setSelect2]= useState(Cookies.get('select2'))


    const [old, setOld]= useState('')
    const [recup, setRecup]= useState('')
    const [new1, setNew1]= useState('')
    const [new2, setNew2]= useState('')
    const [info1, setInfo1]= useState('')
    const [info2, setInfo2]= useState('')


    const mettreAJourLargeurEcran = () => {
        setLargeurEcran(window.innerWidth);
    
      };

      if(tok!= ''){
        if(tok!= lu){
            Cookies.remove('token')
            Cookies.remove('autoa')
            Cookies.remove('titre')
            Cookies.remove('gestauto')
            window.location.reload();
            navigate('/')

        }
    }

    useEffect(()=>{
         if(!Cookies.get('token')){
            navigate('/')
         }
    }, [])


    
    const logout = () =>{
        Cookies.remove('token')
        Cookies.remove('autoa')
        Cookies.remove('titre')
        Cookies.remove('gestauto')
        Cookies.remove('default')
        Cookies.remove('default2')
        Cookies.remove('defaultCode')
        Cookies.remove('defaultCode2')
        Cookies.remove('select1')
        Cookies.remove('select2')
        window.location.reload();
       navigate('/')
    }
    useEffect(()=>{
        if(!Cookies.get('token')){
           navigate('/')
        }
   }, [])





useEffect(() => {
        
    window.addEventListener('resize', mettreAJourLargeurEcran);

   
    return () => {
      window.removeEventListener('resize', mettreAJourLargeurEcran);
      
    };
   
  }, []);
// Recuperer token
useEffect(()=>{
    

axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/setToken', { gestauto, titre},{ headers})
    .then(res => 
        {setTok(res.data[0].token)
        

        })
    .catch(err => console.log(err));
}, [])
//nav en haut
const[profil, setProfil]= useState(false)
    
    const showProfil = () => setProfil(!profil)
    const [idAnnexe,setIdAnnexe]= useState('')
    const [secretaire, setSecretaire] = useState([])
    useEffect(()=>{
        axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/navbar?id='+gestauto+'&titre='+titre+'', { headers})
        .then(res => setSecretaire(res.data))
        .catch(err => console.log(err));
    }, [])
    const [loga, setLoga]= useState('')
    
    const [logaId, setLogaID]= useState(0)
    const [valeurLogaID, setValeurLogaID]= useState('')
    const [lea,setLea]=useState([0])
    var date= new Date();
   
   const years= date.getFullYear()
  var  month=  date.getMonth()+1
  var autre= date.toISOString().split('T')[0]
  const days= date.getDate()
  var datee= new Date(years,month-2,days)
  var autre2= datee.toISOString().split('T')[0] 
    const[value,setValue]= useState('')
    const [annexe, setAnnexe]= useState('')
    const [date1, setDate1]= useState('')
  const [date2, setDate2]= useState('')

   

    const [ville, setVille] = useState([])
    useEffect(()=>{
        axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/candidatDir?id='+ce+'' , { headers})
        .then(res =>{ 
          setVille(res.data)
          setLoga(res.data[0].ville)
          setLogaID(res.data[0].id_annexe)
          setValeurLogaID(res.data[0].id_annexe)
          setAnnexe(res.data[0].ville)
        })
        .catch(err => console.log(err));
    }, [])

    const [id_annexe]= ville.map((data)=>(data.id_annexe));
    
     const [query, setQuery]= useState(""); 
    



         
        

         const [candidat, setCandidat] = useState([])
         const [depense, setDepense] = useState([])
         const [payement, setPayement] = useState([])
         const [depenseDir, setDepenseDir]= useState([])


         function handleSubmit(event){
            event.preventDefault();
            setViewLoader(true)
            const [id_ecole]= ville.map((data)=>(data.id_ecole));

            if(annexe=='Toutes les annexes'){
              if(value==''){
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/comptePaye',{id_annexe,month,years}, { headers})
                .then(res => {
                  setPayement(res.data)
                  setViewLoader(false)
            
                }).catch(err => {
                  console.log(err)
                 setError(err)
                 setViewLoader(false) 
                });
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/compteDepense',{id_annexe,month,years}, { headers})
           .then(res => {
            setDepense(res.data)
 
           setViewLoader(false)
            } ).catch(err => {
              console.log(err)
             setError(err)
             setViewLoader(false) 
            });

           axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/compteDepense2', {id_annexe,month,years}, { headers})
           .then(res => {
            setDepenseDir(res.data)
            setViewLoader(false)
           
            } ).catch(err => {
              console.log(err)
             setError(err)
             setViewLoader(false) 
            });
           axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/compte2', {id_annexe,month,years}, { headers})
           .then(res => setCandidat(res.data)
           
           ).catch(err => console.log(err));
              }else  if(annexe=='Toutes les annexes'){
                if(value=='Enregistré ce mois'){
                  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/comptePaye',{id_annexe,month,years}, { headers})
                    .then(res => {
                      setPayement(res.data)
      
                setViewLoader(false)
                    }).catch(err => {
                      console.log(err)
                      setError(err)
                      setViewLoader(false)
                     } );
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/compteDepense',{id_annexe,month,years}, { headers})
               .then(res => {
                setDepense(res.data)
                setViewLoader(false)
               
                }).catch(err => {
                  console.log(err)
                 setError(err)
                 setViewLoader(false) 
                });

               axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/compteDepense2', {id_annexe,month,years}, { headers})
               .then(res => {
                setDepenseDir(res.data)
     setViewLoader(false)
               
                } ).catch(err => console.log(err));
               axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/compte2', {id_annexe,month,years}, { headers})
               .then(res => {
                setCandidat(res.data)
             
               setViewLoader(false)
                }).catch(err => {
                  console.log(err)
                 setError(err)
                 setViewLoader(false) 
                });
                  } else if(value=='Enregistré cette année'){
                    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/tousDepenseYears',{years,le,o,id_annexe}, { headers})
                    .then(res => {
                      setDepense(res.data)
                      setViewLoader(false)
                    })
                    .catch(err => {
                      console.log(err)
                     setError(err)
                     setViewLoader(false) 
                    });
  
                    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/tousDepenseYears2',{years,le,o,id_annexe}, { headers})
                    .then(res => {
                      setDepenseDir(res.data)
                      setViewLoader(false)
                    })
                    .catch(err => {
                      console.log(err)
                      setError(err)
                      setViewLoader(false)
                    });
                    
                    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/tousCandidatYears',{years,le,o,id_annexe}, { headers})
                    .then(res => {
                      setCandidat(res.data)
                      setViewLoader(false)
                      
                  })
                    .catch(err => {
                      console.log(err)
                     setError(err)
                     setViewLoader(false) 
                    });
  
                    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/tousPayementYears',{years,le,o,id_annexe}, { headers})
                    .then(res => {
                      setPayement(res.data)
                     setViewLoader(false) 
                    })
                    .catch(err => {
                      console.log(err)
                     setError(err) 
                     setViewLoader(false)
                    });
  
                }
              }
            }else{
           
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/candidatDir', {annexe,id_annexe,id_ecole}, { headers})
            .then(res => {
                
             
                const a= res.data[0].id_ecole
              
                setLea(res.data)

                if(value==''){
                  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/infoPayement', {a,month,years}, { headers})
                  .then(res => {
                    setPayement(res.data)
                    setViewLoader(false)
                  
                }).catch(err => {
                  console.log(err)
                 setError(err)
                 setViewLoader(false) 
                  
                });
  
                  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/infoDepense', {a,month,years}, { headers})
                  .then(res => {
                    setDepense(res.data)
                    setViewLoader(false)
                  
                   } ).catch(err => {
                    console.log(err)
                    setError(err)
                    setViewLoader(false)
                   });
  
                  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/infoDepense2', {a,month,years}, { headers})
                 .then(res => {
                  setDepenseDir(res.data)
       setViewLoader(false)
                 
                }).catch(err => {
                  console.log(err)
                  setError(err)
                  setViewLoader(false)
                });
  
  
                 axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/info', {a,month,years}, { headers})
                 .then(res => {
                  setCandidat(res.data)
                 setViewLoader(false)
                }).catch(err => {
                  console.log(err)
                 setError(err)
                 setViewLoader(false) 
                });
                }else{
                  //value chose
                  if(value=='Enregistré ce mois'){
                    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/depenseInfoThisInit',{month,years,a}, { headers})
                    .then(res => {
                      setDepense(res.data)
                     setViewLoader(false) 
                    })
                    .catch(err => {
                      console.log(err)
                     setError(err)
                     setViewLoader(false) 
                    });
                    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/moisInit',{month,years,a}, { headers})
                    .then(res => {
                      setCandidat(res.data)
                     setViewLoader(false) 
                    })
                    .catch(err => {
                      console.log(err)
                     setError(err)
                     setViewLoader(false) 
                    });
  
                    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/depenseInfoThisInit2',{month,years,a}, { headers})
                  .then(res => {
                    setDepenseDir(res.data)
                   setViewLoader(false) 
                  })
                  .catch(err => {
                      console.log(err)
                     setError(err)
                     setViewLoader(false) 
                    });
  
                  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/payementInfoThisInit',{month,years,a}, { headers})
                  .then(res => {
                    setPayement(res.data)
                    setViewLoader(false)
                  })
                  .catch(err => {
                      console.log(err)
                     setError(err)
                     setViewLoader(false) 
                    });
  
                  
                    
  
                    }
                else if(value=='Enregistré cette année'){
                    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/depYearInit',{years,a}, { headers})
                    .then(res => {
                      setDepense(res.data)
                     setViewLoader(false) 
                    })
                    .catch(err => {
                      console.log(err)
                     setError(err)
                     setViewLoader(false) 
                    });
  
                    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/depYearInit2',{years,a}, { headers})
                    .then(res => {
                      setDepenseDir(res.data)
                     setViewLoader(false) 
                    })
                    .catch(err => {
                      console.log(err)
                     setError(err)
                     setViewLoader(false) 
                    });
                    
                    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/toutInit',{years,a}, { headers})
                    .then(res => {
                      setCandidat(res.data)
                     setViewLoader(false) 
                    })
                    .catch(err => {
                      console.log(err)
                     setError(err)
                     setViewLoader(false) 
                    });
  
                    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/payementInfoThisYearsInit',{years,a}, { headers})
                    .then(res => {
                      setPayement(res.data)
                      setViewLoader(false)
                    })
                    .catch(err => {
                      console.log(err)
                     setError(err)
                     setViewLoader(false) 
                    });
  
                }else if(value=='Tous les Enregistrements'){
                    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/depInfoInit',{a}, { headers})
                    .then(res => {
                      setDepense(res.data)
                      setViewLoader(false)
                    })
                    .catch(err => {
                      console.log(err)
                     setError(err)
                     setViewLoader(false) 
                    });
  
                    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/depInfoInit2',{a}, { headers})
                  .then(res => {
                    setDepenseDir(res.data)
                   setViewLoader(false) 
                  })
                  .catch(err => {
                      console.log(err)
                     setError(err)
                     setViewLoader(false) 
                    });
  
                  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/candInit',{a}, { headers})
                  .then(res => {
                    setCandidat(res.data)
                   setViewLoader(false) 
                  })
                  .catch(err => {
                      console.log(err)
                     setError(err)
                     setViewLoader(false) 
                    });
  
                  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/payInfoInit',{a}, { headers})
                  .then(res => {
                    setPayement(res.data)
                   setViewLoader(false) 
                  })
                  .catch(err => {
                      console.log(err)
                     setError(err)
                     setViewLoader(false) 
                    });
                }
  
  
                }

                

 
            }).catch(err => {
                      console.log(err)
                     setError(err)
                     setViewLoader(false) 
                    });

           

         
          }
        }
       


        let totalPaye=0
        let totalPaye2=0
        let totalDep1=0
        let totaDep2=0
        
         
            payement.map((data, i)=> ( 
                
                      totalPaye=totalPaye+data.montant    
                
            ))
            
            candidat.map((data, i)=> ( 
                
              totalPaye2=totalPaye2+data.montant_payer    
        
    ))
            depense.map((data, i)=> (
                totalDep1=totalDep1+data.montant
            ))
          
            depenseDir.map((data, i)=> (
              totaDep2=totaDep2+data.montant
            ))
          const totalDep=totalDep1+totaDep2

          
          const  le = lea.map((data)=>(data.id_ecole))      
    
        var o=le
        const {id}= useParams();
        if (o!=0){
            o=le
        }else{
            
            o=id
        }

        if(logaId!='' && o==0){
          if(select1=== undefined || select2===undefined){
          axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defa', {loga,logaId}, { headers})
          .then(res => {
              
   
              const a= res.data[0].id_ecole
        
              setLea(res.data)

              axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/infoPayement', {a,month,years}, { headers})
              .then(res => {
                setPayement(res.data)
                 setLoader(false)
              }
              ).catch(err =>{
                 console.log(err)
                 setError(err)
                 setLoader(false)
                });

              axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/infoDepense', {a,month,years}, { headers})
              .then(res => {
                setDepense(res.data)
                setLoader(false)
              }
              
              ).catch(err =>{
                console.log(err)
                setError(err)
                setLoader(false)
              } );

              axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/infoDepense2', {a,month,years}, { headers})
             .then(res => {
              setDepenseDir(res.data)
              setLoader(false)
             }
             ).catch(err =>{
               console.log(err)
               setError(err)
               setLoader(false)
              });


             axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/info', {a,month,years}, { headers})
             .then(res => {
              setCandidat(res.data)
              setLoader(false)

             }
             ).catch(err => {
              console.log(err)
              setError(err)
              setLoader(false)
             });


          }).catch(err => console.log(err));

        }else if(select1 || select2){

          axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defa', {loga,logaId}, { headers})
          .then(res => {
              
   
              const a= res.data[0].id_ecole
              setDate1(select1)
                    setDate2(select2) 



                    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/vVoirDep', {a,select1,select2}, { headers})
                    .then(res => {
                        setDepense(res.data)
                        setLoader(false)
                    
                  }).catch(err =>{
                     console.log(err)
                     setError(err)
                     setLoader(false)
                     
                  });
        
                    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/vVoirDep2', {a,select1,select2}, { headers})
                    .then(res => {
                        setDepenseDir(res.data)
                        setLoader(false)
                    
                  }).catch(err => {
                    console.log(err)
                   setError(err)
                   setLoader(false) 
                  });

                  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/vVoirPaye', {select1,select2,a}, { headers})
                  .then(res => {
                     setPayement(res.data)
                  
                      setLoader(false)
                  }).catch(err => {
                      console.log(err)
                   setError(err)
                   setLoader(false)   
                  });


                  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/vVoirBilan', {a,select1,select2}, { headers})
                  .then(res =>{ 
                     
                      setCandidat(res.data) 
                      setLoader(false)            
                  }
                      
                  ).catch(err =>{
                       console.log(err)
                      setError(err)
                      setLoader(false)
                      
                      });

        
            }).catch(err => console.log(err));

           


        }

        }



        useEffect(()=>{
          if(o!=0){
          axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/idAnnexe', {o}, { headers})
          .then(res =>{ 
              
              setIdAnnexe(res.data[0].ville)             
          }
             
  
          ).catch(err => console.log(err));
        }
      }, [])
          var b
         
   
          
         if(idAnnexe!=''){
          b= idAnnexe
        }  else{
          b= loga
        }
        

// select auto

useEffect(()=>{
  if(o!=0){
    if(select1=== undefined || select2===undefined){
  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/tic', {o,month,years,le}, { headers})
  .then(res =>{    
      setCandidat(res.data) 
      setLoader(false)            
  }    
  ).catch(err => {
    console.log(err)
  setError(err)
  setLoader(false)
  });
}else if(select1 || select2)
  {

    


        const a= o
        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/vVoirBilan', {a,select1,select2}, { headers})
        .then(res =>{ 
          setDate1(select1)
          setDate2(select2) 
            setCandidat(res.data) 
            setLoader(false)            
        }
            
        ).catch(err =>{
             console.log(err)
            setError(err)
            setLoader(false)
            
            });


  }
}
}, [])

useEffect(()=>{
  if(o!=0){
    if(select1=== undefined || select2===undefined){
  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/ticDep2', {o,month,years,le}, { headers})
  .then(res =>{    
      setDepenseDir(res.data) 
      setLoader(false)           
  }
  ).catch(err => {
    console.log(err)
    setError(err)
    setLoader(false)
  });
}else if(select1 || select2){

      const a= o
      axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/vVoirDep2', {a,select1,select2}, { headers})
      .then(res => {
          setDepenseDir(res.data)
          setLoader(false)
      
    }).catch(err => {
      console.log(err)
     setError(err)
     setLoader(false) 
    });

}
  }
}, [])


useEffect(()=>{
  if(o!=0){
    if(select1=== undefined || select2===undefined){
  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/ticDep', {o,month,years,le}, { headers})
  .then(res =>{    
      setDepense(res.data)    
      setLoader(false)        
  }
  ).catch(err => {
    console.log(err)
    setError(err)
    setLoader(false)
  });
}else if(select1 || select2){

      const a= o
    
      
      axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/vVoirDep', {a,select1,select2}, { headers})
      .then(res => {
          setDepense(res.data)
          setLoader(false)
      
    }).catch(err =>{
       console.log(err)
       setError(err)
       setLoader(false)
       
    });

}
  }
}, [])


useEffect(()=>{
  if(o!=0){
    if(select1=== undefined || select2===undefined){
  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/ticPay', {o,month,years,le}, { headers})
  .then(res =>{      
      setPayement(res.data)
      setLoader(false)           
  }    
  ).catch(err =>{ 
    console.log(err)
    setError(err)
    setLoader(false)
  });
}else if(select1 || select2){
  

      const a= o
      axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/vVoirPaye', {select1,select2,a}, { headers})
      .then(res => {
         setPayement(res.data)
      
          setLoader(false)
      }).catch(err => {
          console.log(err)
       setError(err)
       setLoader(false)   
      });
}
  }
}, [])


          function handleTout(event){
              event.preventDefault();

              if(annexe=='Toutes les annexes'){
                if(value=='Enregistré ce mois'){
                  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/comptePaye',{id_annexe,month,years}, { headers})
                    .then(res => setPayement(res.data)
      
                
                ).catch(err => console.log(err));
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/compteDepense',{id_annexe,month,years}, { headers})
               .then(res => setDepense(res.data)
     
               
               ).catch(err => console.log(err));

               axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/compteDepense2', {id_annexe,month,years}, { headers})
               .then(res => setDepenseDir(res.data)
     
               
               ).catch(err => console.log(err));
               axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/compte2', {id_annexe,month,years}, { headers})
               .then(res => setCandidat(res.data)
               
               ).catch(err => console.log(err));
                  } else if(value=='Enregistré cette année'){
                    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/tousDepenseYears',{years,le,o,id_annexe}, { headers})
                    .then(res => setDepense(res.data))
                    .catch(err => console.log(err));
  
                    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/tousDepenseYears2',{years,le,o,id_annexe}, { headers})
                    .then(res => setDepenseDir(res.data))
                    .catch(err => console.log(err));
                    
                    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/tousCandidatYears',{years,le,o,id_annexe}, { headers})
                    .then(res => setCandidat(res.data))
                    .catch(err => console.log(err));
  
                    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/tousPayementYears',{years,le,o,id_annexe}, { headers})
                    .then(res => setPayement(res.data))
                    .catch(err => console.log(err));
  
                }else if(value=='Tous les Enregistrements'){
                  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/tousdepInfo',{le,o,id_annexe}, { headers})
                  .then(res => setDepense(res.data))
                  .catch(err => console.log(err));

                  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/tousdepInfo2',{le,o,id_annexe}, { headers})
                .then(res => setDepenseDir(res.data))
                .catch(err => console.log(err));

                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/touscandInfo',{le,o,id_annexe}, { headers})
                .then(res => setCandidat(res.data))
                .catch(err => console.log(err));

                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/touspayInfo',{le,o,id_annexe}, { headers})
                .then(res => setPayement(res.data))
                .catch(err => console.log(err));
              }

              }else{
                if(value=='Enregistré ce mois'){
                  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/depenseInfoThis',{month,years,le,o}, { headers})
                  .then(res => setDepense(res.data))
                  .catch(err => console.log(err));
                  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/candidatInfoThis',{month,years,le,o}, { headers})
                  .then(res => setCandidat(res.data))
                  .catch(err => console.log(err));

                  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/depenseInfoThis2',{month,years,le,o}, { headers})
                .then(res => setDepenseDir(res.data))
                .catch(err => console.log(err));

                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/payementInfoThis',{month,years,le,o}, { headers})
                .then(res => setPayement(res.data))
                .catch(err => console.log(err));

                
                  if(o==0){
                    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defaDepenseInfoThis',{month,years,valeurLogaID}, { headers})
                  .then(res => setDepense(res.data))
                  .catch(err => console.log(err));
                  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defaInfoThis',{month,years,valeurLogaID}, { headers})
                  .then(res => setCandidat(res.data))
                  .catch(err => console.log(err));

                  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defaDepenseInfoThis2',{month,years,valeurLogaID}, { headers})
                .then(res => setDepenseDir(res.data))
                .catch(err => console.log(err));

                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defaPayementInfoThis',{month,years,valeurLogaID}, { headers})
                .then(res => setPayement(res.data))
                .catch(err => console.log(err));
                  }

                  }
              else if(value=='Enregistré cette année'){
                  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/depenseInfoThisYears',{years,le,o}, { headers})
                  .then(res => setDepense(res.data))
                  .catch(err => console.log(err));

                  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/depenseInfoThisYears2',{years,le,o}, { headers})
                  .then(res => setDepenseDir(res.data))
                  .catch(err => console.log(err));
                  
                  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/candidatInfoThisYears',{years,le,o}, { headers})
                  .then(res => setCandidat(res.data))
                  .catch(err => console.log(err));

                  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/payementInfoThisYears',{years,le,o}, { headers})
                  .then(res => setPayement(res.data))
                  .catch(err => console.log(err));

              }else if(value=='Tous les Enregistrements'){
                  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/depInfo',{le,o}, { headers})
                  .then(res => setDepense(res.data))
                  .catch(err => console.log(err));

                  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/depInfo2',{le,o}, { headers})
                .then(res => setDepenseDir(res.data))
                .catch(err => console.log(err));

                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/candInfo',{le,o}, { headers})
                .then(res => setCandidat(res.data))
                .catch(err => console.log(err));

                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/payInfo',{le,o}, { headers})
                .then(res => setPayement(res.data))
                .catch(err => console.log(err));
              }


              }
             
            }
  
            useEffect(()=>{
              setDate1(autre2)
              setDate2(autre)
          }, [])
         
            
            function handlePropose(event){
              setViewLoader(true)
                event.preventDefault();
                Cookies.set('select1',date1)
            Cookies.set('select2',date2)
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/personDep2Dir', {date1,date2,le,o}, { headers})
                .then(res => {
                   setDepense(res.data)
                   setViewLoader(false)
                
                     }).catch(err => {
                      console.log(err)
                     setError(err)
                     setViewLoader(false) 
                    });

                     axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/personDep3Dir', {date1,date2,le,o}, { headers})
                .then(res => {
                   setDepenseDir(res.data)
               setViewLoader(false)
                     }).catch(err => {
                      console.log(err)
                     setError(err)
                     setViewLoader(false) 
                    });

                     axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/personPay2Dir', {date1,date2,le,o}, { headers})
                .then(res => {
                   setPayement(res.data)
           setViewLoader(false)
                     }).catch(err => {
                      console.log(err)
                     setError(err)
                     setViewLoader(false) 
                    });
                     axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/personCandidatDir', {date1,date2,le,o}, { headers})
                     .then(res => {
                        setCandidat(res.data)
               setViewLoader(false)
                         
                         
                     }).catch(err => {
                      console.log(err)
                     setError(err)
                     setViewLoader(false) 
                    });
            }


//modification password



const[valid, setValid]= useState('')
              
const[valid2, setValid2]= useState('')
const[supMod, setSupMod]= useState(false) 

const toggleSup = ()=> {
  
 
  setSupMod(!supMod)
  setValid('')
 
}


if(valid!= ''){
 toggleSup()
 


} 



              

const[supMod2, setSupMod2]= useState(false) 

const toggleSup2 = ()=> {
  
 
  setSupMod2(!supMod2)
  setValid2('')
  
}


if(valid2!= ''){
 toggleSup2()
 


} 




function handleModf(event){
    const id= gestauto
    event.preventDefault();
    const currentPassword = secretaire.map((data) => data.password)[0];
    
    if(currentPassword===old){
    
        if(new1===new2){
            setInfo2('')
            axios.put('https://gestauto-56bfcbe4b876.herokuapp.com/upPassword/'+id, {new1,titre}, { headers})
            .then(res => {
                  
        axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/navbar?id='+gestauto+'&titre='+titre+'', { headers })
        .then(res => setSecretaire(res.data))
        .catch(err => console.log(err));
                toggleSup()

            setValid2('on')
            }).catch(err => console.log(err));
        }else{
           
            setInfo2('Incorrecte')
            setTimeout(() => setInfo2(''), 3000)
        }
    }else{
        setInfo1('Incorrecte')
        setTimeout(() => setInfo1(''), 3000)
    }
}





const [menuVisible, setMenuVisible] = useState(false);

const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  
};






useEffect(() => {
    const handleClickOutside = (event) => {
        if (menuVisible && !document.querySelector('.carD').contains(event.target)) {
            setMenuVisible(false);
        }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, [menuVisible]);



         
            var bar 
            const location = useLocation();
            if(largeurEcran>= 1000){
                bar=    <div className="barHaut">

<div className="lep">
                    
                    
                    <img src={moni} className="user" onClick={showProfil} />
                    
                    {supMod && (<div className='modalSup'>
    <div className='overlay'>
        
    </div>
    <div className='modal-contentSupInfo'>
       <div className="containerModal"> 
        <h3 className="form-title2">MODIFIER MOT DE PASSE</h3>
     
      <div className='supD'>
      <input type="text"  name="prenom" className="inputCmot1"  id="prenom"  value={secretaire.map((data)=>(data.nom))}  ></input>
     <input type="text"  name="prenom" className="inputCmot1"  id="prenom"  value={secretaire.map((data)=>(data.prenom))}  ></input>
      
     
      </div>
      <label htmlFor="libelle" className="">Ancien mot de pass</label> <br/>
      <input type="pasword"  name="prenom" className="inputCmot"  id="prenom"   onChange={e => setOld(e.target.value)}   ></input>
      <br/>
      <h4 className="text-danger">{info1}</h4>
      <br/>
      <label htmlFor="libelle" className="">Nouveau mot de pass</label> <br/>
      <input type="pasword"  name="prenom" className="inputCmot"  id="prenom" onChange={e => setNew1(e.target.value)}   ></input>
      <br/>
      <label htmlFor="libelle" className="">Confirmer mot de pass</label> <br/>
      <input type="pasword"  name="prenom" className="inputCmot"  id="prenom"   onChange={e => setNew2(e.target.value)} ></input>
      <br/>
      <h4 className="text-danger">{info2}</h4>
      <br/>
      <div className="supD">
      <Link onClick={handleModf}><button className='nm1' > Modifier </button></Link>
      <Link onClick={toggleSup}><button className='nm2' > Annuler </button></Link>
      </div>
      </div>
    </div>
   
</div>)}
{supMod2 && (<div className='modal'>
    <div className='overlay'>
        
   
    <div className='modal-content'>
       
      <h2>Mot de passe modifier avec succès</h2>
      <div className='supD'>
     
      <Link onClick={toggleSup2}> <button className='supC' >QUITER</button> </Link>
    
      </div>
     
      </div>
    </div>
   
</div>)}
                    
                    <div className="hero"> 
                     <h3 className="nom">{secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}</h3>
                     
                    </div>
       
                    <div class="options">
     <h2 className="form-title2"> {secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}</h2>           
    <button class="option-btn" onClick={e => setValid('on')}>Modifier</button>
    <button class="option-btn2" onClick={logout}>Déconnexion</button>
  </div>
       
                    </div>


                <NavLink to={'/accueil2/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Accueil</button> </NavLink>
            <NavLink to={'/candidatDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Candidats</button> </NavLink>
                <NavLink to={'/depenseDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Depenses</button> </NavLink>
                
                
                <NavLink to={'/payementDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Recettes</button> </NavLink>
                <NavLink to={'/bilan/'+id} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Bilan Global</button> </NavLink>
                <NavLink to={'/optPresenceDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Presence</button> </NavLink>
                <NavLink to={'/resultatDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Resultats</button> </NavLink>
                <Link onClick={logout} className="link">
                               <img src={log} className="am" />
                               <div className="deco">DECONNEXION</div>
                               
                          </Link> 
                
                
            
            </div>
            
            
              
              }else{
                bar=  <IconContext.Provider value={{color:'#fff'}} >
                             
                <div className="icon">
             <div>
             &nbsp;&nbsp;&nbsp;
             <img src={logo} className="userLog"  /> 
             </div>
             <div className="naI">
               {secretaire.map((data)=>(data.auto_ecole))} 
             </div>  
                   
             <div className="navbar">
             
                <Link to="#" className={sidebar?  "trois": "menu-bars"}>
                    <FaIcons.FaBars onClick={showSidebar} />
                </Link>
             
                &nbsp;&nbsp;&nbsp;
                  
             
             </div>
            
 <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
    <ul className="nav-menu-items" >
        <li className="navbar-toggle">
            <Link to="#" className="menu-bars">
               <AiIcons.AiOutlineClose onClick={showSidebar}/>
            </Link>
        </li>
        <div className="carD">
                    <div className="profil">
        <AiIcons.AiOutlineUser className="icone-profil"   onClick={toggleMenu} />
        <div className="infos-utilisateur">
           <p className="inPo"   onClick={toggleMenu}> {secretaire.map((data)=>(data.poste))} </p>
          <p className="inN"  onClick={toggleMenu}> {secretaire.map((data)=>(data.nom))}  {secretaire.map((data)=>(data.prenom))} </p>
        </div>
      </div>


      {menuVisible && (
      <div className="dropdown-menu">
         <button className="option-btnMobi" onClick={e => setValid('on')}>Modifier</button>
         <button className="option-btnMobi2" onClick={logout}>Déconnexion</button>
      </div>
   )}

      </div>
        {sidedataDir.map((item, index) =>{
           const isActive = location.pathname.startsWith(item.path);
          return (
           
            <li key={index} className={isActive ? `${item.cName} active` : item.cName} onClick={showSidebar}>
                               <Link to={item.path+'/'+o}>
                    <span>{item.title}</span>
                 </Link>
                
            </li>
            
          
          )
 
        })}
       <li className="nav-text">
        <Link onClick={logout}  >
             
               <span>Deconnexion</span>
               
          </Link>
          </li> 
    </ul>
 </nav>
             </div>
             {supMod && (<div className='modalSup'>
    <div className='overlay'>
        
    </div>
    <div className='modal-contentSupInfo'>
       <div className="containerModal"> 
        <h3 className="form-title2">MODIFIER MOT DE PASSE</h3>
     
      <div className='supD'>
      <input type="text"  name="prenom" className="inputCmot1"  id="prenom"  value={secretaire.map((data)=>(data.nom))}  ></input>
     <input type="text"  name="prenom" className="inputCmot1"  id="prenom"  value={secretaire.map((data)=>(data.prenom))}  ></input>
      
     
      </div>
      <label htmlFor="libelle" className="">Ancien mot de pass</label> <br/>
      <input type="pasword"  name="prenom" className="inputCmot"  id="prenom"   onChange={e => setOld(e.target.value)}   ></input>
      <br/>
      <h4 className="text-danger">{info1}</h4>
      <br/>
      <label htmlFor="libelle" className="">Nouveau mot de pass</label> <br/>
      <input type="pasword"  name="prenom" className="inputCmot"  id="prenom" onChange={e => setNew1(e.target.value)}   ></input>
      <br/>
      <label htmlFor="libelle" className="">Confirmer mot de pass</label> <br/>
      <input type="pasword"  name="prenom" className="inputCmot"  id="prenom"   onChange={e => setNew2(e.target.value)} ></input>
      <br/>
      <h4 className="text-danger">{info2}</h4>
      <br/>
      <div className="supD">
      <Link onClick={handleModf}><button className='nm1' > Modifier </button></Link>
      <Link onClick={toggleSup}><button className='nm2' > Annuler </button></Link>
      </div>
      </div>
    </div>
   
</div>)}
{supMod2 && (<div className='modal'>
    <div className='overlay'>
        
   
    <div className='modal-content'>
       
      <h2>Mot de passe modifier avec succès</h2>
      <div className='supD'>
     
      <Link onClick={toggleSup2}> <button className='supC' >QUITER</button> </Link>
    
      </div>
     
      </div>
    </div>
   
</div>)} 


             </IconContext.Provider>
                     
             }



             let autreDep
             let totalAutreDep
             if(depense.length>0){
              autreDep =  <h4 className='alie'>Autres Depenses</h4>
              totalAutreDep =  <div className='vT'>
              TOTAL : {totalDep1} FCFA
              </div>
             }else {
              autreDep = ''
              totalAutreDep = ''
             }


             let load

if(viewLoader==true){
    load=   <div className="avt">
    <div className="rin">
      <div className="chm">
        <Loader/>
      </div>
    </div>
  </div>
}else{
    load=''
}


             
if(loader){
  return(
      <div className="per">
      
      <div className="loader">
      <div className="overlayLo"> 
          <div className="loaderP">
          <Loader/>
          </div>
          </div>
          </div>
          </div>
  )
}else if(error){
  return (<div>Une erreur est survenue : {error.message}</div>)
}
else{
             if(largeurEcran>= 1024){ 

    return(
        
        <>
        
    <div className='wini'>

   

{bar}


           <br/> 
           <div>                      
        <div className='popo'  >
             <div>
             <select name="poste" className='sel' onChange={e => setAnnexe(e.target.value)}>
            <option >{b}</option>
                     
                {  
                    ville.filter((data) => data.ville!=b).map((data)=>(   
                        
                        <option value={data.ville}>{data.ville}</option>
                            
                    ))
                    
                }
                <option>Toutes les annexes</option>
           </select> <br/>
           <br/>
             <h4 className='che'> Recherche personnalisée </h4>
           <div className='person'>
           <input type='date' value={date1} className='oixe' onChange={e => setDate1(e.target.value)}></input> <input type='date' value={date2} className='oixe' onChange={e => setDate2(e.target.value)} ></input> 
           <button type='submit' onClick={handlePropose} className='butonSF6'>Appliquer</button>
           </div>
             </div>
  

           <select name="poste" className='seles' onChange={e => setValue(e.target.value)} >
             
            <option >Enregistré ce mois</option>
            <option >Tous les Enregistrements</option>
            <option>Enregistré cette année</option>
            
            
            

                     
                
           </select>
           <button className='butonSF5' onClick={handleSubmit}>Appliquer</button>
           </div> 
           
                            
           
           </div>
           
           <br/><br/>
           <div className="bilan-container">
      <div className="recettes-section">
      <h4 className='alie'>NOS RECETTES</h4>
                            <br/>

           
        {
                    
                    candidat.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                        <div className="bilan-depense">
                          
                            <span className='texte-depense'>
                            <div> {data.nom} {data.prenom}</div>
                        <span className="montant">{data.montant_payer} FCFA</span><span className='txt'>
                        pour
                        </span>
                         <span className="libelle">Premier versement</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                         </span>

                     
                         
                      </div>
                    ))
                  }


{
                    
                    payement.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                        <div className="bilan-depense">
                          
                            <span className='texte-depense'>
                            <div> {data.nom} {data.prenom}</div>
                        <span className="montant">{data.montant} FCFA</span><span className='txt'>
                        pour
                        </span>
                         <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                         </span>

                     
                         
                      </div>
                    ))
                  }

            
                             <div className='vT'>
                             TOTAL : {totalPaye+totalPaye2} FCFA
                             </div>
     
      </div>
      <div className="depenses-section">
    
       
        
        <h4 className='alie'>MES DEPENSES</h4>
                            <br/>





                            {
                        
                        depenseDir.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                                <span className='texte-depense'>
                            <span className="montant">{data.montant} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                         
            
                             
                          </div>
                        ))
                      }
                           <div className='vT'>
                             TOTAL : {totaDep2} FCFA
                             </div>
        
        <br/>
        {autreDep}       
                            <br/>

                            {
                        
                        depense.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                                <span className='texte-depense'>
                            <span className="montant">{data.montant} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                            
                             
                          </div>
                        ))
                      }
      {totalAutreDep}
                
      </div>
    </div>

             <br/>
                <div className='affich'>
                  <h2 className='gain'>  RESULTAT D'EXERCICE : <h3 className={(totalPaye+totalPaye2)-totalDep>=0 ? 'gain1':'gain2'}>{(totalPaye+totalPaye2)-totalDep} CFA</h3></h2>
                  </div>
              
                  </div>
                  <Footer/>
        </>
    )
                    }

                    else if(largeurEcran>=510){
                      return(
                        <>
                    <div className='wini'>
                     {bar}
                        
                
                        <div className='barContener'>
            
            {/* Barre de recherche */}
            <div className='barChercheT'>
                <input type="text"className='rechBar'  name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e => setQuery(e.target.value)}></input>
            </div>
            <br/>
<select name="poste"   className='rechBar' onChange={e => setAnnexe(e.target.value)} onClick={handleSubmit}>
<option >{b}</option>
     
{  
    ville.filter((data) => data.ville!=b).map((data)=>(   
        
        <option  value={data.ville}  >{data.ville}</option>
            
    ))
    
}
<option>Toutes les annexes</option>
</select> 

            
            {/* Barre de sélection avec bouton Appliquer */}
            <div className='barChoixT'>
                <select name="poste" className='choosee' onChange={e => setValue(e.target.value)}>
                <option >Enregistré ce mois</option>
            <option >Tous les Enregistrements</option>
            <option>Enregistré cette année</option>
                </select>
                {/* Bouton Appliquer pour le select */}
                <button className='butonSF' onClick={handleTout}>Appliquer</button>
            </div>
            
            {/* Barre de recherche personnalisée */}
            <div className='persBarT'>
                
                <input type='date' className='perSF' value={date1} onChange={e => setDate1(e.target.value)}></input>
                <input type='date' className='perSF' value={date2} onChange={e => setDate2(e.target.value)}></input>
                <button type='submit' className='butonSF' onClick={handlePropose}>APPLIQUER</button>
            </div>
            
            </div>
                 

            <br/><br/>
           <div className="bilan-container">
      <div className="recettes-section">
      <h4 className='alie'>NOS RECETTES</h4>
                            <br/>

           
        {
                    
                    candidat.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                        <div className="bilan-depense">
                          
                            <span className='texte-depense'>
                            <div> {data.nom} {data.prenom}</div>
                        <span className="montant">{data.montant_payer} FCFA</span><span className='txt'>
                        pour
                        </span>
                         <span className="libelle">Premier versement</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                         </span>

                     
                         
                      </div>
                    ))
                  }


{
                    
                    payement.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                        <div className="bilan-depense">
                          
                            <span className='texte-depense'>
                            <div> {data.nom} {data.prenom}</div>
                        <span className="montant">{data.montant} FCFA</span><span className='txt'>
                        pour
                        </span>
                         <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                         </span>

                     
                         
                      </div>
                    ))
                  }

            
                             <div className='vT'>
                             TOTAL : {totalPaye+totalPaye2} FCFA
                             </div>
     
      </div>
      <div className="depenses-section">
    
       
        
        <h4 className='alie'>MES DEPENSES</h4>
                            <br/>





                            {
                        
                        depenseDir.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                                <span className='texte-depense'>
                            <span className="montant">{data.montant} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                         
            
                             
                          </div>
                        ))
                      }
                           <div className='vT'>
                             TOTAL : {totaDep2} FCFA
                             </div>
        
        <br/>
        {autreDep}       
                            <br/>

                            {
                        
                        depense.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                                <span className='texte-depense'>
                            <span className="montant">{data.montant} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                            
                             
                          </div>
                        ))
                      }
  {totalAutreDep}
                
      </div>
    </div>


             <br/>
                <div className='affich'>
                  <h2 className='gain'>  RESULTAT D'EXERCICE : <h3 className={(totalPaye+totalPaye2)-totalDep>=0 ? 'gain1':'gain2'}>{(totalPaye+totalPaye2)-totalDep} CFA</h3></h2>
                  </div>


                    </div>
                
                    <Footer/>
                    
                
                    </>
                    )
                    }

                    else{
                      return(
                        <>
                    <div className='wini'>
                     {bar}
                        
                
                        <div className='barContener'>
            
            {/* Barre de recherche */}
       
                <input type="text"className='seLe'  name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e => setQuery(e.target.value)}></input>
         
           <br/> 
<select name="poste"   className='rechBar' onChange={e => setAnnexe(e.target.value)} >
<option >{b}</option>
     
{  
    ville.filter((data) => data.ville!=b).map((data)=>(   
        
        <option  value={data.ville}  >{data.ville}</option>
            
    ))
    
}
<option>Toutes les annexes</option>
</select> 

            
            {/* Barre de sélection avec bouton Appliquer */}
            <div className='barChoixT'>
                <select name="poste" className='choosee' onChange={e => setValue(e.target.value)}>
                <option >Enregistré ce mois</option>
            <option >Tous les Enregistrements</option>
            <option>Enregistré cette année</option>
                </select>
                {/* Bouton Appliquer pour le select */}
                <button className='butonSF' onClick={handleSubmit}>Appliquer</button>
            </div>
            
            {/* Barre de recherche personnalisée */}
            <div className='persBarT'>
                
                <input type='date' className='perSF' value={date1} onChange={e => setDate1(e.target.value)}></input>
                <input type='date' className='perSF' value={date2} onChange={e => setDate2(e.target.value)}></input>
                <button type='submit' className='butonSF' onClick={handlePropose}>APPLIQUER</button>
            </div>
            
            </div>
            {load}
                 
                        <div className=''>
                        <div className=''>
                      
                            <br/>
                            <h4 className='alie'>NOS RECETTES</h4>
                            <br/>

                          
                           
                          
                            {
                    
                    candidat.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                        <div className="bilan-depense">
                          
                            <span className='texte-depense'>
                            <div> {data.nom} {data.prenom}</div>
                        <span className="montant">{data.montant_payer} FCFA</span><span className='txt'>
                        pour
                        </span>
                         <span className="libelle">Premier versement</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                         </span>

                     
                         
                      </div>
                    ))
                  }


{
                    
                    payement.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                        <div className="bilan-depense">
                          
                            <span className='texte-depense'>
                            <div> {data.nom} {data.prenom}</div>
                        <span className="montant">{data.montant} FCFA</span><span className='txt'>
                        pour
                        </span>
                         <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                         </span>

                     
                         
                      </div>
                    ))
                  }

            
                             <div className='vT'>
                             TOTAL : {totalPaye+totalPaye2} FCFA
                             </div>
                        </div>
                        
                    </div>
                
                    <br/>
                            <h4 className='alie'>MES DEPENSES</h4>
                            <br/>





                            {
                        
                        depenseDir.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                                <span className='texte-depense'>
                            <span className="montant">{data.montant} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                         
            
                             
                          </div>
                        ))
                      }
                           <div className='vT'>
                             TOTAL : {totaDep2} FCFA
                             </div>
        
        <br/>
                    {autreDep}       
                            <br/>

                            {
                        
                        depense.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                                <span className='texte-depense'>
                            <span className="montant">{data.montant} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                            
                             
                          </div>
                        ))
                      }
        
       {totalAutreDep}
                
                    <br/>
                
                    <div className='affich'>
                  <h2 className='gain'>  RESULTAT D'EXERCICE : <h3 className={(totalPaye+totalPaye2)-totalDep>=0 ? 'gain1':'gain2'}>{(totalPaye+totalPaye2)-totalDep} CFA</h3></h2>
                  </div>

                  <br/>
                
                    </div>
                
                    <Footer/>
                    
                
                    </>
                    )
                    }
                  }
}

