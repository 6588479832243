import React, {useEffect, useState } from "react"
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "F:/projet/first/src/components/loader";
import moni from 'F:/projet/first/src/components/image/moni.png';
import croix from 'F:/projet/first/src/components/image/croix.png'
import moment from "moment";
import Cookies from 'js-cookie';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import secre from 'F:/projet/first/src/components/image/images.png'
export default function Profil(){
  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
    'credentials': 'include',
  };
         const [query, setQuery]= useState(""); 

         const [titre,setTitre]= useState(Cookies.get('titre'))
         const [vtoken, setVtoken]= useState(Cookies.get('token'))
         const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
         const [dure, setDure]= useState('')
         const [langue, setLangue]= useState('')
         const [le, setLe]= useState('')
         const [name, setName]= useState('')
         const [prenom, setPrenom]= useState('')
         const [username,setUsername ]= useState('')
         const [password,setPassword ]= useState('')
         const [total, setTotal]= useState('')
         const [contact, setContact]= useState('')
         const [sexe, setSexe]= useState('')
         const [adresse, setAdresse]= useState('')
         const [age,setAge]= useState('')
         const [montant, setMontant]= useState('')
         const [statut, setStatut]= useState('')
         const [permis, setPermis]= useState('')
         const [date, setDate]= useState('')
         const [dateRetrait, setDateRetrait]= useState('')
         const [nombre, setNombre]= useState('')
         const [nombre2, setNombre2]= useState('')
         const {id}= useParams();
         const [loginStatut, setLoginStatut]= useState('')
         const navigate = useNavigate();
         const [payement, setPayement]= useState([])
         const [error, setError] = useState(null);
         const [loader, setLoader]= useState(true)
         const [showPassword, setShowPassword] = useState(false);
         const [secretaire, setSecretaire] = useState([])
         const [categorie, setCategorie] = useState('')


         const togglePasswordVisibility = () => {
          setShowPassword(!showPassword);
        };
         
  useEffect(()=>{
      axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/navbar?id='+gestauto+'&titre='+titre+'', { headers })
      .then(res => setSecretaire(res.data))
      .catch(err => console.log(err));
  }, [])
  var effet
  var ob
  if(secretaire.map((data)=>(data.poste))=='secretaire' || secretaire.map((data)=>(data.poste))=='directeur' ){
    effet=<Link to={'/retrait/'+le} className='linko'><button className="retrait">MODIFIER</button> </Link>
    
  }else{effet=''}
 
  if(secretaire.map((data)=>(data.poste))=='directeur'){
    ob=<Link to={'/lesObservationsDir/'+le} className="linko"><button className="retrait">OBSERVATION</button></Link> 
  }else{
    ob=<Link to={'/lesObservations/'+le} className="linko"><button className="retrait">OBSERVATION</button></Link> 
  }
     
         const [candidat, setCandidat] = useState([])
         useEffect(()=>{
             axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/update/'+id, { headers })
             .then(res => { 
                 setLe(res.data[0].id)
                 setName(res.data[0].nom)
                 setPrenom(res.data[0].prenom)
                 setContact(res.data[0].contact)
                 setSexe(res.data[0].sexe)
                 setAdresse(res.data[0].adresse)
                 setAge(res.data[0].age)
                 setMontant(res.data[0].montant_payer)
                 setStatut(res.data[0].statut_payement)
                 setDate(res.data[0].date)
                 setPermis(res.data[0].statut_retrait_permis)
                 setDateRetrait(res.data[0].date_retrait)
                 setCandidat(res.data)
                 setTotal(res.data[0].montant_total)
                 setUsername(res.data[0].user_name)
                 setPassword(res.data[0].password)
                 setDure(res.data[0].dure)
                 setLangue(res.data[0].langue)
                 setCategorie(res.data[0].categorie)
                setLoader(false)
             }
    
                 
                 )
             .catch(err => {
              console.log(err)
              setError(err)
              setLoader(false)
              
            });
     
         
         }, [])
       
     
     const[modal, setModal]= useState(false) 
const toggleModal = ()=> {
    setModal(!modal)
}
 
       
     
     
var permisInfo
var laDate
if(permis=='oui'){
  permisInfo='Retiré'
  laDate=<div className="adro">
  <div >LE</div> <div>{moment(dateRetrait).utc(1).format('DD-MM-YYYY')}</div>
  </div>
}else{
    permisInfo='Non retiré'
}
let cata 
if(categorie){
  cata=<> <div className="adro">
  <div >Categorie</div> <div>Permis {categorie}</div>
  </div><br/></>
}else{
  cata= ''
}


if(loader){
  return(
      <div className="per">
      
      <div className="loader">
      <div className="overlayLo"> 
          <div className="loaderP">
          <Loader/>
          </div>
          </div>
          </div>
          </div>
  )
}else if(error){
  return (<div>Une erreur est survenue : {error.message}</div>)
}else{

    
    return(


        <>
        <div className="pofille">
       <div className="box">
        <br/>
        
       <div className="playProfil"> 
                   <img src={sexe=='Masculin' ? moni : secre} className="imo"  />

               

                   
<Link  onClick={toggleModal} >     <h5 className="osi">  {name} {prenom} </h5></Link>
{modal && (<div className='modal'>
    <div className='overlay'>
        
    </div>
    <div className='modal-content'>
        <div> <h3 className="useName">USERNAME :</h3> {username}<br/>
        <h3 className="useName">PASSWORD :</h3> {password}
        </div>
      <Link onClick={toggleModal}>  <img src={croix} className='close-modal'/></Link>
    </div>
</div>)}
                   </div>
                   
                   <br/>
                  
                   <div className="adro">
                  <div > SEXE</div> <div>{sexe}</div>
                  </div>
                  <br/>
                 
                   <div className="adro">

                 <div className="tes">  ADRESSE</div> <div className="tes">{adresse}</div>
                   </div>
                   <br/>
                   <div className="adro">
                  <div > CONTACT</div> <div>{contact}</div>
                  </div>
                  <br/>
                  
                  <div className="adro">
                  <div > INSCRIT</div> <div>{moment(date).utc(1).format('DD-MM-YYYY')}</div>
                  </div>
                  <div></div>
                  <br/>
                  {cata}
                  
                  <div className="adro">
                  <div > Duré de la formation</div> <div>{dure ? dure.substring(0, 2) : ''} mois</div>
                  </div>
                  <br/>
                  <div className="adro">
                  <div > Langue</div> <div>{langue}</div>
                  </div>
                  <br/>
                  <div className="adro">
                  <div > USERNAME</div> <div>{username}</div>
                  </div>
                  <br/>
                  <div className="adro">
                  <div > PASSWORD</div> <div>{showPassword ? password : '*'.repeat(password.length)}
               <span>   {showPassword ? (
        <FaEyeSlash onClick={togglePasswordVisibility} />
      ) : (
        <FaEye onClick={togglePasswordVisibility} />
      )}</span>
                  </div>
                  </div>
                  <br/>
                  <div className="adro">
                  <div >PERMIS </div> <div>{permisInfo}</div>
                  </div>
                  <br/>
                  {laDate}
                
                  <div className="poBut">
                  {ob}
                
                  {effet}
                  </div>
                   </div>
                   </div>
        </>
    )
}
}