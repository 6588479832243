import Loader from "../components/loader";
import axios from "axios";
import React, {useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
export default function CreateDepense() {
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };
    const [date, setDate]= useState('')
    const [libelle, setLibelle]= useState('')
    const [montant, setMontant]= useState('')
    const navigate = useNavigate();
    const [loader, setLoader]= useState(false);
    const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
    const [info, setInfo]= useState('')
    const [dateControl, setDateControl]= useState('')
    
    const [secretaire, setSecretaire] = useState([])
    useEffect(()=>{
     
        axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/ajout?id='+gestauto+'', { headers})
        .then(res => setSecretaire(res.data))
        .catch(err => console.log(err));
    }, [])

    useEffect(()=>{
        setDate(autre)
    }, [])
     const [id_ecole]= secretaire.map((data)=>(data.id_ecole));
     const [id_annexe]= secretaire.map((data)=>(data.id_annexe)); 
    function handleSubmit(event){
        setInfo('')
        setLoader(true)
        event.preventDefault();

        if( montant=="" || libelle=="" || date==""){
            setLoader(false)
            setInfo("UN CHAMP N'EST PAS REMPLI VEILLEZ VERIFIER!!!")
           
         }else if(date>autre){
            setLoader(false)
            setDateControl('Verifier la date')
        }else if(montant<=0){
            setLoader(false)
              setInfo('VERIFIER LA VALEUR DU MONTANT')
        }
         else{
    
        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/ajout', {date,libelle,montant,id_ecole,id_annexe}, { headers})
        .then(res => {
            setLoader(false)
            navigate('/depense');
        }).catch(err => console.log(err));
    }
    }

    const handleGoBack = () => {
        navigate('/depense');
      };


    var date2= new Date();
      
        var autre= date2.toISOString().split('T')[0]
        
        let load

        if(loader==true){
            load=   <div className="modal">
                <div className="overlay">
    
                </div>
                   <div className="modal-contentis">
        <div className="anous">
        <Loader/>
        </div>
        </div>
            </div>
           
        }else{
            load=''
        }
     
    return(
        
    <div className="auths">
        
         
         <body className="body">

         
         
         <div className="container">
         <FontAwesomeIcon icon={faArrowLeft} onClick={handleGoBack} style={{ color: 'white', fontSize: '28px', cursor: 'pointer' }} />
            <h1 className="form-title">ENREGISTRER DEPENSE</h1>
                 <form action="#" onSubmit={handleSubmit}>
                   <div className="main-user-info">
 

                   <div className="user-input-box">
                   <label htmlFor="montant" className="">Montant</label>
                    <input type="number" name="montant" className="" id="montant" onChange={e => setMontant(e.target.value)}></input>
                    
                   </div>

                   <div className="user-input-box">
                   <label htmlFor="date" className="">DATE</label>
                   <h4 className="text-danger">{dateControl}</h4>
                    <input type="date" value={date} name="date" className=""  id="date" onChange={e => setDate(e.target.value)}  ></input>
                    
                   </div>

                   <div className="libe">
                   <label htmlFor="libelle" className="">Libelle</label>
                    <input type="text" name="libelle" className="" id="libelle" maxLength={100} onChange={e => setLibelle(e.target.value)}></input>
                    
                   </div>
                    
                   </div>
                    
                    <h4 className="text-danger">{info}</h4>
                    <div className="form-submit-btn">
                    <input type="submit" className='' value="ENREGISTRER" ></input>
                    </div>
                 </form>
            </div> 
             <br/><br/><br/>
             </body>
             {load}
    </div>
    )
}