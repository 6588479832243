import React, {useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import Loader from "../components/loader";
import jsPDF from 'jspdf';
import moment from "moment";
import 'moment/locale/fr';
import 'jspdf-autotable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
function Create(){
    const [name, setName]= useState('')
    const [prenom, setPrenom]= useState('')
    const [total, setTotal]= useState('')
    const [contact, setContact]= useState('')
    const [sexe, setSexe]= useState('Masculin')
    const [adresse, setAdresse]= useState('')
    const [age,setAge]= useState('')
    const [montant, setMontant]= useState('')
    const [loader, setLoader]= useState(false);
    const [error, setError] = useState(null);
    const [dure, setDure]= useState('1')
    const [langue, setLangue]= useState('ADJA')
    const [categorie, setCategorie]= useState('A')
    const [date, setDate]= useState('')
    const [info, setInfo]= useState('')
    const [control, setControl]= useState('')
    const [user,setUser]= useState('')
    const [pass,setPass]= useState('')
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };
    const [dateControl, setDateControl]= useState('')
    const [titre,setTitre]= useState(Cookies.get('titre'))
    const [vtoken, setVtoken]= useState(Cookies.get('token'))
    const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
    const navigate = useNavigate();


    const [secretaire, setSecretaire] = useState([])
        const [infoAuto, setInfoAuto] = useState('')
    const [localisation, setLocalisation] = useState('')
    const [logoA, setLogoA]= useState('')
    const [rcm, setRcm] = useState('')
    const [ifu, setIfu] = useState('')
    const [autorisation, setAutorisation] = useState('')
    const [mail, setMail] = useState('')

    const [time, setTime]= useState('')
    useEffect(()=>{
     
        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/createdNEW', {titre,vtoken,gestauto}, { headers})
        .then(res => setSecretaire(res.data))
        .catch(err => console.log(err));
    }, [])
       
    useEffect(()=>{
     
        axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/infoAuto?id='+gestauto+'&titre='+titre+'', { headers })
        .then(res => {
            setInfoAuto(res.data[0].contact)
            setLocalisation(res.data[0].localisation)
           setLogoA(res.data[0].logo)
           setRcm(res.data[0].rcm)
           setIfu(res.data[0].ifu)
           setAutorisation(res.data[0].autorisation)
           setMail(res.data[0].mail)
        })
        .catch(err => console.log(err));
    }, [])
    
    
    
     const [id_ecole]= secretaire.map((data)=>(data.id_ecole));
     const [id_annexe]= secretaire.map((data)=>(data.id_annexe)); 
     var date2= new Date();
       
     const maintenant = new Date();
     const heure = maintenant.getHours();
     const minutes = maintenant.getMinutes().toString().padStart(2, '0');
     const secondes = maintenant.getSeconds().toString().padStart(2, '0');
         
        var years= date2.getFullYear();
         
       var  month= date2.getMonth()+1;
        
        var day= date2.getDate();
        var autre= date2.toISOString().split('T')[0]  
     
        
         useEffect(()=>{
            setDate(autre)
        }, [])
        var username=''
        var password=''

//facture




const loadImageAsBase64 = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };



       // Fonction pour générer un identifiant aléatoire de 5 chiffres
       const generateRandomId = () => {
        let result = '';
        for (let i = 0; i < 5; i++) {
          result += Math.floor(Math.random() * 10); // Génère un chiffre aléatoire entre 0 et 9
        }
        return result;
      };
            // Fonction pour générer un timestamp de 6 caractères
    const generateTimestamp = () => {
        const timestamp = ('000000' + Date.now()).slice(-6); // Obtenir les 6 derniers caractères du timestamp
        return timestamp;
      };
    
    
     // Générer un identifiant aléatoire de 4 caractères
     const randomId = generateRandomId().padEnd(5, '0'); // Remplir avec des zéros si nécessaire
    
    
    
      const generatePaymentReference = () => {
        // Générer un timestamp avec 6 caractères
        const timestamp = generateTimestamp();
      
     
      
        // Concaténer le timestamp et l'identifiant aléatoire de manière aléatoire
      const randomNumber = Math.random();
      const paymentReference = randomNumber > 0.5 ? `${timestamp}${randomId}` : `${randomId}${timestamp}`;
    
     
      
        return paymentReference;
      };
    
      const reference = generatePaymentReference();
    
      
    
      const generatePDF = async(name, prenom, contact, adresse, montant, total, date,referencePaiement,imageUrl,localisation,infoAuto) => {
        // Créer une nouvelle instance de jsPDF
        const doc = new jsPDF();
        const reste = total - montant;
      
        try {
            if (imageUrl) {
              const base64Logo = await loadImageAsBase64(imageUrl); // Utilisation de await pour charger l'image
              doc.addImage(base64Logo, 'PNG', 20, 10, 30, 30);
            }
          } catch (error) {
            console.error("Erreur lors du chargement de l'image :", error);
         
          }

doc.setFontSize(12);
doc.setTextColor(0, 0, 0); // Couleur noire

doc.text(`Auto Ecole ${secretaire.map((data) => data.auto_ecole)}`, 105, 15, null, null, 'center');

if (infoAuto) {
doc.setFontSize(10);
doc.text(`TEL : ${infoAuto}`, 105, 20, null, null, 'center');
}
if (mail) {
    doc.text(`Email : ${mail}`, 105, 25,null,null,'center');

    if (localisation) {
        // Limite de caractères par ligne (ajustez si nécessaire)
        const maxLineWidth = 120; // Largeur maximale pour la ligne, ajustez selon vos marges
    
        // Découpe le texte en plusieurs lignes si nécessaire
        const locationText = ` ${localisation}`;
        const lines = doc.splitTextToSize(locationText, maxLineWidth);
    
        // Dessine chaque ligne sur le PDF
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0); // Couleur noire
        lines.forEach((line, index) => {
            doc.text(line, 105, 30 + (index * 5), null, null, 'center'); // Ajuste l'espacement entre les lignes
        });
    }
 
}else{
    if (localisation) {
        // Limite de caractères par ligne (ajustez si nécessaire)
        const maxLineWidth = 120; // Largeur maximale pour la ligne, ajustez selon vos marges
    
        // Découpe le texte en plusieurs lignes si nécessaire
        const locationText = ` ${localisation}`;
        const lines = doc.splitTextToSize(locationText, maxLineWidth);
    
        // Dessine chaque ligne sur le PDF
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0); // Couleur noire
        lines.forEach((line, index) => {
            doc.text(line, 105, 25 + (index * 5), null, null, 'center'); // Ajuste l'espacement entre les lignes
        });
    }
}

// Ajouter la localisation si disponible







        // Définir la police et la taille du texte
        doc.setFont('helvetica', 'normal');
      
        // Insérer le logo et le nom du système
        doc.setFontSize(20);
        doc.setTextColor(25, 25, 112);
        doc.text('Quittance de Paiement', 105, 48, null, null, 'center');
      
       
      
        // Date
        doc.setFontSize(11);
        doc.text(`Date: ${moment(date).utc(1).format('DD-MM-YYYY')}`, 20, 55);
        doc.text(`Heure: ${heure}:${minutes}:${secondes}`, 20, 60);

     
        if (ifu) {
            doc.setFontSize(9);
            doc.text(`IFU : ${ifu}`, 105, 55);
          
        }
        if (rcm) {
            doc.setFontSize(9);
            doc.text(`N°RCCM : ${rcm}`, 105, 60);
          
        }

        if (autorisation) {
            doc.setFontSize(9);
            doc.text(`Référence de l’autorisation : ${autorisation}`, 105, 65);
           
        }
        // Ligne de séparation
        doc.setLineWidth(0.5);
        doc.line(20, 68, 190, 68);
      
        // Nom et prénom du payeur et référence de paiement
        doc.setFontSize(11);
  
        doc.setFont('helvetica', 'bold');

doc.text(`${prenom} ${name}`, 31, 75);



doc.setFontSize(11);
doc.setTextColor(25, 25, 112);
doc.setFont('helvetica', 'normal');
doc.text(`Catégorie: Permis ${categorie}`, 20, 80);
        doc.text(`Langue de Formation: ${langue}`, 20, 85);
        doc.text(`Durée de Formation: ${dure} mois`, 20, 90)
        doc.text(`Référence de Paiement: ${referencePaiement}`, 105, 80);
      
        // Tableau des informations
        const data = [
         
          ['Frais de Formation', `${total} FCFA`],
          ['Montant payé', `${montant} FCFA`],
   
          ['Reste à payer', `${reste} FCFA`]
        ];
      
        // Utiliser autoTable pour générer le tableau
        doc.autoTable({ startY: 100,head: [['Désignation', 'Valeur']], body: data });
      // Calcul du montant restant
      doc.setFontSize(11);
doc.text(`Arrêté le present reçu à la somme de: ${montant} FCFA`, 20, doc.autoTable.previous.finalY + 10);

// Ligne de signature
doc.setFontSize(11);
doc.text('Signature:', 20, doc.autoTable.previous.finalY + 30);
        // Sauvegarder le PDF
        doc.save(`${prenom}_${name}_quittance.pdf`);
      };

// fin facture
const[modal, setModal]= useState(false) 
const toggleModal = ()=> {
    setModal(!modal)
}



        function genererCredentiels() {
           

           
            username = prenom.toLowerCase() + '.' + name.toLowerCase();

            
             password = Math.random().toString(36).substring(2, 10);

             if(username && password){
                setUser(username)
                setPass(password)
             }
       

       
        }

        useEffect(()=>{
            setTime(`${heure}:${minutes}:${secondes}`);
        }, [heure, minutes, secondes])
        



    function handleSubmit(event){
        const referencePaiement = generatePaymentReference();
        setControl('')
            setInfo('')
            setLoader(true)
        event.preventDefault();
        if(name=="" || prenom=="" || contact=="" || sexe=="" || montant==""  || adresse=="" || total==""  ){
           setLoader(false)
            setInfo("UN CHAMP N'EST PAS REMPLI VEILLEZ VERIFIER!!!")
           
            
         }
         else  if(contact.length<8){
            setLoader(false)
            setControl('CONTACT INCORECTE')
        }else if(date>autre){
            setLoader(false)
                setDateControl('Verifier la date')
            }
            else if(montant<=0 || total<=0 || montant-total>0){
                setLoader(false)
               setInfo('VEILLEZ VERIFIE LA VALEUR DU MONTANT')
            }
         else{

            genererCredentiels()
            if(username!='' && password!=''){
                const logoUrl = logoA ? `https://gestauto-56bfcbe4b876.herokuapp.com/get-image?logo=${logoA}` : null;



            if(montant== total){

                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/create20', {name,prenom,contact,sexe, adresse,montant,date,id_ecole,total,id_annexe,username,password,dure,langue,referencePaiement,categorie,time}, { headers})
                .then(res => {
                    generatePDF(name, prenom, contact, adresse, montant, total, date,referencePaiement,logoUrl,localisation,infoAuto);
                    setLoader(false)
                    toggleModal()
                }).catch(err =>{
                     console.log(err)
                     setError(err)
                     setLoader(false)
                 } );
            }else{
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/create', {name,prenom,contact,sexe, adresse,montant,date,id_ecole,total,id_annexe,username,password,dure,langue,referencePaiement,categorie,time}, { headers})
                .then(res => {
                    generatePDF(name, prenom, contact, adresse, montant, total, date,referencePaiement,logoUrl,localisation,infoAuto);
                    setLoader(false)
                    toggleModal()
                }).catch(err =>{
                     console.log(err)
                     setError(err)
                     setLoader(false)
                    });
            }
          
        }
    }

    
    }
    

    const handleGoBack = () => {
        navigate('/candidat');
      };
        
        var vev = <h3 className="text-danger2">  Je suis certain que les informations saisis sont correctes  <input type="checkbox" className="chk"></input> </h3>

  
        let load

        if(loader==true){
            load=   <div className="modal">
                <div className="overlay">
    
                </div>
                   <div className="modal-contentis">
        <div className="anous">
        <Loader/>
        </div>
        </div>
            </div>
           
        }else{
            load=''
        }

        if(error){
            return (<div>Une erreur est survenue : {error.message}</div>)
        }else{
    return(
        <div className="authes">
            <br/> <br/> <br/>
            <body className="body">
            
            <div className="container"> 
            <FontAwesomeIcon icon={faArrowLeft} onClick={handleGoBack} style={{ color: 'white', fontSize: '28px', cursor: 'pointer' }} />
            <h1 className="form-title">ENREGISTREMENT</h1>
                                 
                 <form action="#" onSubmit={handleSubmit}>
                 
                   <div className="hai">
                    <div className="user-input-box">
                 

                    <label htmlFor="fullName" className="">Nom</label>
                    
                    <input type="text" id="fullName" name="fullName" className=""  onChange={e => setName(e.target.value)}  ></input>
                    </div>
                    <div className="user-input-box">
                    <label htmlFor="username" className="">Prenom</label>
                    <input type="text"  className="" name="username" id="username" onChange={e => setPrenom(e.target.value)}></input>
                    </div>
                    
                    <div className="user-input-box">
                    <label htmlFor="contact" className="">Contact</label>
                    <h4 className="text-danger">{control}</h4>
                    <input type="number" name="contact" id="contact" className="" onChange={e => setContact(e.target.value)}></input>
                    </div>
                   
                    <div className="user-input-box">
                    <label htmlFor="adresse" className="">Adresse</label>
                    <input type="text" name="adresse" className="" id="adresse" onChange={e => setAdresse(e.target.value)}></input>
                    
                    </div>
                    
                    <div className="user-input-box">
                    <label htmlFor="montantPayer" className="">Montant Payé</label>
                    <input type="number" name="montantPayer" className="" id="montantPayer" onChange={e => setMontant(e.target.value)}></input>
                    
                    </div>
                    <div className="user-input-box">
                    <label htmlFor="fraisDeFormation" className="">Montant Total</label>
                    <input type="number" name="fraisDeFormation" className="" id="fraisDeFormation" onChange={e => setTotal(e.target.value)}></input>
                    
                    </div>
                    
                     
                     <div className="user-input-box">
                     <label htmlFor="date" className="">Date</label>
                     <h4 className="text-danger">{dateControl}</h4>
                    <input type="date" name="date" value={date} id="date" className="" onChange={e => setDate(e.target.value)}></input>
                     </div>
                     <div className="user-input-box">
                     <label htmlFor="langue" className="">Langue</label>
                     <select name="langue" className='' onChange={e => setLangue(e.target.value)} >
                     <option value={'ADJA'}>ADJA</option>
                     <option value={'BARIBA'}>BARIBA</option>
                     <option value={'DINDI'}>DINDI</option> 
                     <option value={'FON'}>FON</option>
                     <option value={'Français'}>Français</option>
                     <option value={'GOUN'}>GOUN</option>
                     <option value={'MINA'}>MINA</option>
                     <option value={'YORUBA'}>YORUBA</option>
         
                            
            </select>
                     </div>

                    


                     <div className="user-input-box">
                     <label htmlFor="langue" className="">Durée de la formation</label>
                     <select name="langue" className='' onChange={e => setDure(e.target.value)} >
                     <option value={'1'}>1 Mois</option>
                     <option value={'2'}>2 Mois</option>
                     <option value={'3'}>3 Mois</option> 
                     <option value={'4'}>4 Mois</option>
                     <option value={'5'}>5 Mois</option>
                     <option value={'6'}>6 Mois</option>
                     <option value={'12'}>12 Mois</option>
                   
         
                            
            </select>
                     </div>

                    

                     <div className="user-input-box">
                     <label htmlFor="sexe" className="">Sexe</label>
                     <select name="sexe" className='' onChange={e => setSexe(e.target.value)} >
             
             <option value={'Masculin'}>Masculin</option>
             <option value={'Feminin'}>Feminin</option>
                              
            </select>
                     </div>
                    
                     </div>



                     <div className="look"> 
                     <div className="lookTZ"> Catégorie</div>
                     <div className="lookFZ">
                    
                     <select name="categorie" className='' onChange={e => setCategorie(e.target.value)} >
             
                     <option value={'A'}>Permis A</option>
             <option value={'A1'}>Permis A1</option>
             <option value={'A1+B'}>Permis A1+B</option>
             <option value={'A1+C+C1'}>Permis A1+C+C1</option>
             <option value={'A3'}>Permis A3</option>
             <option value={'A3+A2'}>Permis A3+A2</option>
             <option value={'A1+A2+A3'}>Permis A1+A2+A3</option>
             <option value={'B'}>Permis B</option>
             <option value={'C'}>Permis C</option>
             <option value={'C1'}>Permis C1</option>
             <option value={'DR'}>Permis DR</option>
             <option value={'D'}>Permis D</option>
             <option value={'E'}>Permis E</option>
             <option value={'F'}>Permis F</option>
                              
            </select>
           
                    </div>
                     </div>

                     <h4 className="text-danger">{info}</h4>
                    
                    <div class="form-submit-btn">
                    <input type="submit" className='' value="ENREGISTRER" ></input>
                    
                    </div>
                    
                   
                    
                 </form>
                 
            </div> 
                {modal && (<div className='modal'>
    <div className='overlay'>
        
    </div>
    <div className='modal-content3'>
        <div> <h4 className="useName">USERNAME :</h4>  {user}
        <h4 className="useName">PASSWORD :</h4>   {pass}
        </div>
        <Link className='bnfs' to={'/candidat'}> <button className="btn-create">D'accord</button>  </Link>
    </div>
</div>)}
            </body>
            {load}
        </div>
    )
}  
}

export default Create;