import React,{ useEffect, useState} from 'react';
import axios from 'axios';
import Navbar from 'F:/projet/first/src/components/navbar';
import moni from 'F:/projet/first/src/components/image/moni.png';
import secre from 'F:/projet/first/src/components/image/images.png'
import Cookies from 'js-cookie';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Loader from "../components/loader";
import Suppr from './supp.png'
import Footer from '../components/footer';
export default function VoirCond(){
    const [candidat, setCandidat] = useState([])
    const [presence,setPresence]= useState([])
    const [id_cand, setId_cand]= useState([])
    const [largeurEcran, setLargeurEcran] = useState(window.innerWidth);
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };
    
    const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
   
    const [mie, setMie]= useState(Cookies.get('mie'))

    const [error, setError] = useState(null);
    const [loader, setLoader]= useState(true)
    const [viewLoader, setViewLoader] = useState(false)


    useEffect(()=>{
        axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/cond?id='+mie+'', { headers})
        .then(res =>{
             setCandidat(res.data)
             
            })
        .catch(err =>{
             console.log(err)
             
            });
    }, [])
          
    const mettreAJourLargeurEcran = () => {
        setLargeurEcran(window.innerWidth);
    
      };
    
       
       
        
        
        useEffect(() => {      
            window.addEventListener('resize', mettreAJourLargeurEcran);      
            return () => {
              window.removeEventListener('resize', mettreAJourLargeurEcran);        
            };      
          }, []);
    const [secretaire, setSecretaire] = useState([])
    useEffect(()=>{
        
       
        axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/moniInfo?id='+gestauto+'', { headers})
        .then(res => setSecretaire(res.data))
        .catch(err => console.log(err));
    }, [])
       
    
    
     const [id_ecole]= secretaire.map((data)=>(data.id_ecole));
    


     const [query, setQuery]= useState(""); 
      
         let exp

         var date= new Date();
         const now= date.toLocaleDateString();
        const years= date.getFullYear()
       const  month= date.getMonth()+1
        const day= date.getDate()
      

            
        
         useEffect(()=>{
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/voirCond', {years,month,day,mie}, { headers})
            .then(res => {
               setPresence(res.data)
       
               setLoader(false)
                
            }).catch(err =>{ 
                console.log(err)
                setError(err)
             setLoader(false)
            });
        }, [])          
            
            
            useEffect(()=>{
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/date', {years,month,day,mie}, { headers})
                .then(res => {
                   setId_cand(res.data)
                
                    
                    
                }).catch(err => console.log(err));
            }, [])

            const handleDelete = async(id) => {  
                try {
                 await axios.delete('https://gestauto-56bfcbe4b876.herokuapp.com/absent/'+id, { headers})
                 window.location.reload()
                }catch(err) {
                 console.log(err);
                }
              }

              const[valid, setValid]= useState('')
              
              const[valid2, setValid2]= useState('')
              const[supMod, setSupMod]= useState(false) 

              const toggleSup = ()=> {
                setValid2(valid)
               
                setSupMod(!supMod)
                setValid('')
            }


            if(valid!= ''){
               toggleSup()
               
              
              
              } 

              let load

              if(viewLoader==true){
                  load=  <div className="avt">
                  <div className="rin">
                    <div className="chm">
                      <Loader/>
                    </div>
                  </div>
                </div>
              }else{
                  load=''
              }
  
  
              if(loader){
                  return(
                      <div className="per">
                      
                      <div className="loader">
                      <div className="overlayLo"> 
                          <div className="loaderP">
                          <Loader/>
                          </div>
                          </div>
                          </div>
                          </div>
                  )
              }else if(error){
                  return (<div>Une erreur est survenue : {error.message}</div>)
              }else{


                if(largeurEcran>= 510){  
            return(
                <>
                
                <div className='wini'>
                <Navbar/>
                <h1 className='win'>Les presents du cour de conduite du {now}</h1><br/><br/>
                
                <div className="search">
                     <input type="text" className="recherche" name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e=>setQuery(e.target.value)}></input>
                </div>
                <div className="searche_result">
                    
                   
                </div>
        
                <br/><br/>
            <div className=''>
                <div className=''>
                    
                <div className="card-list"  >
                                 {
                                            presence.filter((data) => data.nom.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query)).map((data, i)=> (
                                                <div className="card" >
                                                 <img src={data.sexe=='Masculin' ? moni : secre}  alt="Candidat 1" />
                                             <div className='info'>
                                                <p className='name'> {data.nom} {data.prenom}</p>
                                             
                                                <p className='contacts'> {data.contact}</p>
                                               
                                                <i className="fa-solid fa-trash-can modaFF" onClick={e => setValid(data.id)}></i>
                                                
                                                {supMod && (<div className='modalSup'>
    <div className='overlaySup'>
        
    </div>
    <div className='modal-contentSup'>
        <h2>Voulez-vous vraiment supprimer ?</h2>
     
      <div className='supD'>
      <Link onClick={e => handleDelete(valid2)}> <button className='supC' >Confirmer</button> </Link>
      <Link onClick={toggleSup}><button className='supA' > Annuler </button></Link>
      </div>
     
      
    </div>
   
</div>)}
                                                </div>
                                            </div>
                                        
                                            ))
                                          } 
                                 </div>
                </div>
                
                <Link to={'/ajoutPresence'} > <button className='ess'>Ajouter à la liste</button></Link>
            </div>
            <br/>
            </div>
            <Footer/>
            </>
            )
                   }else{
                    return(
                        <>
                        
                        <div className='wini'>
                        <Navbar/>
                        <h1 className='win'>Les presents du cour de conduite du {now}</h1><br/><br/>
                        
                        <div className="search">
                             <input type="text" className="recherche" name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e=>setQuery(e.target.value)}></input>
                        </div>
                        <div className="searche_result">
                            
                           
                        </div>
                
                        <br/><br/>
                    <div className=''>
                    <div className="card-list"  >
                                 {
                                            presence.filter((data) => data.nom.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query)).map((data, i)=> (
                                                <div className="card" >
                                                 <img src={data.sexe=='Masculin' ? moni : secre}  alt="Candidat 1" />
                                             <div className='info'>
                                                <p className='name'> {data.nom} {data.prenom}</p>
                                             
                                                <p className='contacts'> {data.contact}</p>
                                               
                                                <i className="fa-solid fa-trash-can modaFF" onClick={e => setValid(data.id)}></i>
                                                
                                                {supMod && (<div className='modalSup'>
    <div className='overlaySup'>
        
    </div>
    <div className='modal-contentSup'>
        <h2>Voulez-vous vraiment supprimer ?</h2>
     
      <div className='supD'>
      <Link onClick={e => handleDelete(valid2)}> <button className='supC' >Confirmer</button> </Link>
      <Link onClick={toggleSup}><button className='supA' > Annuler </button></Link>
      </div>
     
      
    </div>
   
</div>)}
                                                </div>
                                            </div>
                                        
                                            ))
                                          } 
                                 </div>
                        
                        <Link to={'/ajoutPresence'} > <button className='ess'>Ajouter à la liste</button></Link>
                    </div>
                    <br/>
                    </div>
                    <Footer/>
                    </>
                    )
                   }
                
                
                }
                            }