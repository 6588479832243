import React,{ useEffect, useState} from 'react';
import axios from 'axios';

import moni from 'F:/projet/first/src/components/image/moni.png';
import log from 'F:/projet/first/src/components/image/log.png';
import Loader from "../components/loader";
import Footer from '../components/footer';
import { Link , useParams,NavLink, useNavigate,  useLocation} from "react-router-dom";
import secre from 'F:/projet/first/src/components/image/images.png'
import Cookies from 'js-cookie';
import { sidedataDir } from 'F:/projet/first/src/components/sidedataDir'
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { IconContext } from "react-icons";
import logo from 'F:/projet/first/src/components/image/logo4.jpg'
import { FaPhoneAlt } from 'react-icons/fa';
export default function CandidatDir(){
    const [titre,setTitre]= useState(Cookies.get('titre'))  
    const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
    const [loader, setLoader]= useState(true)
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };
    const [sidebar, setSidebar]= useState(false)
    const showSidebar = () => setSidebar(!sidebar)
    const [lu,setLu] = useState(Cookies.get('token'))
    const [tok, setTok]= useState('')
    const [error, setError] = useState(null);

    const [viewLoader, setViewLoader] = useState(false)
    const [ce,setCe]= useState(Cookies.get('autoa'))

    const [select1,setSelect1]= useState(Cookies.get('select1'))
    const [select2,setSelect2]= useState(Cookies.get('select2'))
    const [old, setOld]= useState('')
    const [recup, setRecup]= useState('')
    const [new1, setNew1]= useState('')
    const [new2, setNew2]= useState('')
    const [info1, setInfo1]= useState('')
    const [info2, setInfo2]= useState('')
    const [largeurEcran, setLargeurEcran] = useState(window.innerWidth);
    const mettreAJourLargeurEcran = () => {
        setLargeurEcran(window.innerWidth);
    
      };

      if(tok!= ''){
        if(tok!= lu){
            Cookies.remove('token')
            Cookies.remove('autoa')
            Cookies.remove('titre')
            Cookies.remove('gestauto')
            window.location.reload();
            navigate('/')

        }
    }

    var navigate= useNavigate()
    useEffect(()=>{
         if(!Cookies.get('token')){
            navigate('/')
         }
    }, [])


    
    
    const logout = () =>{
        Cookies.remove('token')
        Cookies.remove('autoa')
        Cookies.remove('titre')
        Cookies.remove('gestauto')
        Cookies.remove('default')
        Cookies.remove('default2')
        Cookies.remove('defaultCode')
        Cookies.remove('defaultCode2')
        Cookies.remove('select1')
        Cookies.remove('select2')
        window.location.reload();
       navigate('/')
    }
    useEffect(()=>{
        if(!Cookies.get('token')){
           navigate('/')
        }
   }, [])





useEffect(() => {
        
    window.addEventListener('resize', mettreAJourLargeurEcran);

   
    return () => {
      window.removeEventListener('resize', mettreAJourLargeurEcran);
      
    };
   
  }, []);
// Recuperer token
useEffect(()=>{
    

axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/setToken', { gestauto, titre},{ headers})
    .then(res => 
        {setTok(res.data[0].token)
        

        })
    .catch(err => console.log(err));
}, [])
//nav en haut

    const[profil, setProfil]= useState(false)

    const showProfil = () => setProfil(!profil)

    const [secretaire, setSecretaire] = useState([])
    useEffect(()=>{
        axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/navbar?id='+gestauto+'&titre='+titre+'', { headers})
        .then(res => setSecretaire(res.data))
        .catch(err => console.log(err));
    }, [])

   





    const [annexe, setAnnexe]= useState('')
    const [lea,setLea]=useState([0])
    var date= new Date();
    const now= date.toLocaleDateString();
   const years= date.getFullYear()
  var  month=  date.getMonth()+1
  var month2= date.getMonth()+1
  var day2= date.getDate()
  const days= date.getDate()
  var datee= new Date(years,month-2,days)
  var autre2= datee.toISOString().split('T')[0] 
  var autre= date.toISOString().split('T')[0]

    const[value,setValue]= useState('')
    const [idAnnexe,setIdAnnexe]= useState('')
    const[controleAnnexe,setControleAnnexe]= useState('')
    const [date1, setDate1]= useState('')
    const [date2, setDate2]= useState('')
    const [loga, setLoga]= useState('')
    
    const [logaId, setLogaID]= useState(0)
    const [valeurLogaID, setValeurLogaID]= useState('')
   
   
    const [ville, setVille] = useState([])
    useEffect(()=>{
        axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/candidatDir?id='+ce+'' , { headers})
        .then(res =>{ setVille(res.data)
        setLoga(res.data[0].ville)
        setLogaID(res.data[0].id_annexe)
        setValeurLogaID(res.data[0].id_ecole)
        setAnnexe(res.data[0].ville)
        })
        .catch(err => console.log(err));
    }, [])

    const [id_annexe]= ville.map((data)=>(data.id_annexe));
    
     const [query, setQuery]= useState(""); 
  

         useEffect(()=>{
       
           
                setDate1(autre2)
                setDate2(autre)
          
        }, [])
        

         
         const [candidat, setCandidat] = useState([])

  const handleDelete = async(id) => {
        try {
         await axios.delete('https://gestauto-56bfcbe4b876.herokuapp.com/depense/'+id, { headers})
         window.location.reload()
        }catch(err) {
         console.log(err);
        }
      }

         function handleSubmit(event){
            setViewLoader(true)
            event.preventDefault();
            
            const [id_ecole]= ville.map((data)=>(data.id_ecole));

            
           
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/candidatDir', {annexe,id_annexe,id_ecole}, { headers})
            .then(res => {
                
           
                const a= res.data[0].id_ecole
              
                setLea(res.data)
           
                setControleAnnexe(a);
                /*axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/get' )
                .then(res => setCandidat(res.data))*/   
                if(value==''){                 
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/info', {a,month,years}, { headers})
                .then(res =>{ 
                    
                    setCandidat(res.data)   
                    setViewLoader(false)          
                }
                   

                ).catch(err =>{
                     console.log(err)
                    setError(err)
                    setViewLoader(false)
                    
                    });
                
            }else if(value=='Enregistré cette année'){
            
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/toutInit',{years,a}, { headers})
                .then(res => {
                    setCandidat(res.data)
                    setViewLoader(false)
                })
                .catch(err =>{
                     console.log(err)
                     setError(err)
                    setViewLoader(false)
                    
                    });
                  if(o==0){
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defaInfoThisYearsInit',{years,a}, { headers})
                .then(res =>{ setCandidat(res.data)
                setViewLoader(false)
                })
                .catch(err =>{
                     console.log(err)
                     setError(err)
                    setViewLoader(false)
                    });
            }
            }else if(value=='Enregistré ce mois'){
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/moisInit',{month,years,a}, { headers})
                .then(res =>{
                     setCandidat(res.data)
                    setViewLoader(false)
                    
                    })
                .catch(err =>{
                    console.log(err)
                    setError(err)
                   setViewLoader(false)
                   });
                if(o==0){
                    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defaInfoThisInit',{month,years,a}, { headers})
                    .then(res => {
                        setCandidat(res.data)
                        setViewLoader(false)
                    })
                    .catch(err =>{
                        console.log(err)
                        setError(err)
                       setViewLoader(false)
                       });
                }
                }else if(value=='Tous les Candidats'){
    
                    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/candInit',{a}, { headers})
                    .then(res => {
                        setCandidat(res.data)
                        setViewLoader(false)
                    })
                    .catch(err =>{
                        console.log(err)
                        setError(err)
                       setViewLoader(false)
                       });
                    if(o==0){
                        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defaInfoInit',{a}, { headers})
                    .then(res => {
                        setCandidat(res.data)
                        setViewLoader(false)
                    })
                    .catch(err =>{
                        console.log(err)
                        setError(err)
                       setViewLoader(false)
                       });
                    }
                }
            else if(value=='Qui ont retiré leurs permis'){
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/permisInit',{a}, { headers})
                .then(res => {
                    setCandidat(res.data)
                    setViewLoader(false)
                })
                .catch(err =>{
                    console.log(err)
                    setError(err)
                   setViewLoader(false)
                   });
                if(o==0){
                    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defaInfoPermisInit',{a}, { headers})
                .then(res => {
                    setCandidat(res.data)
                    setViewLoader(false)
                })
                .catch(err =>{
                    console.log(err)
                    setError(err)
                   setViewLoader(false)
                   });
                }
            }
// pour le seelect des options



  
//fin
            
               
        
                
                
                    
                
            
            
            }).catch(err =>{
                console.log(err)
               
               
               });

                

            
            
        
        }
        var cont=0
        
        
        const  le = lea.map((data)=>(data.id_ecole))

        
           
    
        var o=le
        const {id}= useParams();
        if (o!=0){
            o=le
        }else{
            
            o=id
        }

if(value==''){
    if(select1=== undefined || select2===undefined){
        if(logaId!='' && o==0){
            
        
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defa', {loga,logaId}, { headers})
            .then(res => {
                
       
                const a= res.data[0].id_ecole
              
                
                
                setControleAnnexe(a);
                setLogaID('')
                /*axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/get' )
                .then(res => setCandidat(res.data))*/                    
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/info', {a,month,years}, { headers})
                .then(res =>{ 
                   
                    setCandidat(res.data) 
                    setLoader(false)            
                }
                   
    
                ).catch(err =>{
                     console.log(err)
                    setError(err)
                    setLoader(false)
                    
                    });
                
                      
            
            }).catch(err =>{ console.log(err)
                setError(err)
                setLoader(false)
            });
            
    
        
        }
    }else if(select1 || select2){
        if(logaId!='' && o==0){
            
        
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defa', {loga,logaId}, { headers})
            .then(res => {
                
       
                const o= res.data[0].id_ecole
              
                
                
                setControleAnnexe(o);
                setLogaID('')
                /*axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/get' )
                .then(res => setCandidat(res.data))*/                    
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/vVoir', {o,select1,select2}, { headers})
                .then(res =>{ 
                   
                    setCandidat(res.data) 
                    setLoader(false)            
                }
                    
                ).catch(err =>{
                     console.log(err)
                    setError(err)
                    setLoader(false)
                    
                    });

            }).catch(err =>{ console.log(err)
                setError(err)
                setLoader(false)
            });
            
    
        
        }
    }
    }



        useEffect(()=>{
            if(o!=0){
        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/idAnnexe', {o}, { headers})
        .then(res =>{ 
            
            setIdAnnexe(res.data[0].ville)             
        }
           

        ).catch(err => console.log(err));
    }
    }, [])
        var b
       
     
      if(idAnnexe!=''){
        b= idAnnexe
      }  else{
        b= loga
      }
     
        
         
        useEffect(()=>{
            if( select1 === undefined || select2=== undefined){
            
            if(value==''){
            if(o!=0){
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/tic', {o,month,years,le}, { headers})
            .then(res =>{ 
                console.log('ah paapa')
                setCandidat(res.data)  
                setLoader(false)           
            }
               

            ).catch(err =>{
                 console.log(err)
                setError(err)
                setLoader((false))
                });
        }
    }
    }else if(select1 || select2) {
        setDate1(select1)
        setDate2(select2)
        if(value==''){
         
     
       if(o!=0){
        console.log('ah maamaa')
       axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/vVoir', {select1,select2,o}, { headers})
       .then(res => {
          setCandidat(res.data)
         setLoader(false)
           
           
       }).catch(err =>{
           console.log(err)
           setError(err)
          setLoader(false)
          });
        }
      
    }
    }
        }, [])

           


            
        function handleTout(event){
            setViewLoader(true)
            event.preventDefault();
            if(value=='Enregistré ce mois'){
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/candidatInfoThis',{month,years,o,le}, { headers})
                .then(res => {
                    setCandidat(res.data)
                    setViewLoader(false)
                })
                .catch(err =>{
                    console.log(err)
                    setError(err)
                   setViewLoader(false)
                   });
                if(o==0){
                    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defaInfoThis',{month,years,valeurLogaID}, { headers})
                    .then(res => {
                        setCandidat(res.data)
                        setViewLoader(false)
                    
                    })
                    .catch(err =>{
                        console.log(err)
                        setError(err)
                       setViewLoader(false)
                       });
                }
                }
            else if(value=='Enregistré cette année'){
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/candidatInfoThisYears',{years,o,le}, { headers})
                .then(res =>{
                     setCandidat(res.data)
                     setViewLoader(false)
                    })
                .catch(err =>{
                    console.log(err)
                    setError(err)
                   setViewLoader(false)
                   });
                  if(o==0){
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defaInfoThisYears',{years,valeurLogaID}, { headers})
                .then(res => {
                    setCandidat(res.data)
                    setViewLoader(false)
                })
                .catch(err =>{
                    console.log(err)
                    setError(err)
                   setViewLoader(false)
                   });
            }
            }else if(value=='Tous les Candidats'){
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/candInfo',{o,le}, { headers})
                .then(res => {
                    setCandidat(res.data)
                    setViewLoader(false)
                })
                .catch(err =>{
                    console.log(err)
                    setError(err)
                   setViewLoader(false)
                   });
                if(o==0){
                    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defaInfo',{valeurLogaID}, { headers})
                .then(res => {
                    setCandidat(res.data)
                    setViewLoader(false)
                })
                .catch(err =>{
                    console.log(err)
                    setError(err)
                   setViewLoader(false)
                   });
                }
            }else if(value=='Qui ont retiré leurs permis'){
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/candidatInfoPermis',{o,le}, { headers})
                .then(res => {
                    setCandidat(res.data)
                    setViewLoader(false)
                })
                .catch(err =>{
                    console.log(err)
                    setError(err)
                   setViewLoader(false)
                   });
                if(o==0){
                    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defaInfoPermis',{valeurLogaID}, { headers})
                .then(res => {
                    setCandidat(res.data)
                    setViewLoader(false)
                })
                .catch(err =>{
                    console.log(err)
                    setError(err)
                   setViewLoader(false)
                   });
                }
            }
           
        }


        function handlePropose(event){
            Cookies.set('select1',date1)
            Cookies.set('select2',date2)
            setViewLoader(true)
            event.preventDefault();
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/personCandidatDir', {date1,date2,o,le}, { headers})
            .then(res => {
               setCandidat(res.data)
              setViewLoader(false)
                
                
            }).catch(err =>{
                console.log(err)
                setError(err)
               setViewLoader(false)
               });
            if(o==0){
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defaFiltre', {date1,date2,valeurLogaID}, { headers})
            .then(res => {
               setCandidat(res.data)
               setViewLoader(false)
                          
            }).catch(err =>{
                console.log(err)
                setError(err)
               setViewLoader(false)
               });
            }
        }
         var ecrit
   
//ici nav var





//modification password



const[valid, setValid]= useState('')
              
const[valid2, setValid2]= useState('')
const[supMod, setSupMod]= useState(false) 

const toggleSup = ()=> {
  
 
  setSupMod(!supMod)
  setValid('')
 
}


if(valid!= ''){
 toggleSup()
 


} 



              

const[supMod2, setSupMod2]= useState(false) 

const toggleSup2 = ()=> {
  
 
  setSupMod2(!supMod2)
  setValid2('')
  
}


if(valid2!= ''){
 toggleSup2()
 


} 




function handleModf(event){
    const id= gestauto
    event.preventDefault();
    const currentPassword = secretaire.map((data) => data.password)[0];
    
    if(currentPassword===old){
    
        if(new1===new2){
            setInfo2('')
            axios.put('https://gestauto-56bfcbe4b876.herokuapp.com/upPassword/'+id, {new1,titre}, { headers})
            .then(res => {
                    
        axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/navbar?id='+gestauto+'&titre='+titre+'', { headers })
        .then(res => setSecretaire(res.data))
        .catch(err => console.log(err));
                toggleSup()

            setValid2('on')
            }).catch(err => console.log(err));
        }else{
           
            setInfo2('Incorrecte')
            setTimeout(() => setInfo2(''), 3000)
        }
    }else{
        setInfo1('Incorrecte')
        setTimeout(() => setInfo1(''), 3000)
    }
}



const [menuVisible, setMenuVisible] = useState(false);

const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  
};






useEffect(() => {
    const handleClickOutside = (event) => {
        if (menuVisible && !document.querySelector('.carD').contains(event.target)) {
            setMenuVisible(false);
        }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, [menuVisible]);

var bar
const location = useLocation(); 
if(largeurEcran>= 1000){
    bar=         <div className="barHaut">
         <div className="lep">
                    
                    
                    <img src={moni} className="user" onClick={showProfil} />
                    {supMod && (<div className='modalSup'>
    <div className='overlay'>
        
    </div>
    <div className='modal-contentSupInfo'>
       <div className="containerModal"> 
        <h3 className="form-title2">MODIFIER MOT DE PASSE</h3>
     
      <div className='supD'>
      <input type="text"  name="prenom" className="inputCmot1"  id="prenom"  value={secretaire.map((data)=>(data.nom))}  ></input>
     <input type="text"  name="prenom" className="inputCmot1"  id="prenom"  value={secretaire.map((data)=>(data.prenom))}  ></input>
      
     
      </div>
      <label htmlFor="libelle" className="">Ancien mot de pass</label> <br/>
      <input type="pasword"  name="prenom" className="inputCmot"  id="prenom"   onChange={e => setOld(e.target.value)}   ></input>
      <br/>
      <h4 className="text-danger">{info1}</h4>
      <br/>
      <label htmlFor="libelle" className="">Nouveau mot de pass</label> <br/>
      <input type="pasword"  name="prenom" className="inputCmot"  id="prenom" onChange={e => setNew1(e.target.value)}   ></input>
      <br/>
      <label htmlFor="libelle" className="">Confirmer mot de pass</label> <br/>
      <input type="pasword"  name="prenom" className="inputCmot"  id="prenom"   onChange={e => setNew2(e.target.value)} ></input>
      <br/>
      <h4 className="text-danger">{info2}</h4>
      <br/>
      <div className="supD">
      <Link onClick={handleModf}><button className='nm1' > Modifier </button></Link>
      <Link onClick={toggleSup}><button className='nm2' > Annuler </button></Link>
      </div>
      </div>
    </div>
   
</div>)}
{supMod2 && (<div className='modal'>
    <div className='overlay'>
        
   
    <div className='modal-content'>
       
      <h2>Mot de passe modifier avec succès</h2>
      <div className='supD'>
     
      <Link onClick={toggleSup2}> <button className='supC' >QUITER</button> </Link>
    
      </div>
     
      </div>
    </div>
   
</div>)}
                    
                    <div className="hero"> 
                     <h3 className="nom">{secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}</h3>
                     
                    </div>
       
                    <div class="options">
     <h2 className="form-title2"> {secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}</h2>           
    <button class="option-btn" onClick={e => setValid('on')}>Modifier</button>
    <button class="option-btn2" onClick={logout}>Déconnexion</button>
  </div>



                    </div>


    <NavLink to={'/accueil2/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Accueil</button> </NavLink>
<NavLink to={'/candidatDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Candidats</button> </NavLink>
<NavLink to={'/depenseDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Depenses</button> </NavLink>


<NavLink to={'/payementDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Recettes</button> </NavLink>
<NavLink to={'/bilan/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Bilan Global</button> </NavLink>
<NavLink to={'/optPresenceDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Presence</button> </NavLink>
<NavLink to={'/resultatDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Resultats</button> </NavLink>



<Link onClick={logout} className="link">
                               <img src={log} className="am" />
                               <div className="deco">DECONNEXION</div>
                               
                          </Link>  

</div>

}else{
    bar=  <IconContext.Provider value={{color:'#fff'}} >
                
    <div className="icon">
 <div>
 &nbsp;&nbsp;&nbsp;
 <img src={logo} className="userLog"  /> 
 </div>
 <div className="naI">
               {secretaire.map((data)=>(data.auto_ecole))} 
             </div>   
       
 <div className="navbar">
 
    <Link to="#" className={sidebar?  "trois": "menu-bars"}>
        <FaIcons.FaBars onClick={showSidebar} />
    </Link>
 
    &nbsp;&nbsp;&nbsp;
      
 
 </div>
 
 <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
    <ul className="nav-menu-items" >
        <li className="navbar-toggle">
            <Link to="#" className="menu-bars">
               <AiIcons.AiOutlineClose onClick={showSidebar}/>
            </Link>
        </li>
        <div className="carD">
                    <div className="profil">
        <AiIcons.AiOutlineUser className="icone-profil"   onClick={toggleMenu} />
        <div className="infos-utilisateur">
           <p className="inPo"   onClick={toggleMenu}> {secretaire.map((data)=>(data.poste))} </p>
          <p className="inN"  onClick={toggleMenu}> {secretaire.map((data)=>(data.nom))}  {secretaire.map((data)=>(data.prenom))} </p>
        </div>
      </div>


      {menuVisible && (
      <div className="dropdown-menu">
         <button className="option-btnMobi" onClick={e => setValid('on')}>Modifier</button>
         <button className="option-btnMobi2" onClick={logout}>Déconnexion</button>
      </div>
   )}

      </div>
        {sidedataDir.map((item, index) =>{
           const isActive = location.pathname.startsWith(item.path);
          return (
           
            <li key={index} className={isActive ? `${item.cName} active` : item.cName} onClick={showSidebar}>
                             <Link to={item.path+'/'+o}>
                    <span>{item.title}</span>
                 </Link>
                
            </li>
            
          
          )
 
        })}
       <li className="nav-text">
        <Link onClick={logout}  >
             
               <span>Deconnexion</span>
               
          </Link>
          </li> 
    </ul>
 </nav>
 </div>

 {supMod && (<div className='modalSup'>
    <div className='overlay'>
        
    </div>
    <div className='modal-contentSupInfo'>
       <div className="containerModal"> 
        <h3 className="form-title2">MODIFIER MOT DE PASSE</h3>
     
      <div className='supD'>
      <input type="text"  name="prenom" className="inputCmot1"  id="prenom"  value={secretaire.map((data)=>(data.nom))}  ></input>
     <input type="text"  name="prenom" className="inputCmot1"  id="prenom"  value={secretaire.map((data)=>(data.prenom))}  ></input>
      
     
      </div>
      <label htmlFor="libelle" className="">Ancien mot de pass</label> <br/>
      <input type="pasword"  name="prenom" className="inputCmot"  id="prenom"   onChange={e => setOld(e.target.value)}   ></input>
      <br/>
      <h4 className="text-danger">{info1}</h4>
      <br/>
      <label htmlFor="libelle" className="">Nouveau mot de pass</label> <br/>
      <input type="pasword"  name="prenom" className="inputCmot"  id="prenom" onChange={e => setNew1(e.target.value)}   ></input>
      <br/>
      <label htmlFor="libelle" className="">Confirmer mot de pass</label> <br/>
      <input type="pasword"  name="prenom" className="inputCmot"  id="prenom"   onChange={e => setNew2(e.target.value)} ></input>
      <br/>
      <h4 className="text-danger">{info2}</h4>
      <br/>
      <div className="supD">
      <Link onClick={handleModf}><button className='nm1' > Modifier </button></Link>
      <Link onClick={toggleSup}><button className='nm2' > Annuler </button></Link>
      </div>
      </div>
    </div>
   
</div>)}
{supMod2 && (<div className='modal'>
    <div className='overlay'>
        
   
    <div className='modal-content'>
       
      <h2>Mot de passe modifier avec succès</h2>
      <div className='supD'>
     
      <Link onClick={toggleSup2}> <button className='supC' >QUITER</button> </Link>
    
      </div>
     
      </div>
    </div>
   
</div>)} 


 </IconContext.Provider>
}

  const [selectedLoginId, setSelectedLoginId] = useState(null);

          const toggleLoginDetails = (id) => {
            setSelectedLoginId(selectedLoginId === id ? null : id);
          };
          

let load

if(viewLoader==true){
    load=   <div className="avt">
    <div className="rin">
      <div className="chm">
        <Loader/>
      </div>
    </div>
  </div>
}else{
    load=''
}



if(loader){
    return(
        <div className="per">
        
        <div className="loader">
        <div className="overlayLo"> 
            <div className="loaderP">
            <Loader/>
            </div>
            </div>
            </div>
            </div>
    )
}else if(error){
    return (<div>Une erreur est survenue : {error.message}</div>)
}
else{
if(largeurEcran>= 1024){ 
    return(
        <>
        <div className='wini'>
 
{bar}


        <h1 className='win'>La page de gestion des candidats</h1><br/><br/>
        {load}
        <div className='inove'>
            <div >
                <div className='izi'>
        <div className="search">
             <input type="text" className='recherche' name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e=>setQuery(e.target.value) }  ></input>
             </div>
             </div><br/><br/><br/>
             
             <h4 className='che'> Recherche personnalisée </h4>
           <div className='person'>
            <input type='date' value={date1} className='oixe' onChange={e => setDate1(e.target.value)}></input> <input type='date' value={date2} className='oixe' onChange={e => setDate2(e.target.value)} ></input> 
            <button type='submit' onClick={handlePropose} className='butonSF6'>Appliquer</button>
           </div>
           
             </div>
             
             <div className='autre'  >
             
             <select name="poste"   className='autreSel' onChange={e => setAnnexe(e.target.value)} >
            <option >{b}</option>
                     
                {  
                    ville.filter((data) => data.ville!=b).map((data)=>(   
                        
                        <option  value={data.ville}  >{data.ville}</option>
                            
                    ))
                    
                }
           </select>   <br/>
           
                <select name="poste" className='autreSel'  onChange={e => setValue(e.target.value)}>
                <option value={'Enregistré ce mois'}>Enregistré ce mois</option>
                   <option value={'Tous les Candidats'}>Tous les Candidats</option>
            
            <option value={'Enregistré cette année'}>Enregistré cette année</option>
            
            <option value={'Qui ont retiré leurs permis'}>Qui ont retiré leurs permis</option>
            
                </select>

                <button className='butonSF4' onClick={handleSubmit}>Appliquer</button>
           </div>
          
           </div> <br/><br/><br/>
           <Link to={'/createCandidatDir/'+o} className='yello'> <button className='ajouter'>AJOUTER UN CANDIDAT</button> </Link> <br/><br/>
           <div className="card-list20">
{
        candidat.filter((data) => data.nom.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query)).reverse().map((data, i)=> (
            <div className={`card20 ${selectedLoginId === data.id ? 'card-expanded' : ''}`} key={i}>
             <img src={data.sexe=='Masculin' ? moni : secre}  alt="Candidat 1" />
         <div className='info'>
            <p className='name'> {data.nom} {data.prenom}</p>
         
            <a href={`tel:+229${data.contact}`} className="contact-link">
            <p className="contacts">
              <FaPhoneAlt className="phone-icon" /> {data.contact}
            </p>
          </a>
               
<div className="login-section">
{selectedLoginId===data.id && (
            <div className="login-details">
             <button onClick={() => toggleLoginDetails(data.id)} className="eye-button">
                  {selectedLoginId === data.id ? '👁️' : '👁️‍🗨️'}
                </button>
              <p>Username: {data.user_name}</p>
              <p>Password: {data.password}</p>
              
            </div>
          )}

               {selectedLoginId !== data.id && (
              <p className="login">
                Login***
                <button onClick={() => toggleLoginDetails(data.id)} className="eye-button">
                  {selectedLoginId === data.id ? '👁️' : '👁️‍🗨️'}
                </button>
              </p>
            )}

</div>  
            <Link to={'/pageCandidatDir/'+ data.id} >    <button className="modifier-btn">VOIR</button>  </Link>
            </div>
        </div>
    
        ))
      } 
</div>
    <br/>
    </div>
    <Footer/>
    </>
    )}else{
        return(
            <>
            <div className='wini'>
     
    {bar}
    
    
            <h1 className='win'>La page de gestion des candidats</h1><br/><br/>
           




{load}
               <div className='barContener'>

                {/* Barre de recherche */}

<input type="text"className='seLe'  name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e => setQuery(e.target.value)}></input>
<br/>
             
<select name="poste"   className='rechBar' onChange={e => setAnnexe(e.target.value)} >
                <option >{b}</option>
                         
                    {  
                        ville.filter((data) => data.ville!=b).map((data)=>(   
                            
                            <option  value={data.ville}  >{data.ville}</option>
                                
                        ))
                        
                    }
               </select> 


{/* Barre de sélection avec bouton Appliquer */}
<div className='barChoixT'>
<select name="poste" className='choosee' onChange={e => setValue(e.target.value)}>
    <option value={'Enregistré ce mois'}>Enregistré ce mois</option>
            <option value={'Tous les Candidats'}>Tous les Candidats</option>
            <option value={'Enregistré cette année'}>Enregistré cette année</option>
            
            <option value={'Qui ont retiré leurs permis'}>Qui ont retiré leurs permis</option>
    </select>
{/* Bouton Appliquer pour le select */}
<button className='butonSF' onClick={handleSubmit}>Appliquer</button>
</div>

{/* Barre de recherche personnalisée */}
<div className='persBarT'>

<input type='date' className='perSF' value={date1} onChange={e => setDate1(e.target.value)}></input>
<input type='date' className='perSF' value={date2} onChange={e => setDate2(e.target.value)}></input>
<button type='submit' className='butonSF' onClick={handlePropose}>APPLIQUER</button>
</div>

</div>





               <Link to={'/createCandidatDir/'+o} className='yello'> <button className='ajouter'>AJOUTER UN CANDIDAT</button> </Link> <br/><br/>
               <div className=''>
            <div className=''>
              
              
            <div className="card-list20">
{
        candidat.filter((data) => data.nom.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query)).reverse().map((data, i)=> (
            <div className={`card20 ${selectedLoginId === data.id ? 'card-expanded' : ''}`} key={i}>
             <img src={data.sexe=='Masculin' ? moni : secre}  alt="Candidat 1" />
         <div className='info'>
            <p className='name'> {data.nom} {data.prenom}</p>
         
            <a href={`tel:+229${data.contact}`} className="contact-link">
            <p className="contacts">
              <FaPhoneAlt className="phone-icon" /> {data.contact}
            </p>
          </a>
               
<div className="login-section">
{selectedLoginId===data.id && (
            <div className="login-details">
             <button onClick={() => toggleLoginDetails(data.id)} className="eye-button">
                  {selectedLoginId === data.id ? '👁️' : '👁️‍🗨️'}
                </button>
              <p>Username: {data.user_name}</p>
              <p>Password: {data.password}</p>
              
            </div>
          )}

               {selectedLoginId !== data.id && (
              <p className="login">
                Login***
                <button onClick={() => toggleLoginDetails(data.id)} className="eye-button">
                  {selectedLoginId === data.id ? '👁️' : '👁️‍🗨️'}
                </button>
              </p>
            )}

</div>
            <Link to={'/pageCandidatDir/'+ data.id} >    <button className="modifier-btn">VOIR</button>  </Link>
            </div>
        </div>
    
        ))
      } 
</div>

              
              
              
                                         </div>
            
        </div>
        <br/>
        </div>
        <Footer/>
        </>
        )
    }
}
    }
