
import React,{ useEffect, useState} from 'react';
import axios from 'axios';
import moment from "moment";
import moni from 'F:/projet/first/src/components/image/moni.png';
import Footer from '../components/footer';
import log from 'F:/projet/first/src/components/image/log.png';
import { Link, NavLink ,useParams, useNavigate,  useLocation} from "react-router-dom";
import Loader from "../components/loader";
import Cookies from 'js-cookie';
import { sidedataDir } from 'F:/projet/first/src/components/sidedataDir'
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { IconContext } from "react-icons";
import logo from 'F:/projet/first/src/components/image/logo4.jpg'
import croix from './croix.png'
import 'moment/locale/fr';

export default function DepenseDir(){
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };
    const [sidebar, setSidebar]= useState(false)
    const showSidebar = () => setSidebar(!sidebar)
    const [lu,setLu] = useState(Cookies.get('token'))
    const [tok, setTok]= useState('')
    const [loader, setLoader]= useState(true)
    const [error, setError] = useState(null);
    const [titre,setTitre]= useState(Cookies.get('titre'))
    const [vtoken, setVtoken]= useState(Cookies.get('token'))
    const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
    const [select1,setSelect1]= useState(Cookies.get('select1'))
    const [select2,setSelect2]= useState(Cookies.get('select2'))
    const [old, setOld]= useState('')
    const [recup, setRecup]= useState('')
    const [new1, setNew1]= useState('')
    const [new2, setNew2]= useState('')
    const [info1, setInfo1]= useState('')
    const [info2, setInfo2]= useState('')
    var navigate= useNavigate()
    const [pop, setPop]= useState('')
    const [pop2, setPop2]= useState('')
    const [largeurEcran, setLargeurEcran] = useState(window.innerWidth);
    const [viewLoader, setViewLoader] = useState(false)
    const upTok =()=>{
         setLu(Cookies.get('token'))
     
    }
    const [view, setView]= useState('')
    const mettreAJourLargeurEcran = () => {
        setLargeurEcran(window.innerWidth);
    
      };

      if(tok!= ''){
        if(tok!= lu){
            Cookies.remove('token')
            Cookies.remove('autoa')
            Cookies.remove('titre')
            Cookies.remove('gestauto')
            window.location.reload();
            navigate('/')

        }
    }

    


    
    
    const logout = () =>{
        Cookies.remove('token')
        Cookies.remove('autoa')
        Cookies.remove('titre')
        Cookies.remove('gestauto')
        Cookies.remove('default')
        Cookies.remove('default2')
        Cookies.remove('defaultCode')
        Cookies.remove('defaultCode2')
        Cookies.remove('select1')
        Cookies.remove('select2')

        window.location.reload();
       navigate('/')
    }
    useEffect(()=>{
        if(!Cookies.get('token')){
           navigate('/')
        }
   }, [])





useEffect(() => {
        
    window.addEventListener('resize', mettreAJourLargeurEcran);

   
    return () => {
      window.removeEventListener('resize', mettreAJourLargeurEcran);
      
    };
   
  }, []);
// Recuperer token
useEffect(()=>{
    

axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/setToken', { gestauto, titre},{ headers})
    .then(res => 
        {setTok(res.data[0].token)
        

        })
    .catch(err => console.log(err));
}, [])
//nav en haut
    const[profil, setProfil]= useState(false)
    const [ce,setCe]= useState(Cookies.get('autoa'))
    const showProfil = () => setProfil(!profil)
    const [loga, setLoga]= useState('')
    const [valeurLogaID, setValeurLogaID]= useState('')
    const [logaId, setLogaID]= useState(0)
    const [secretaire, setSecretaire] = useState([])
  
    const [idAnnexe,setIdAnnexe]= useState('')
    const [lea,setLea]=useState([0])
    var date= new Date();
    const now= date.toLocaleDateString();
   const years= date.getFullYear()
   const day= date.getDate()
  var  month=  date.getMonth()+1
  const days= date.getDate()
  var datee= new Date(years,month-2,days)
  var autre2= datee.toISOString().split('T')[0] 
  var autre= date.toISOString().split('T')[0]
    const[value,setValue]= useState('')
    
    const [date1, setDate1]= useState('')
    const [date2, setDate2]= useState('')


    useEffect(()=>{
        setDate1(autre2)
        setDate2(autre)
      

    }, [])


    const[inf, setInf] = useState(false)
    const[dataLibe, setDataLibe]= useState('')
    const[dataDate, setDataDate]= useState('')
    const[dataMontant, setDataMontant]= useState('')
    const[modal, setModal]= useState(false) 
    const toggleModal = ()=> {
        setModal(!modal)
    }
    
    const togglePop =()=> {
        setInf(!inf)
    }
    let varDate, varMontant ,  varLibe
    if(pop2!= ''){
        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/vivooSp', {pop2},{ headers})
        .then(res => {
          setDataLibe(res.data[0].libelle)
          setDataDate(res.data[0].date)
          setDataMontant(res.data[0].montant)
         
          
        }).catch(err => console.log(err));
        if(dataLibe !='' && dataDate !='' && dataMontant !=''){
            console.log('les pop ', dataDate,dataMontant,dataLibe)
            
          
            togglePop()
            setPop2('')
        }
       
    }
    
    if(pop!= ''){
        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/vivoo', {pop},{ headers})
        .then(res => {
          setDataLibe(res.data[0].libelle)
          setDataDate(res.data[0].date)
          setDataMontant(res.data[0].montant)
         
          
        }).catch(err => console.log(err));
        if(dataLibe !='' && dataDate !='' && dataMontant !=''){
            console.log('les pop ', dataDate,dataMontant,dataLibe)
            
          
            togglePop()
            setPop('')
        }
       
    }





    const [annexe, setAnnexe]= useState('')
    useEffect(()=>{
        axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/navbar?id='+gestauto+'&titre='+titre+'', { headers})
        .then(res =>{
            setSecretaire(res.data)

        } )
        .catch(err => console.log(err));
    }, [])

    const page="candidatDir";

    const [ville, setVille] = useState([])
    useEffect(()=>{
        axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/candidatDir?id='+ce+'' , { headers})
        .then(res =>{
            setVille(res.data)
            setLoga(res.data[0].ville)
            setLogaID(res.data[0].id_annexe)
            setValeurLogaID(res.data[0].id_ecole)
            setAnnexe(res.data[0].ville)
        } )
        .catch(err => console.log(err));
    }, [])

    const [id_annexe]= ville.map((data)=>(data.id_annexe));
    
     const [query, setQuery]= useState(""); 

         
         const [depense, setDepense] = useState([])
         const [depense2, setDepense2] = useState([])
         function handleSubmit(event){
            event.preventDefault();
            
            const [id_ecole]= ville.map((data)=>(data.id_ecole));

            setViewLoader(true)
           
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/candidatDir', {annexe,id_annexe,id_ecole}, { headers})
            .then(res => {
                
                
                const a= res.data[0].id_ecole
                
                setLea(res.data)
          if(value==''){
           
          
             
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/infoDepense', {a,month,years}, { headers})
            .then(res => {
                setDepense(res.data)
                setViewLoader(false)
            
          }).catch(err =>{
             console.log(err)
             setError(err)
             setViewLoader(false)
             
          });

            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/infoDepense2', {a,month,years}, { headers})
            .then(res => {
                setDepense2(res.data)
                setViewLoader(false)
            
          }).catch(err => {
            console.log(err)
           setError(err)
           setViewLoader(false) 
          });
    
          } else if(value=='Enregistré cette année'){
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/depYearInit',{years,a}, { headers})
            .then(res => {
                setDepense(res.data)
             setViewLoader(false)   
                
            })
            .catch(err => {
                console.log(err)
             setError(err)
             setViewLoader(false)   
            });

            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/depYearInit2',{years,a}, { headers})
            .then(res => {
                setDepense2(res.data)
                setViewLoader(false)
            })
            .catch(err => {
                console.log(err)
             setError(err)
             setViewLoader(false)   
            });

            if(o==0){
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defaDepenseInfoThisYearsInit',{years,a}, { headers})
            .then(res => {
                setDepense(res.data)
             setViewLoader(false)   
            })
            .catch(err => {
                console.log(err)
             setError(err)   
                setViewLoader(false)
            });

            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defaDepenseInfoThisYearsInit2',{years,a}, { headers})
            .then(res => {
                setDepense2(res.data)
             setViewLoader(false)
                
            })
            .catch(err => {
                console.log(err)
             setError(err)
             setViewLoader(false)   
            });
            }
        }else if(value=='Tous les Depenses'){
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/depInfoInit',{a}, { headers})
            .then(res => {
                setDepense(res.data)
             setViewLoader(false)   
            })
            .catch(err => {
                console.log(err)
             setError(err)
             setViewLoader(false)   
            });

            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/depInfoInit2',{a}, { headers})
            .then(res => {
                setDepense2(res.data)
             setViewLoader(false)   
            })
            .catch(err => {
                console.log(err)
                setError(err)
                setViewLoader(false)
            });

            if(o==0){
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defaDepInfoInit',{le,a}, { headers})
            .then(res => {
                setDepense(res.data)
             setViewLoader(false)   
            })
            .catch(err => {
                console.log(err)
             setError(err) 
             setViewLoader(false)  
            });

            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defaDepInfoInit2',{le,a}, { headers})
            .then(res => setDepense2(res.data))
            .catch(err => console.log(err));
            }
        }
          else  if(value=='Enregistré ce mois'){
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/depenseInfoThisInit',{month,years,a}, { headers})
            .then(res => {
                setDepense(res.data)
             setViewLoader(false)   
            })
            .catch(err => {
                console.log(err)
             setError(err)
             setViewLoader(false)   
            });

            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/depenseInfoThisInit2',{month,years,a}, { headers})
            .then(res => {
                setDepense2(res.data)
             setViewLoader(false)   
            })
            .catch(err => {
                console.log(err)
             setError(err)
             setViewLoader(false)   
            });

            if(o==0){
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defaDepenseInfoThisInit',{month,years,a}, { headers})
                .then(res => {
                    setDepense(res.data)
                    setViewLoader(false)
                })
                .catch(err => {
                    console.log(err)
                 setError(err)   
                    setViewLoader(false)
                });

                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defaDepenseInfoThisInit2',{month,years,a}, { headers})
                .then(res => {
                    setDepense2(res.data)
                 setViewLoader(false)   
                })
                .catch(err => {
                    console.log(err)
                 setError(err)
                 setViewLoader(false)   
                    
                });
            }
            }
 
            }).catch(err => {
                console.log(err)
             setError(err)
             setViewLoader(false)   
            });
         
        }
             
        
       



        const  le = lea.map((data)=>(data.id_ecole))
        
        var o=le
        const {id}= useParams();
        if (o!=0){
            o=le
        }else{
            
            o=id
        }


        if(logaId!='' && o==0){
        
            if(select1=== undefined || select2===undefined){
                /*
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defa', {loga,logaId}, { headers})
            .then(res => {
              
         
                const a= res.data[0].id_ecole
              
                
                
                
                setLogaID('')
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/infoDepense', {a,month,years}, { headers})
                .then(res => {
                    setDepense(res.data)
                    setLoader(false)
                }
                ).catch(err =>{
                    console.log(err)
                    setError(err)
                    setLoader(false)
                } );
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/infoDepense2', {a,month,years}, { headers})
                .then(res => {
                    setDepense2(res.data)
                    setLoader(false)
                }
      
                
                ).catch(err =>{
                    console.log(err)
                    setError(err)
                    setLoader(false)
                } );
       
            
            }).catch(err =>{
                console.log(err)
                    setError(err)
                    setLoader(false)
            } );
             */
            setLogaID('')
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/depParDefaut', {valeurLogaID,date1,date2}, { headers})
            .then(res => {
                
                setDepense(res.data)
                setLoader(false)
            
          }).catch(err =>{
             console.log(err)
             setError(err)
             setLoader(false)
             
          });

            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/depParDefaut2', {valeurLogaID,date1,date2}, { headers})
            .then(res => {
                setDepense2(res.data)
                setLoader(false)
            
          }).catch(err => {
            console.log(err)
           setError(err)
           setLoader(false) 
          });
            
        }

            else if(select1 || select2){
            
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defa', {loga,logaId}, { headers})
                .then(res => {
                    setDate1(select1)
                    setDate2(select2) 
             
                    const a= res.data[0].id_ecole
                  
                    
                    
                    
                    setLogaID('')
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/vVoirDep', {a,select1,select2}, { headers})
                .then(res => {
                    setDepense(res.data)
                    setLoader(false)
                
              }).catch(err =>{
                 console.log(err)
                 setError(err)
                 setLoader(false)
                 
              });
    
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/vVoirDep2', {a,select1,select2}, { headers})
                .then(res => {
                    setDepense2(res.data)
                    setLoader(false)
                
              }).catch(err => {
                console.log(err)
               setError(err)
               setLoader(false) 
              });

            }).catch(err =>{
                console.log(err)
                    setError(err)
                    setLoader(false)
            } );
            }
   
        }


        useEffect(()=>{
            if(o!=0){
           
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/idAnnexe', {o}, { headers})
            .then(res =>{ 
                
                setIdAnnexe(res.data[0].ville)             
            }
               
    
            ).catch(err => console.log(err));
        }
        }, [])
            var b
           
        
         
         if(idAnnexe!=''){
            b= idAnnexe
          }  else{
            b= loga
          }

        useEffect(()=>{
            if(o!=0){
                if(select1=== undefined || select2===undefined){
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/ticDep', {o,month,years,le}, { headers})
            .then(res =>{ 
                
                setDepense(res.data)      
                setLoader(false)      
            }
               

            ).catch(err =>{
                console.log(err)
                    setError(err)
                    setLoader(false)
            });

            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/ticDep2', {o,month,years,le}, { headers})
            .then(res =>{ 
                
                setDepense2(res.data) 
                setLoader(false)           
            }
               

            ).catch(err =>{
                console.log(err)
                    setError(err)
                    setLoader(false)
                  });
        }else  if(select1 || select2){

            setDate1(select1)
            setDate2(select2) 
                const a= o
        
                setLogaID('')
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/vVoirDep', {a,select1,select2}, { headers})
            .then(res => {
                setDepense(res.data)
                setLoader(false)
            
          }).catch(err =>{
             console.log(err)
             setError(err)
             setLoader(false)
             
          });

            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/vVoirDep2', {a,select1,select2}, { headers})
            .then(res => {
                setDepense2(res.data)
                setLoader(false)
            
          }).catch(err => {
            console.log(err)
           setError(err)
           setLoader(false) 
          });

    
        }
            }
        }, [])
       
        
        function handlePropose(event){
            Cookies.set('select1',date1)
            Cookies.set('select2',date2)
            setViewLoader(true)
            event.preventDefault();
            
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/personDepenseDir', {date1,date2,le,o}, { headers})
            .then(res => {
               setDepense(res.data)
            setViewLoader(false)
                
                
            }).catch(err => {
                console.log(err)
             setViewLoader(false)
             setError(err)   
            });
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/personDepense2Dir', {date1,date2,le,o}, { headers})
            .then(res => {
               setDepense2(res.data)
        setViewLoader(false)
                
                
            }).catch(err => {
                console.log(err)
             setError(err)   
                setViewLoader(false)
            });

            if(o==0){
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defaPersonDepenseDir', {date1,date2,valeurLogaID}, { headers})
                .then(res => {
                   setDepense(res.data)
                
                    setViewLoader(false)
                    
                }).catch(err => {
                    console.log(err)
                 setError(err)
                 setViewLoader(false)   
                });
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defaPersonDepense2Dir', {date1,date2,valeurLogaID}, { headers})
                .then(res => {
                   setDepense2(res.data)
                 
                    setViewLoader(false)
                    
                }).catch(err => {
                    console.log(err)
                 setViewLoader(false)
                 setError(err)   
                });
            }
        }


        if(view!= ''){
            setViewLoader(true)
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/vevDi', {years,month,day,view}, { headers})
            .then(res => {
               if(res.data.message== 'No match'){
                toggleModal()
                setViewLoader(false)
                    setView('')
               }else{
                setViewLoader(false)
                navigate('/updateDepenseDir/'+res.data[0].id)
               }
              
            }).catch(err => console.log(err));
            setView('')
          }     
    


//modification password



const[valid, setValid]= useState('')
              
const[valid2, setValid2]= useState('')
const[supMod, setSupMod]= useState(false) 

const toggleSup = ()=> {
  
 
  setSupMod(!supMod)
  setValid('')
 
}


if(valid!= ''){
 toggleSup()
 


} 



              

const[supMod2, setSupMod2]= useState(false) 

const toggleSup2 = ()=> {
  
 
  setSupMod2(!supMod2)
  setValid2('')
  
}


if(valid2!= ''){
 toggleSup2()
 


} 




function handleModf(event){
    const id= gestauto
    event.preventDefault();
    const currentPassword = secretaire.map((data) => data.password)[0];
    
    if(currentPassword===old){
    
        if(new1===new2){
            setInfo2('')
            axios.put('https://gestauto-56bfcbe4b876.herokuapp.com/upPassword/'+id, {new1,titre}, { headers})
            .then(res => {
                    
        axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/navbar?id='+gestauto+'&titre='+titre+'', { headers })
        .then(res => setSecretaire(res.data))
        .catch(err => console.log(err));
                toggleSup()

            setValid2('on')
            }).catch(err => console.log(err));
        }else{
           
            setInfo2('Incorrecte')
            setTimeout(() => setInfo2(''), 3000)
        }
    }else{
        setInfo1('Incorrecte')
        setTimeout(() => setInfo1(''), 3000)
    }
}




const [menuVisible, setMenuVisible] = useState(false);

const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  
};






useEffect(() => {
    const handleClickOutside = (event) => {
        if (menuVisible && !document.querySelector('.carD').contains(event.target)) {
            setMenuVisible(false);
        }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, [menuVisible]);


       var bar 
       const location = useLocation();
        if(largeurEcran>= 1000){
            bar= <div className="barHaut">
 <div className="lep">
                    
                    
                    <img src={moni} className="user" onClick={showProfil} />
                    
                    {supMod && (<div className='modalSup'>
    <div className='overlay'>
        
    </div>
    <div className='modal-contentSupInfo'>
       <div className="containerModal"> 
        <h3 className="form-title2">MODIFIER MOT DE PASSE</h3>
     
      <div className='supD'>
      <input type="text"  name="prenom" className="inputCmot1"  id="prenom"  value={secretaire.map((data)=>(data.nom))}  ></input>
     <input type="text"  name="prenom" className="inputCmot1"  id="prenom"  value={secretaire.map((data)=>(data.prenom))}  ></input>
      
     
      </div>
      <label htmlFor="libelle" className="">Ancien mot de pass</label> <br/>
      <input type="pasword"  name="prenom" className="inputCmot"  id="prenom"   onChange={e => setOld(e.target.value)}   ></input>
      <br/>
      <h4 className="text-danger">{info1}</h4>
      <br/>
      <label htmlFor="libelle" className="">Nouveau mot de pass</label> <br/>
      <input type="pasword"  name="prenom" className="inputCmot"  id="prenom" onChange={e => setNew1(e.target.value)}   ></input>
      <br/>
      <label htmlFor="libelle" className="">Confirmer mot de pass</label> <br/>
      <input type="pasword"  name="prenom" className="inputCmot"  id="prenom"   onChange={e => setNew2(e.target.value)} ></input>
      <br/>
      <h4 className="text-danger">{info2}</h4>
      <br/>
      <div className="supD">
      <Link onClick={handleModf}><button className='nm1' > Modifier </button></Link>
      <Link onClick={toggleSup}><button className='nm2' > Annuler </button></Link>
      </div>
      </div>
    </div>
   
</div>)}
{supMod2 && (<div className='modal'>
    <div className='overlay'>
        
   
    <div className='modal-content'>
       
      <h2>Mot de passe modifier avec succès</h2>
      <div className='supD'>
     
      <Link onClick={toggleSup2}> <button className='supC' >QUITER</button> </Link>
    
      </div>
     
      </div>
    </div>
   
</div>)}
                    
                    <div className="hero"> 
                     <h3 className="nom">{secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}</h3>
                     
                    </div>
       
                    <div class="options">
     <h2 className="form-title2"> {secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}</h2>           
    <button class="option-btn" onClick={e => setValid('on')}>Modifier</button>
    <button class="option-btn2" onClick={logout}>Déconnexion</button>
  </div>
       
                    </div>

            <NavLink to={'/accueil2/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Accueil</button> </NavLink>
    <NavLink to={'/candidatDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Candidats</button> </NavLink>
        <NavLink to={'/depenseDir/'+id} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Depenses</button> </NavLink>
        
        
        <NavLink to={'/payementDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Recettes</button> </NavLink>
        <NavLink to={'/bilan/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Bilan Global</button> </NavLink>
        <NavLink to={'/optPresenceDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Presence</button> </NavLink>
        <NavLink to={'/resultatDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Resultats</button> </NavLink>
        
        
        <Link onClick={logout} className="link">
                               <img src={log} className="am" />
                               <div className="deco">DECONNEXION</div>
                               
                          </Link>  
    
    </div>
    
    
        }else{
            bar=  <IconContext.Provider value={{color:'#fff'}} >
                         
            <div className="icon">
         <div>
         &nbsp;&nbsp;&nbsp;
         <img src={logo} className="userLog"  /> 
         </div>
               
         <div className="naI">
               {secretaire.map((data)=>(data.auto_ecole))} 
             </div>      
         <div className="navbar">
         
            <Link to="#" className={sidebar?  "trois": "menu-bars"}>
                <FaIcons.FaBars onClick={showSidebar} />
            </Link>
         
            &nbsp;&nbsp;&nbsp;
              
         
         </div>
        
 <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
    <ul className="nav-menu-items" >
        <li className="navbar-toggle">
            <Link to="#" className="menu-bars">
               <AiIcons.AiOutlineClose onClick={showSidebar}/>
            </Link>
        </li>
        <div className="carD">
                    <div className="profil">
        <AiIcons.AiOutlineUser className="icone-profil"   onClick={toggleMenu} />
        <div className="infos-utilisateur">
           <p className="inPo"   onClick={toggleMenu}> {secretaire.map((data)=>(data.poste))} </p>
          <p className="inN"  onClick={toggleMenu}> {secretaire.map((data)=>(data.nom))}  {secretaire.map((data)=>(data.prenom))} </p>
        </div>
      </div>


      {menuVisible && (
      <div className="dropdown-menu">
         <button className="option-btnMobi" onClick={e => setValid('on')}>Modifier</button>
         <button className="option-btnMobi2" onClick={logout}>Déconnexion</button>
      </div>
   )}

      </div>
        {sidedataDir.map((item, index) =>{
              const isActive = location.pathname.startsWith(item.path);
          return (
           
            <li key={index} className={isActive ? `${item.cName} active` : item.cName} onClick={showSidebar}>
                 <Link to={item.path+'/'+o}>
                    <span>{item.title}</span>
                 </Link>
                
            </li>
            
          
          )
 
        })}
       <li className="nav-text">
        <Link onClick={logout}  >
             
               <span>Deconnexion</span>
               
          </Link>
          </li> 
    </ul>
 </nav>
         </div>

         {supMod && (<div className='modalSup'>
    <div className='overlay'>
        
    </div>
    <div className='modal-contentSupInfo'>
       <div className="containerModal"> 
        <h3 className="form-title2">MODIFIER MOT DE PASSE</h3>
     
      <div className='supD'>
      <input type="text"  name="prenom" className="inputCmot1"  id="prenom"  value={secretaire.map((data)=>(data.nom))}  ></input>
     <input type="text"  name="prenom" className="inputCmot1"  id="prenom"  value={secretaire.map((data)=>(data.prenom))}  ></input>
      
     
      </div>
      <label htmlFor="libelle" className="">Ancien mot de pass</label> <br/>
      <input type="pasword"  name="prenom" className="inputCmot"  id="prenom"   onChange={e => setOld(e.target.value)}   ></input>
      <br/>
      <h4 className="text-danger">{info1}</h4>
      <br/>
      <label htmlFor="libelle" className="">Nouveau mot de pass</label> <br/>
      <input type="pasword"  name="prenom" className="inputCmot"  id="prenom" onChange={e => setNew1(e.target.value)}   ></input>
      <br/>
      <label htmlFor="libelle" className="">Confirmer mot de pass</label> <br/>
      <input type="pasword"  name="prenom" className="inputCmot"  id="prenom"   onChange={e => setNew2(e.target.value)} ></input>
      <br/>
      <h4 className="text-danger">{info2}</h4>
      <br/>
      <div className="supD">
      <Link onClick={handleModf}><button className='nm1' > Modifier </button></Link>
      <Link onClick={toggleSup}><button className='nm2' > Annuler </button></Link>
      </div>
      </div>
    </div>
   
</div>)}
{supMod2 && (<div className='modal'>
    <div className='overlay'>
        
   
    <div className='modal-content'>
       
      <h2>Mot de passe modifier avec succès</h2>
      <div className='supD'>
     
      <Link onClick={toggleSup2}> <button className='supC' >QUITER</button> </Link>
    
      </div>
     
      </div>
    </div>
   
</div>)} 



         </IconContext.Provider>
                 
         }


         let totalDep1=0
         let totalDep2=0

         depense.map((data, i)=> (
           totalDep1=totalDep1+data.montant
       ))

    
       depense2.map((data, i)=> (
         totalDep2=totalDep2+data.montant
     ))



let autreDep

if(depense.length>0){
    autreDep =   <h4 className='ali'>Autres Depenses </h4>
}else{
    autreDep =''
}




     let load

if(viewLoader==true){
    load=   <div className="avt">
    <div className="rin">
      <div className="chm">
        <Loader/>
      </div>
    </div>
  </div>
}else{
    load=''
}


if(loader){
    return(
        <div className="per">
        
        <div className="loader">
        <div className="overlayLo"> 
            <div className="loaderP">
            <Loader/>
            </div>
            </div>
            </div>
            </div>
    )
}else if(error){
    return (<div>Une erreur est survenue : {error.message}</div>)
}
else{

     if(largeurEcran>= 1024){
    return(
        <>
    <div className='wini'>
        


        
       {bar}




        <h1 className='win'>La page de gestion des depenses</h1><br/><br/>

        {load}

        <div className='inove'>
            <div>
                <div className='izi'>
        <div className="search">

             <input type="text" className='recherche' name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e=>setQuery(e.target.value) }  ></input>
             </div>
</div>
             <br/><br/><br/>
             <h4 className='che'> Recherche personnalisée </h4>
           <div className='person'>
           <input type='date' value={date1} className='oixe' onChange={e => setDate1(e.target.value)}></input> <input type='date' value={date2} className='oixe' onChange={e => setDate2(e.target.value)} ></input> 
           <button type='submit' onClick={handlePropose} className='butonSF6'>Appliquer</button>
           </div>
             </div>

             <div className='autre'  >       

        
             
             <select name="poste" className='autreSel' onChange={e => setAnnexe(e.target.value)} >
            <option >{b}</option>
                     
                {  
                    ville.filter((data) => data.ville!=b).map((data)=>(   
                        
                        <option value={data.ville}>{data.ville}</option>
                            
                    ))
                    
                }
           </select>   
           <select name="poste" className='autreSel'  onChange={e => setValue(e.target.value)}>
           <option value={'Enregistré ce mois'}>Enregistré ce mois</option>
                   <option value={'Tous les Depenses'}>Tous les Depenses</option>
            
            <option value={'Enregistré cette année'}>Enregistré cette année</option>
            
           
            
                </select>
                <button className='butonSF4' onClick={handleSubmit}>Appliquer</button>
           </div>
          </div>

          <div>
    <br/><br/><br/>
    <Link to={'/createDepenseDir/'+o} className='yello'> <button className='ajouter'>AJOUTER UNE DEPENSE</button> </Link> <br/><br/>
    <h4 className='ali'>Depenses spéciales du directeur</h4>
   
           

        <div className=''>
        <div className=''>
            <div className='uncard-list'>
        {
                        
                        depense2.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                                <span className='texte-depense'>
                            <span className="montant">{data.montant} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                             <Link onClick={e => setView(data.id)}  >  <span className="icone-modifier2"> </span></Link> 
                               {modal && (<div className='modal'>
    <div className='overlay'>
        
    </div>
    <div className='modal-content'>
        <h2>Attention cette depence ne peut plus être modifiée!!!</h2>
      <Link onClick={toggleModal}>  <img src={croix} className='close-modal'/></Link>
    </div>
</div>)}
                             
                          </div>
                        ))
                      }
        
        </div>
        </div>
        
    </div>
    </div>
    

        <div className=''>
        <div className=''>
             {autreDep}
             <div className='uncard-list'>
             {
                        
                        depense.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                                <span className='texte-depense'>
                            <span className="montant">{data.montant} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                            
                             
                          </div>
                        ))
                      }
        </div>

             <div className='vT'>
             TOTAL : {totalDep1+totalDep2} FCFA
             </div>
        </div>
        
    </div>
    <br/>
    </div>
    <Footer/>
    </>
    )
                    }else if(largeurEcran>=510){
                        return(
                            <>
                        <div className='wini'>
                            
                    
                    
                            
                           {bar}
                    
                    
                    
                    
                            <h1 className='win'>La page de gestion des depenses</h1><br/><br/>
                    
                {load}
                    
                              <div>
                {/*les là*/}
                <div className='barContener'>
                
                {/* Barre de recherche */}
                
                <input type="text"className='seLe'  name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e => setQuery(e.target.value)}></input>
                <br/>
                <select name="poste"   className='rechBar' onChange={e => setAnnexe(e.target.value)} >
                <option >{b}</option>
                         
                    {  
                        ville.filter((data) => data.ville!=b).map((data)=>(   
                            
                            <option  value={data.ville}  >{data.ville}</option>
                                
                        ))
                        
                    }
                </select> 
                
                
                
                {/* Barre de sélection avec bouton Appliquer */}
                <div className='barChoixT'>
                <select name="poste" className='choosee' onChange={e => setValue(e.target.value)}>
                <option >Enregistré ce mois</option>
                                       <option >Tous les Depenses</option>
                                
                                <option>Enregistré cette année</option>
                                
                </select>
                {/* Bouton Appliquer pour le select */}
                <button className='butonSF' onClick={handleSubmit}>Appliquer</button>
                </div>
                
                {/* Barre de recherche personnalisée */}
                <div className='persBarT'>
                
                <input type='date' className='perSF' value={date1} onChange={e => setDate1(e.target.value)}></input>
                <input type='date' className='perSF' value={date2} onChange={e => setDate2(e.target.value)}></input>
                <button type='submit' className='butonSF' onClick={handlePropose}>APPLIQUER</button>
                </div>
                
                </div>
                {/*end là*/}
                
                
                        <br/><br/><br/>
                        <Link to={'/createDepenseDir/'+o} className='yello'> <button className='ajouter'>AJOUTER UNE DEPENSE</button> </Link> <br/><br/>
                        <h4 className='ali'>Depenses spéciales du directeur</h4>
                       
                    <br/>           
                    
                            <div className=''>
       <div className='card-list'>                        

{
                        
                        depense2.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                                <span className='texte-depense'>
                            <span className="montant">{data.montant} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                             <Link onClick={e => setView(data.id)}  >  <span className="icone-modifie"> </span></Link> 
                               {modal && (<div className='modal'>
    <div className='overlay'>
        
    </div>
    <div className='modal-content'>
        <h2>Attention cette depence ne peut plus être modifiée!!!</h2>
      <Link onClick={toggleModal}>  <img src={croix} className='close-modal'/></Link>
    </div>
</div>)}
                             
                          </div>
                        ))
                      }
        
        </div>
                            
                        </div>
                        </div>
                        
                    
                            <div className=''>
                            <div className=''>
                        {autreDep}
               <div  className='card-list'>           

{
                        
                        depense.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                                <span className='texte-depense'>
                            <span className="montant">{data.montant} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                            
                             
                          </div>
                        ))
                      }
        </div>

                    
                                 <div className='vT'>
                                 TOTAL : {totalDep1+totalDep2} FCFA
                                 </div>
                            </div>
                            
                        </div>
                        <br/>
                        </div>
                        <Footer/>
                        </>
                        )
                    }
                    
                    else{
                        return(
                            <>
                        <div className='wini'>
                            
                    
                    
                            
                           {bar}
                    
                    
                    
                    
                            <h1 className='win'>La page de gestion des depenses</h1><br/><br/>
                    
                {load}
                    
                              <div>
                {/*les là*/}
                <div className='barContener'>
                
                {/* Barre de recherche */}
                
                <input type="text"className='seLe'  name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e => setQuery(e.target.value)}></input>
                <br/>
                <select name="poste"   className='rechBar' onChange={e => setAnnexe(e.target.value)} >
                <option >{b}</option>
                         
                    {  
                        ville.filter((data) => data.ville!=b).map((data)=>(   
                            
                            <option  value={data.ville}  >{data.ville}</option>
                                
                        ))
                        
                    }
                </select> 
                
                
                
                {/* Barre de sélection avec bouton Appliquer */}
                <div className='barChoixT'>
                <select name="poste" className='choosee' onChange={e => setValue(e.target.value)}>
                <option >Enregistré ce mois</option>
                                       <option >Tous les Depenses</option>
                                
                                <option>Enregistré cette année</option>
                                
                </select>
                {/* Bouton Appliquer pour le select */}
                <button className='butonSF' onClick={handleSubmit}>Appliquer</button>
                </div>
                
                {/* Barre de recherche personnalisée */}
                <div className='persBarT'>
                
                <input type='date' className='perSF' value={date1} onChange={e => setDate1(e.target.value)}></input>
                <input type='date' className='perSF' value={date2} onChange={e => setDate2(e.target.value)}></input>
                <button type='submit' className='butonSF' onClick={handlePropose}>APPLIQUER</button>
                </div>
                
                </div>
                {/*end là*/}
                
                
                        <br/><br/><br/>
                        <Link to={'/createDepenseDir/'+o} className='yello'> <button className='ajouter'>AJOUTER UNE DEPENSE</button> </Link> <br/><br/>
                        <h4 className='ali'>Depenses spéciales du directeur</h4>
                       
                    <br/>           
                    
                            <div className=''>
                               

{
                        
                        depense2.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                                <span className='texte-depense'>
                            <span className="montant">{data.montant} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                             <Link onClick={e => setView(data.id)}  >  <span className="icone-modifie"> </span></Link> 
                               {modal && (<div className='modal'>
    <div className='overlay'>
        
    </div>
    <div className='modal-content'>
        <h2>Attention cette depence ne peut plus être modifiée!!!</h2>
      <Link onClick={toggleModal}>  <img src={croix} className='close-modal'/></Link>
    </div>
</div>)}
                             
                          </div>
                        ))
                      }
        

                            
                        </div>
                        </div>
                        
                    
                            <div className=''>
                            <div className=''>
                                {autreDep}
                               
                          

{
                        
                        depense.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                                <span className='texte-depense'>
                            <span className="montant">{data.montant} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                            
                             
                          </div>
                        ))
                      }
        

                    
                                 <div className='vT'>
                                 TOTAL : {totalDep1+totalDep2} FCFA
                                 </div>
                            </div>
                            
                        </div>
                        <br/>
                        </div>
                        <Footer/>
                        </>
                        )
                
                    }
                }
}

