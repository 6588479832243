import React,{ useEffect, useState} from 'react';
import axios from 'axios';
import Navbar from 'F:/projet/first/src/components/navbar';
import Footer from '../components/footer';
import { Link } from "react-router-dom";
import Loader from "../components/loader";
import Cookies from 'js-cookie';
import moni from 'F:/projet/first/src/components/image/moni.png';
import secre from 'F:/projet/first/src/components/image/images.png'
export default function SecretaireResultConduite(){

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };
      const [largeurEcran, setLargeurEcran] = useState(window.innerWidth);
    const [candidat, setCandidat] = useState([])
    const [viewLoader,setViewLoader] = useState(false)
    const [titre,setTitre]= useState(Cookies.get('titre'))
    const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
    const [loader, setLoader]= useState(true)
    const [error, setError] = useState(null);
    const [id, setId] = useState('')
    const [id2, setId2]= useState('')
    const [defaultV,setDefaultV]= useState(Cookies.get('default'))
   const [defaultV2,setDefaultV2]= useState(Cookies.get('default2'))
   const [select1,setSelect1]= useState(Cookies.get('select1'))
   const [select2,setSelect2]= useState(Cookies.get('select2'))
    useEffect(()=>{
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/avoir',{gestauto,titre}, { headers})
            .then(res =>{
                 setId(res.data[0].id_ecole)
                 setId2(res.data[0].id_ecole)
                })
            .catch(err => console.log(err));
    
        }, [])
    
    

  var date= new Date();
  const now= date.toLocaleDateString();
 const years= date.getFullYear()
 const days= date.getDate()
var  month=  date.getMonth()+1
var autre= date.toISOString().split('T')[0] 
var datee= new Date(years,month-3,days)
var autre2= datee.toISOString().split('T')[0]
const [date1, setDate1]= useState(autre2)
    const [dat, setDat]= useState('')
  const [date2, setDate2]= useState(autre)

const [out, setOut]= useState('is')
    




const mettreAJourLargeurEcran = () => {
    setLargeurEcran(window.innerWidth);

  };



  useEffect(() => {       
    window.addEventListener('resize', mettreAJourLargeurEcran);  
    return () => {
      window.removeEventListener('resize', mettreAJourLargeurEcran);
      
    }; 
  }, []);


  useEffect(() => {       
    if(select1=== undefined || select2=== undefined){
 
    }else{
    setDefaultV(select1)
    setDefaultV2(select2)
    }
   }, []);


  if(id!='' && out=='is'){
    if(defaultV || defaultV2){
        const date1= defaultV
        const date2= defaultV2
        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/condResultat',{date1,date2,id}, { headers})
        .then(res => {
                     
            setCandidat(res.data)
            setLoader(false)
        })
        .catch(err =>{
             console.log(err)
             setError(err)
             setLoader(false)
        
            });

        setId('')
        setOut('')
    }else{
        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/condResultat',{date1,date2,id}, { headers})
        .then(res => {
                     
            setCandidat(res.data)
            setLoader(false)
        })
        .catch(err =>{
             console.log(err)
             setError(err)
             setLoader(false)
        
            });

        setId('')
        setOut('')
    }
    }

    function handlePropose(event){
       
        setViewLoader(true)
        event.preventDefault();
        Cookies.set('default',date1)
        Cookies.set('default2',date2)
        Cookies.set('select1',date1)
        Cookies.set('select2',date2)
        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/modf2Date', {date1,date2,id2}, { headers})
        .then(res => {
           setCandidat(res.data)
          
           setViewLoader(false)
           setId('')
        }).catch(err =>{
             console.log(err)
             setError(err)
          
             setViewLoader(false)
        });
        
    }
  
     const [query, setQuery]= useState(""); 

     let load

     if(viewLoader==true){
        load=   <div className="avt">
        <div className="rin">
          <div className="chm">
            <Loader/>
          </div>
        </div>
      </div>
    }else{
        load=''
    }


     if(loader){
        return(
            <div className="per">
            
            <div className="loader">
            <div className="overlayLo"> 
                <div className="loaderP">
                <Loader/>
                </div>
                </div>
                </div>
                </div>
        )
    }else if(error){
        return (<div>Une erreur est survenue : {error.message}</div>)
    }else{
     if(largeurEcran>= 1024){
    return(
        <>
             
             <div className='wini'>
        <Navbar/>
        <h1 className='win'>La page de gestion des resultats conduite</h1><br/><br/>
        {load}
        <div className='aff'>
            
        <div className="search">
             <input type="text" className="recherche" name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e=>setQuery(e.target.value)}></input>
        </div>
        <div className='person2'>
           <input type='date' defaultValue={defaultV ? defaultV : date1} className='oix2' onChange={e => setDate1(e.target.value)}></input> <input type='date' defaultValue={defaultV2 ? defaultV2 : date2}  className='oix2' onChange={e => setDate2(e.target.value)} ></input> 
           <button type='submit' onClick={handlePropose} className='somi2'>APPLIQUER</button>
           </div>
           </div>
           <br/><br/><br/>

    <div className=''>
    <div className="card-list">
                 {
                            candidat.filter((data) => data.nom.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query)).reverse().map((data, i)=> (
                                <div className="card">
                                 <img src={data.sexe=='Masculin' ? moni : secre}  alt="Candidat 1" />
                             <div className='info'>
                                <p className='name'> {data.nom} {data.prenom}</p>
                             
                                <p className='contacts'>Note: {data.resultat_conduite} </p>
                               
                                <Link to={'/upResultConduite/'+ data.id} >    <button className="modifier-btn">Modifier</button>  </Link>
                                </div>
                            </div>
                        
                            ))
                          } 
                 </div>
    </div>
    <br/>
    </div>
    <Footer/>
        </>
    )}
    else if(largeurEcran>=510){
        return(
            <>
                 
                 <div className='wini'>
            <Navbar/>
            <h1 className='win'>La page de gestion des resultats conduite</h1><br/><br/>
          
{load}
        <div className='barContener'>

{/* Barre de recherche */}
<div className='barChercheT'>
    <input type="text"className='rechBar'  name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e => setQuery(e.target.value)}></input>
</div>


{/* Barre de recherche personnalisée */}
<div className='persBarT'>
    
    <input type='date' className='perSF' defaultValue={defaultV ? defaultV : date1} onChange={e => setDate1(e.target.value)}></input>
    <input type='date' className='perSF'  defaultValue={defaultV2 ? defaultV2 : date2} onChange={e => setDate2(e.target.value)}></input>
    <button type='submit' className='butonSF' onClick={handlePropose}>APPLIQUER</button>
</div>

</div>     <br/><br/><br/>
    
               <div className="card-list">
                 {
                            candidat.filter((data) => data.nom.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query)).reverse().map((data, i)=> (
                                <div className="card">
                                 <img src={data.sexe=='Masculin' ? moni : secre}  alt="Candidat 1" />
                             <div className='info'>
                                <p className='name'> {data.nom} {data.prenom}</p>
                             
                                <p className='contacts'>Note: {data.resultat_conduite} </p>
                               
                                <Link to={'/upResultConduite/'+ data.id} >    <button className="modifier-btn">Modifier</button>  </Link>
                                </div>
                            </div>
                        
                            ))
                          } 
                 </div>
        <br/>
        </div>
        <Footer/>
            </>
        )
    }
    else{
        return(
            <>
                 
                 <div className='wini'>
            <Navbar/>
            <h1 className='win'>La page de gestion des resultats conduite</h1><br/><br/>
          
{load}
        <div className='barContener'>

{/* Barre de recherche */}
<div className='barChercheT'>
    <input type="text"className='rechBar'  name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e => setQuery(e.target.value)}></input>
</div>


{/* Barre de recherche personnalisée */}
<div className='persBarT'>
    
    <input type='date' className='perSF' defaultValue={defaultV ? defaultV : date1} onChange={e => setDate1(e.target.value)}></input>
    <input type='date' className='perSF'  defaultValue={defaultV2 ? defaultV2 : date2} onChange={e => setDate2(e.target.value)}></input>
    <button type='submit' className='butonSF' onClick={handlePropose}>APPLIQUER</button>
</div>

</div>     <br/><br/><br/>
    
               <div className="card-list">
                 {
                            candidat.filter((data) => data.nom.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query)).reverse().map((data, i)=> (
                                <div className="card">
                                 <img src={data.sexe=='Masculin' ? moni : secre}  alt="Candidat 1" />
                             <div className='info'>
                                <p className='name'> {data.nom} {data.prenom}</p>
                             
                                <p className='contacts'>Note: {data.resultat_conduite} </p>
                               
                                <Link to={'/upResultConduite/'+ data.id} >    <button className="modifier-btn">Modifier</button>  </Link>
                                </div>
                            </div>
                        
                            ))
                          } 
                 </div>
        <br/>
        </div>
        <Footer/>
            </>
        )
    }
}
}